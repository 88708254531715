import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state?.boosts || initialState;

export const selectBoosts = createSelector(
  [selectSlice],
  state => state.boosts,
);

export const selectBoost = createSelector([selectSlice], state => state.boost);

export const selectIsLoading = createSelector(
  [selectSlice],
  state => state.isLoading,
);

export const selectIsLoadingTools = createSelector(
  [selectSlice],
  state => state.tools.isLoading,
);

export const selectTools = createSelector([selectSlice], state => state.tools);
