import React, { ReactNode } from 'react';
import { ButtonEl, SpinnerWrapper, Spinner } from './styles';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: 'true' | 'false';
  common?: {
    variation?: 'primary' | 'secondary' | 'text';
    color?: 'green' | 'yellow' | string;
    small?: 'true' | 'false';
    fullwidth?: 'true' | 'false';
    fullwidthmobile?: 'true' | 'false';
    margin?: string;
    iconside?: 'left' | 'right';
  };
  children: ReactNode;
}

const Button: React.FunctionComponent<IProps> = ({
  loading,
  common,
  children,
  ...props
}) => {
  return (
    <ButtonEl
      {...props}
      loading={loading}
      {...common}
      disabled={props.disabled || loading === 'true'}
    >
      <SpinnerWrapper loading={loading}>
        <Spinner />
      </SpinnerWrapper>
      {children}
    </ButtonEl>
  );
};

export default Button;
