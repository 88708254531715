import RouteConstants from 'app/routes';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { Empty, Skeleton } from 'antd';
import { Header, Table, TableHeader, TableRow, Logo } from '../../styles';
import Typography from 'app/components/Typography';
import ScrollToTopOnMount from 'app/components/ScrollToTopOnMount';
import { selectBoosts, selectIsLoading } from '../../slice/selectors';
import { useAdminSlice } from '../../slice';
import { Link } from 'app/components/Clicks';

const Boosts = () => {
  const boosts = useSelector(selectBoosts);
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch();
  const { actions } = useAdminSlice();

  useEffect(() => {
    dispatch(actions.getBoosts());
  }, [actions, dispatch]);

  const tableRows = useMemo(() => {
    return boosts?.map(boost => (
      <TableRow
        key={`online-tool-${boost.id}`}
        to={generatePath(RouteConstants.admin.editBoost, {
          boostId: boost.id,
        })}
        loading={isLoading ? 'true' : 'false'}
      >
        <div>
          <Logo src={boost.logoUrl} alt="Company logo" />
          <Typography type="div" bodyVariation="bodySmall">
            {boost.companyName}
          </Typography>
        </div>
        <Typography type="div">{boost.title}</Typography>
        <Typography type="div">{boost.category?.name}</Typography>
      </TableRow>
    ));
  }, [boosts, isLoading]);

  return (
    <div>
      <ScrollToTopOnMount />
      <Header>
        <Typography type="h" level={4}>
          All online tools
        </Typography>
        <Link to={RouteConstants.admin.addBoost} common={{ small: 'true' }}>
          Add new
        </Link>
      </Header>
      <Table>
        <TableHeader>
          <Typography type="div" bodyVariation="bodySmall">
            Partner
          </Typography>
          <Typography type="div" bodyVariation="bodySmall">
            Tool title
          </Typography>
          <Typography type="div" bodyVariation="bodySmall">
            Category
          </Typography>
        </TableHeader>
        {isLoading && (!boosts || boosts?.length === 0) ? (
          <Skeleton active style={{ padding: '1.6rem 2.4rem' }} />
        ) : boosts && boosts.length > 0 ? (
          tableRows
        ) : (
          <Empty
            description="No online tools found"
            style={{ padding: '1.6rem 2.4rem' }}
          />
        )}
      </Table>
    </div>
  );
};

export default Boosts;
