import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PersonIcon from '@mui/icons-material/Person';
import PortraitIcon from '@mui/icons-material/Portrait';
import { CircularProgress, Container, Grid } from '@mui/material';
import PageHeader from '../../../../components/PageHeader';
import EditPublicProfile from './EditPublicProfile';

import { useDispatch, useSelector } from 'react-redux';
import UserAddEdit from '../UserAddEdit/UserAddEdit';
import { useParams } from 'react-router-dom';
import { IUserEditParams } from '../../slice/types';
import { useAdminSlice } from '../../slice';
import NoResults from '../../../../components/NoResults';
import {
  selectUser,
  selectUserDataFetchStatus,
  selectUserStripeUrl,
  selectUserStripeUrlIsLoading,
} from '../../slice/selectors';
import { LoadingButton } from '@mui/lab';
import normalizeUrl from 'normalize-url';
import {
  useGeUserAdmin,
  useToggleUserActivation,
} from '../../../../queries/admin/user';

const TabContainer = props => (
  <TabPanel value={props.value} sx={{ pr: 0 }}>
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      rowSpacing={3}
      columnSpacing={{ xs: 2, md: 3 }}
      {...props}
    />
  </TabPanel>
);

export default function EditUserSettings() {
  const [value, setValue] = useState('1');
  const dispatch = useDispatch();
  const { userId } = useParams<IUserEditParams>();
  const { actions } = useAdminSlice();
  const selectedUserData = useGeUserAdmin(userId);
  const [userData, setUserData] = useState(selectedUserData);
  const [activateLoading, setActivateLoading] = useState(false);
  const userDataFetchStatus = useSelector(selectUserDataFetchStatus);
  const stripeUrlFetchStatus = useSelector(selectUserStripeUrlIsLoading);
  const stripeUrl = useSelector(selectUserStripeUrl);
  const toggleUserActivation = useToggleUserActivation();

  useEffect(() => {
    if (selectedUserData) {
      setUserData(selectedUserData);
    }
  }, [selectedUserData]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (stripeUrlFetchStatus === 'success' && stripeUrl) {
      window.open(normalizeUrl(stripeUrl), '_blank');
      dispatch(
        actions.setFetchStatusByKey({
          key: 'userStripeUrlFetchStatus',
          status: 'idle',
        }),
      );
    }
  }, [stripeUrlFetchStatus, dispatch, actions, stripeUrl]);

  const isStripeButtonDisabled = useMemo(
    () => userDataFetchStatus !== 'loading' && !userData?.user?.stripeId,
    [userDataFetchStatus, userData],
  );

  const loadStripeUrl = useCallback(() => {
    dispatch(actions.getUserStripeUrl(userId));
  }, [actions, dispatch, userId]);

  const deactivateUser = () => {
    setActivateLoading(true);
    toggleUserActivation.mutate(
      { userId: userId, active: false },
      {
        onSuccess: data => {
          setUserData(data);
          setActivateLoading(false);
        },
      },
    );
  };
  const reactivateUser = () => {
    setActivateLoading(true);
    toggleUserActivation.mutate(
      { userId: userId, active: true },
      {
        onSuccess: data => {
          setUserData(data);
          setActivateLoading(false);
        },
      },
    );
  };

  return (
    <Container maxWidth="xl" sx={{ pb: 6 }}>
      <PageHeader
        title={'Edit User'}
        rightColumn={
          <>
            {userData?.user?.active ? (
              <LoadingButton
                variant={'outlined'}
                color={'error'}
                onClick={deactivateUser}
                loading={activateLoading}
                sx={{ mr: 1 }}
              >
                Deactivate
              </LoadingButton>
            ) : (
              <LoadingButton
                variant={'outlined'}
                color={'success'}
                onClick={reactivateUser}
                loading={activateLoading}
                sx={{ mr: 1 }}
              >
                Reactivate
              </LoadingButton>
            )}
            <LoadingButton
              variant={'contained'}
              loading={stripeUrlFetchStatus === 'loading'}
              disabled={isStripeButtonDisabled}
              onClick={loadStripeUrl}
            >
              Manage Payment Method
            </LoadingButton>
          </>
        }
      />
      {userData ? (
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mb={2}>
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                <Tab
                  icon={<PersonIcon />}
                  label="Account"
                  iconPosition="start"
                  value="1"
                />
                <Tab
                  icon={<PortraitIcon />}
                  iconPosition="start"
                  label="website information"
                  value="2"
                />
              </TabList>
            </Box>
            <TabContainer value="1">
              <UserAddEdit isEdit userId={userId} data={userData} />
            </TabContainer>
            <TabContainer value="2">
              <EditPublicProfile
                userId={userId}
                data={userData.user.innerPage}
              />
            </TabContainer>
          </TabContext>
        </Box>
      ) : (
        <Box>
          {userDataFetchStatus === 'loading' && (
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'flex-end'}
              height={'45vh'}
            >
              <CircularProgress size={50} />
            </Box>
          )}
          {userDataFetchStatus === 'fail' && (
            <NoResults title={'User not found'} />
          )}
        </Box>
      )}
    </Container>
  );
}
