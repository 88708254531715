import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useGlobalSlice } from '../../slice';
import { selectRedirectTo, selectSnackbarState } from '../../slice/selectors';
import { useGetUserData } from '../../../../queries/auth/useAuthQueries';
import {
  useStore,
  useSetUserData,
  useRedirectTo,
} from '../../../../hooks/useStore';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { activeCampaignTracking } from '../../../../../utils/activecampaign';
import { useQuery } from '../../../../hooks/useQuery';
import { AuthQueryParams } from '../../../../queries/auth/types';
import RouteConstants from '../../../../routes';
interface IProps {
  children: React.ReactNode;
}

const GlobalWrapper = ({ children }: IProps) => {
  const { actions: globalActions } = useGlobalSlice();

  const dispatch = useDispatch();
  const redirectTo = useSelector(selectRedirectTo);

  const history = useHistory();
  const query = useQuery();
  const location = useLocation();
  const { pathname, search } = useLocation();
  const snackbar = useSelector(selectSnackbarState);
  const updateUser = useSetUserData();
  const setRedirectTo = useRedirectTo();
  const isAuthorized = useStore(s => !!s.user?.uuid);
  const user = useStore(s => s.user);
  const redirectLink = useStore(s => s.redirectTo);

  const handleClose = () => {
    dispatch(globalActions.hideSnackbar());
  };

  // Do not call /me on registration page
  const { isLoading, error: LoginError } = useGetUserData({
    options: {
      enabled:
        !isAuthorized &&
        !location.pathname.includes(RouteConstants.auth.registration),
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: data => {
        updateUser(data[0]);
      },
    },
  });

  // TODO: remove this with redux
  // Redirect from redux
  useEffect(() => {
    if (redirectTo) {
      history.push(redirectTo);
      dispatch(globalActions.setRedirectTo(null));
    }
  }, [redirectTo, globalActions, dispatch, history]);

  // Redirect from zustand and redirect query param if not authorized
  useEffect(() => {
    if (isAuthorized) {
      window.dataLayer.push({
        event: 'VirtualPageView',
        virtualURL: `${pathname}${search}`,
      });
      activeCampaignTracking(isAuthorized);
    }

    if (LoginError && !isAuthorized) {
      // TODO: find a better way for redirection
      if (redirectLink) {
        history.push(redirectLink);
        setRedirectTo(null);
      } else if (!/\/auth/.test(location.pathname)) {
        query.set(AuthQueryParams.redirect, location.pathname);
        history.push(`?${query.toString()}`);
      }
    }
  }, [
    LoginError,
    history,
    location.pathname,
    query,
    isAuthorized,
    redirectLink,
    setRedirectTo,
    pathname,
    search,
  ]);

  // Do not wait for redirect on registration page
  if (location.pathname.includes(RouteConstants.auth.registration)) {
    return <>{children}</>;
  }

  return isLoading ? null : (
    <>
      {/*TODO: Remove with redux*/}
      <Snackbar
        open={snackbar.isOpen}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={snackbar.anchor}
        sx={{ top: { xs: 90, lg: 30 } }}
      >
        <MuiAlert
          variant="filled"
          onClose={handleClose}
          elevation={6}
          severity={snackbar.type}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
      {children}
    </>
  );
};

export default GlobalWrapper;
