import React, { useEffect, useState } from 'react';
import PageHeader from '../../../../components/PageHeader';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import PersonIcon from '@mui/icons-material/Person';
import PortraitIcon from '@mui/icons-material/Portrait';
import UserAddEdit from '../UserAddEdit/UserAddEdit';
import EditPublicProfile from '../EditUserSettings/EditPublicProfile';
import { CircularProgress, Container, Grid } from '@mui/material';
import NoResults from '../../../../components/NoResults';
import TeamAddEdit from './TeamAddEdit';
import { useParams } from 'react-router-dom';
import { ITeamEditParams } from '../../slice/types';
import { Group, SettingsSuggest } from '@mui/icons-material';
import { selectTeamDataFetchStatus } from '../../slice/selectors';
import TabPanel from '@mui/lab/TabPanel';
import { useDispatch } from 'react-redux';
import { useAdminSlice } from '../../slice';
import Members from './Members';

const TabContainer = props => (
  <TabPanel value={props.value} sx={{ pr: 0 }}>
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      rowSpacing={3}
      columnSpacing={{ xs: 2, md: 3 }}
      {...props}
    />
  </TabPanel>
);

const Team = () => {
  const dispatch = useDispatch();
  const { actions } = useAdminSlice();
  const { teamId: teamIdString } = useParams<ITeamEditParams>();
  const teamId = parseInt(teamIdString, 10);
  const [value, setValue] = useState('1');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (teamId) {
      dispatch(actions.getTeam(teamIdString));
    }
  }, [teamId]);

  return (
    <Container maxWidth="xl" sx={{ pb: 6 }}>
      {teamId ? (
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mb={2}>
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                <Tab
                  icon={<SettingsSuggest />}
                  label="Team details"
                  iconPosition="start"
                  value="1"
                />
                <Tab
                  icon={<Group />}
                  iconPosition="start"
                  label="Members"
                  value="2"
                />
              </TabList>
            </Box>
            <TabContainer value="1">
              <TeamAddEdit isEdit={true} teamId={teamId} />
            </TabContainer>
            <TabContainer value="2">
              <Members teamId={teamId} />
            </TabContainer>
          </TabContext>
        </Box>
      ) : (
        <Box>
          {selectTeamDataFetchStatus.name === 'loading' && (
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'flex-end'}
              height={'45vh'}
            >
              <CircularProgress size={50} />
            </Box>
          )}
          {selectTeamDataFetchStatus.name === 'fail' && (
            <NoResults title={'Team not found'} />
          )}
        </Box>
      )}
    </Container>
  );
};

export default Team;
