import React, { ReactNode } from 'react';
import { H1, H2, H3, H4, H5, P, Span, Div } from './Typography.styles';

interface IProps {
  type: 'h' | 'p' | 'span' | 'div';
  level?: 1 | 2 | 3 | 4 | 5;
  h1Variation?: 'display';
  bodyVariation?: 'bodyXL' | 'bodyLarge' | 'bodySmall' | 'table';
  common?: {
    fontWeight?: 300 | 400 | 600 | 700;
    color?: string;
    isUnderlined?: boolean;
    isUppercase?: boolean;
    isItalic?: boolean;
    margin?: string;
    lineHeight?: string;
    textAlign?: 'start' | 'end' | 'center' | 'justify' | 'left' | 'right';
  };
  children: string | ReactNode;
}

const Typography = ({
  type,
  level,
  h1Variation,
  bodyVariation,
  common,
  children,
}: IProps) => {
  if (type === 'h') {
    if (level === 1) {
      return (
        <H1 variation={h1Variation} {...common}>
          {children}
        </H1>
      );
    }

    if (level === 2) {
      return <H2 {...common}>{children}</H2>;
    }

    if (level === 3) {
      return <H3 {...common}>{children}</H3>;
    }

    if (level === 4) {
      return <H4 {...common}>{children}</H4>;
    }

    if (level === 5) {
      return <H5 {...common}>{children}</H5>;
    }
  }

  if (type === 'p')
    return (
      <P variation={bodyVariation} {...common}>
        {children}
      </P>
    );

  if (type === 'span')
    return (
      <Span variation={bodyVariation} {...common}>
        {children}
      </Span>
    );

  return (
    <Div variation={bodyVariation} {...common}>
      {children}
    </Div>
  );
};

export default Typography;
