import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Wrapper, LinkText } from './TabPane.styles';

export interface LinkObj {
  href: string;
  text: string;
  isVisible: boolean;
}

interface IProps {
  links: Array<LinkObj>;
  activeLink: string;
}

const TabPane = ({ links, activeLink }: IProps) => {
  const menu = useMemo(
    () =>
      links.map(item => {
        if (!item.isVisible) return null;
        return (
          <Link to={item.href} key={`sidebar-item-${item.text}`}>
            <LinkText isActive={activeLink.includes(item.href)}>
              {item.text}
            </LinkText>
          </Link>
        );
      }),
    [links, activeLink],
  );
  return <Wrapper>{menu}</Wrapper>;
};

export default TabPane;
