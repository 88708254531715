import { alpha } from '@mui/material/styles';
import { pxToRem } from '../utils/helpers';

function responsiveFontSizes({ xs, sm, md, lg }) {
  return {
    '@media (min-width:0px)': {
      fontSize: pxToRem(xs),
    },
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

const FONT_PRIMARY = "'Inter', sans-serif";

// TODO: Find better way to set font color and font family

const typography = {
  h1: {
    fontFamily: FONT_PRIMARY,
    color: alpha('#111535DE', 0.87),
    fontWeight: 700,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40),
    ...responsiveFontSizes({ xs: 36, sm: 36, md: 36, lg: 38 }),
  },
  h2: {
    fontFamily: FONT_PRIMARY,
    color: alpha('#111535DE', 0.87),
    fontWeight: 700,
    lineHeight: 64 / 48,
    fontSize: pxToRem(32),
    // ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
  },
  h3: {
    fontFamily: FONT_PRIMARY,
    color: alpha('#111535DE', 0.87),
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(24),
    // ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
  },
  h4: {
    fontFamily: FONT_PRIMARY,
    color: alpha('#111535DE', 0.87),
    fontWeight: 400,
    lineHeight: 1.3,
    fontSize: pxToRem(34),
  },
  h5: {
    fontFamily: FONT_PRIMARY,
    color: alpha('#111535DE', 0.87),
    fontWeight: 600,
    lineHeight: 1.4,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ xs: 22, sm: 22, md: 23, lg: 24 }),
  },
  h6: {
    fontFamily: FONT_PRIMARY,
    color: alpha('#111535DE', 0.87),
    fontWeight: 500,
    lineHeight: 1.6,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ xs: 19, sm: 19, md: 20, lg: 20 }),
  },
  subtitle1: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 400,
    lineHeight: pxToRem(28),
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontFamily: FONT_PRIMARY,
    color: alpha('#111535DE', 0.87),
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  body1: {
    fontFamily: FONT_PRIMARY,
    lineHeight: pxToRem(24),
    fontSize: pxToRem(16),
    color: alpha('#000000', 0.6),
  },
  body2: {
    fontFamily: FONT_PRIMARY,
    color: alpha('#111535DE', 0.87),
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    fontFamily: FONT_PRIMARY,
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    letterSpacing: 1.1,
    textTransform: 'uppercase',
    fontFamily: FONT_PRIMARY,
  },
  button: {
    fontWeight: 500,
    lineHeight: pxToRem(26),
    fontSize: pxToRem(15),
    textTransform: 'uppercase',
    fontFamily: FONT_PRIMARY,
  },
};

export default typography;
