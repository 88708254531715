import styled from 'styled-components';

export const Price = styled.div`
  font-weight: 700;
  font-family: 'Sofia Pro', serif;
  font-size: 54px;
  line-height: 46px;
  color: #111535;
  @media (max-width: 767px) {
    font-size: 40px;
    line-height: 46px;
  }
  @media (max-width: 425px) {
    font-size: 34px;
    line-height: 40px;
  }
`;

export const PlanName = styled.div`
  font-family: 'Sofia Pro', serif;
  font-size: 24px;
  line-height: 34px;
  color: rgba(17, 21, 53, 0.87);
  @media (max-width: 425px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const PriceDetail = styled.span`
  font-size: 24px;
  line-height: 22px;
  color: #8c8c8c;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const PlanTooltip = styled.div`
  position: absolute;
  top: 47px;
  left: 0;
  background: #ffffff;
  max-width: 350px;
  -webkit-box-shadow: 0 5px 20px rgb(0 0 0 / 15%);
  box-shadow: 0 5px 20px rgb(0 0 0 / 15%);
  border-radius: 16px;
  width: 100%;
  padding: 24px;
  transform: translateY(10%);
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: ease all 0.3s;
  transition: ease all 0.3s;
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: -20px;
    left: 0;
    z-index: 1;
  }
  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    position: absolute;
    top: -10px;
    left: 70px;
    transform: translate(-50%, 0);
    z-index: 2;
  }
`;

export const PricingDetails = styled.div`
  font-family: 'Sofia Pro', serif;
  display: inline-block;
  color: #111535;
  border-bottom: 1px dashed #056ba4;
  font-size: 21px;
  line-height: 30px;
  font-weight: 300;
  cursor: pointer;
  -webkit-transition: ease all 0.3s;
  transition: ease all 0.3s;
  &:hover ${PlanTooltip} {
    transform: translateY(0%);
    opacity: 1;
    visibility: visible;
  }
  p {
    font-size: 16px;
    line-height: 16px;
    padding-bottom: 0;
    margin-bottom: 5px;
  }
`;
