import api from '../api';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

interface useTrackAcEventProps {
  options?: UseMutationOptions<AxiosError, number, any>;
}

export function useTrackAcEvent(props?: useTrackAcEventProps) {
  return useMutation(async (params: { id: number }) => {
    const result = await api.post('services/track-ac-event/', params);
    return result.data;
  }, props?.options);
}
