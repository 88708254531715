import React, { useState } from 'react';
import {
  List,
  ListItem,
  Stack,
  Typography,
  ListItemText,
  Divider,
  Box,
  Button,
  Grow,
  Collapse,
} from '@mui/material';
import { IPricingPlan } from '../../containers/Registration';
import CheckIcon from '@mui/icons-material/Check';
import {
  PlanName,
  PlanTooltip,
  Price,
  PriceDetail,
  PricingDetails,
} from './Registration.styles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { TransitionGroup } from 'react-transition-group';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useResponsive from '../../hooks/useResponsive';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const MAX_FEATURES = 3;

const PlanInformation = (props: IPricingPlan) => {
  const { shortName, price, priceDetails, pricingDetails, features } = props;
  const [showDetails, setShowDetails] = useState(false);
  const [showAllFeatures, setShowAllFeatures] = useState(false);
  const isMobile = useResponsive('down', 767);
  const isMobileBig = useResponsive('between', 376, 767);
  const isMobileSmall = useResponsive('down', 376);
  return (
    <Box
      p={isMobile ? 3 : 2}
      maxWidth={isMobile ? '100%' : 330}
      width={'100%'}
      flexShrink={3}
    >
      <Stack
        gap={2}
        sx={
          isMobile
            ? {
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                cursor: 'pointer',
              }
            : {}
        }
        {...(isMobile ? { onClick: () => setShowDetails(!showDetails) } : {})}
      >
        {!isMobile && (
          <Typography variant={'h6'}>Your Current Plan:</Typography>
        )}
        <Stack
          rowGap={isMobileSmall ? 0 : 2}
          direction={isMobileBig ? 'row' : 'column'}
          columnGap={2}
          alignItems={isMobileBig ? 'center' : 'flex-start'}
        >
          <PlanName>{shortName}</PlanName>
          <Price>
            {price}
            <PriceDetail>{priceDetails}</PriceDetail>
          </Price>
        </Stack>
        {isMobile &&
          (showDetails ? (
            <KeyboardArrowUpIcon color={'primary'} />
          ) : (
            <KeyboardArrowDownIcon color={'primary'} />
          ))}
      </Stack>
      <Collapse orientation="vertical" in={showDetails || !isMobile}>
        <Box position={'relative'} my={2}>
          {pricingDetails.length ? (
            <PricingDetails>
              Pricing Details
              <PlanTooltip>
                {pricingDetails.map((detail, index) => (
                  <p key={index}>{detail}</p>
                ))}
              </PlanTooltip>
            </PricingDetails>
          ) : (
            ''
          )}
        </Box>
        <Divider />
        <Stack>
          <List dense>
            <TransitionGroup>
              {features
                .slice(0, showAllFeatures ? features.length : MAX_FEATURES)
                .map((feature, index) => (
                  <Grow key={index}>
                    <ListItem sx={{ px: 0 }}>
                      <Stack gap={2} direction={'row'} alignItems={'center'}>
                        <CheckIcon color={'primary'} />
                        <ListItemText primary={feature} />
                      </Stack>
                    </ListItem>
                  </Grow>
                ))}
            </TransitionGroup>
          </List>
        </Stack>
        <Button
          startIcon={showAllFeatures ? <RemoveIcon /> : <AddIcon />}
          onClick={() => setShowAllFeatures(!showAllFeatures)}
        >
          {showAllFeatures ? 'Show less features' : 'Show more features'}
        </Button>
      </Collapse>
    </Box>
  );
};

export default PlanInformation;
