import { PayloadAction } from '@reduxjs/toolkit';
import { IBoostObject, ICategory } from 'app/containers/Boosts/slice/types';
import { IDealObject, IGeo } from 'app/containers/Deals/slice/types';
import {
  IUpdateUserPublicData,
  IUserWithAddressObject,
  IUsersObject,
  IUserObject,
  IUpdateUserInnerPageImageUrl,
  IUpdateUserProfilePicture,
  ITeamObject,
  IUpdateTeamPayload,
  ICreateTeamPayload,
} from 'app/containers/Admin/slice/types';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { adminSaga } from './saga';
import {
  IAdminState,
  ISaveBoostPayload,
  IUploadDealPayload,
  ICreateDealPayload,
  ICreateBoostPayload,
  IUploadUserPayload,
  ICreateUserPayload,
} from './types';
import { fetchStatus } from '../../Global/slice/types';

export const initialState: IAdminState = {
  isLoading: false,
  isCategoriesLoading: false,
  isLocationsLoading: false,
  isSaving: false,
  boosts: null,
  boost: null,
  deals: null,
  deal: null,
  users: null,
  myTeam: null,
  teams: null,
  selectedUser: null,
  selectedTeam: null,
  dealsCategories: null,
  boostsCategories: null,
  locations: null,
  userStripeUrl: '',
  userStripeUrlFetchStatus: 'idle',
  userDocusignGenerationStatus: 'idle',
  userDataFetchStatus: 'idle',
  teamDataFetchStatus: 'idle',
};

const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    getBoosts(state) {
      state.isLoading = true;
    },
    setUsers(state, action: PayloadAction<Array<IUsersObject> | null>) {
      state.users = action.payload;
    },
    setTeams(state, action: PayloadAction<Array<ITeamObject> | null>) {
      state.teams = action.payload;
    },
    setBoosts(state, action: PayloadAction<Array<IBoostObject> | null>) {
      state.boosts = action.payload;
    },
    getBoost(state, action: PayloadAction<number>) {
      state.isLoading = true;
    },
    setBoost(state, action: PayloadAction<IBoostObject>) {
      state.boost = action.payload;
    },
    saveBoost(state, action: PayloadAction<ISaveBoostPayload>) {
      state.isSaving = true;
    },
    createBoost(state, action: PayloadAction<ICreateBoostPayload>) {
      state.isSaving = true;
    },
    getUsers(state) {
      state.isLoading = true;
    },
    getTeams(state) {
      state.isLoading = true;
    },
    getUser(state, action: PayloadAction<string>) {
      state.userDataFetchStatus = 'loading';
    },
    getTeam(state, action: PayloadAction<string>) {
      state.teamDataFetchStatus = 'loading';
    },
    setUserData(state, action: PayloadAction<IUserObject>) {
      state.selectedUser = {
        user: action.payload,
      };
      state.userDataFetchStatus = 'success';
    },
    setTeam(state, action: PayloadAction<ITeamObject | null>) {
      state.selectedTeam = action.payload;
      state.teamDataFetchStatus = 'success';
    },
    setUserWithAddress(state, action: PayloadAction<IUserWithAddressObject>) {
      state.selectedUser = action.payload;
      state.userDataFetchStatus = 'success';
    },
    getDeals(state) {
      state.isLoading = true;
    },
    updateUser(state, action: PayloadAction<IUploadUserPayload>) {
      state.userDataFetchStatus = 'loading';
    },
    createUser(state, action: PayloadAction<ICreateUserPayload>) {
      state.userDataFetchStatus = 'loading';
    },
    updateTeam(state, action: PayloadAction<IUpdateTeamPayload>) {
      state.teamDataFetchStatus = 'loading';
    },
    createTeam(state, action: PayloadAction<ICreateTeamPayload>) {
      state.teamDataFetchStatus = 'loading';
    },
    setDeals(state, action: PayloadAction<Array<IDealObject> | null>) {
      state.deals = action.payload;
    },
    getDeal(state, action: PayloadAction<number>) {
      state.isLoading = true;
    },
    setDeal(state, action: PayloadAction<IDealObject>) {
      state.deal = action.payload;
    },
    updateDeal(state, action: PayloadAction<IUploadDealPayload>) {
      state.isSaving = true;
    },
    createDeal(state, action: PayloadAction<ICreateDealPayload>) {
      state.isSaving = true;
    },
    getUserStripeUrl(state, action: PayloadAction<string>) {
      state.userStripeUrlFetchStatus = 'loading';
    },
    setUserStripeUrl(state, action: PayloadAction<string>) {
      state.userStripeUrl = action.payload;
      state.userStripeUrlFetchStatus = 'success';
    },
    updateUserPublicInfo(state, action: PayloadAction<IUpdateUserPublicData>) {
      state.userDataFetchStatus = 'loading';
    },
    updateUserProfilePicture(
      state,
      action: PayloadAction<IUpdateUserProfilePicture>,
    ) {
      state.userDataFetchStatus = 'loading';
    },
    updateUserInnerPageImageUrl(
      state,
      action: PayloadAction<IUpdateUserInnerPageImageUrl>,
    ) {
      state.userDataFetchStatus = 'loading';
    },
    setFetchStatusByKey(
      state,
      action: PayloadAction<{ key: string; status: fetchStatus }>,
    ) {
      state[action.payload.key] = action.payload.status;
    },
    generateUserDocusignDocument(state, action: PayloadAction<string>) {
      state.userDocusignGenerationStatus = 'loading';
    },
    getDealsCategories(state) {
      state.isCategoriesLoading = true;
    },
    setDealsCategories(state, action: PayloadAction<Array<ICategory> | null>) {
      state.dealsCategories = action.payload;
    },
    getBoostsCategories(state) {
      state.isCategoriesLoading = true;
    },
    setBoostsCategories(state, action: PayloadAction<Array<ICategory> | null>) {
      state.boostsCategories = action.payload;
    },
    getLocations(state) {
      state.isLocationsLoading = true;
    },
    setLocations(state, action: PayloadAction<Array<IGeo> | null>) {
      state.locations = action.payload;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setIsCategoriesLoading(state, action: PayloadAction<boolean>) {
      state.isCategoriesLoading = action.payload;
    },
    setIsLocationsLoading(state, action: PayloadAction<boolean>) {
      state.isLocationsLoading = action.payload;
    },
    setIsSaving(state, action: PayloadAction<boolean>) {
      state.isSaving = action.payload;
    },
  },
});

export const { actions: adminActions } = slice;

export const useAdminSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: adminSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAuthorizationSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
