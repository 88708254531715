import React from 'react';
import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

const FacebookIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5ZM11.2 7.89167L11.0917 8.78333H9.375V13.8333H8.5V8.78333H6.84167V7.89167H8.5V5.85833C8.5 4.53333 9.2 3.76667 10.4167 3.76667C10.7667 3.76667 11.0917 3.78333 11.3417 3.8V4.525H10.8833C9.88333 4.525 9.375 5.03333 9.375 6.05V7.88333H11.2083L11.2 7.89167ZM13.8333 13.8333H10.4833V9.9H12.075L12.4583 6.79167H10.4833V6.075C10.4833 5.69167 10.4833 5.65833 10.8833 5.65833H12.45V2.825L11.9667 2.75833C11.7333 2.725 11.1 2.66667 10.4 2.66667C8.56667 2.66667 7.36667 3.91667 7.36667 5.86667V6.78333H5.70833V9.89167H7.36667V13.825H2.16667V2.16667H13.8333V13.8333Z" />
    </SvgIcon>
  );
};

export default FacebookIcon;
