import styled, { keyframes } from 'styled-components';

const blur = keyframes`
  0%,
  90% {
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
  }
  50% {
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
  }
`;

export const Logo = styled.img`
  display: block;
  object-fit: contain;
  mix-blend-mode: multiply;
  min-width: 150px;
  max-width: 180px;
  max-height: 54px;
  object-position: left;
`;
