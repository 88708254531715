/**
 *
 * Deals
 *
 */
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import RouteConstants from '../../routes';
import AllDeals from './pages/AllDeals/AllDeals';
import DealInner from './pages/DealInner/DealInner';

function Deals() {
  return (
    <Switch>
      <Redirect
        exact
        from={RouteConstants.deals.index}
        to={RouteConstants.deals.all}
      />
      <Route path={RouteConstants.deals.all} component={AllDeals} />
      <Route path={RouteConstants.deals.dealsInner} component={DealInner} />
      <Route path="*">
        <Redirect to={RouteConstants.deals.all} />
      </Route>
    </Switch>
  );
}

export default Deals;
