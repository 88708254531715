import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Wrapper, RatingWrapper } from './Ratings.styles';
import Typography from '../Typography';
import { useTheme } from 'styled-components';
import { Button } from 'app/components/Clicks';

interface IProps {
  average: number;
  count: number;
  userRating: number;
  onStarClick: (starIndex: number) => void;
}

const Ratings = ({ average, count, userRating, onStarClick }: IProps) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRatingOpened, setIsRatingOpened] = useState<boolean>(false);
  const [isThanksMessage, setIsThanksMessage] = useState<boolean>(false);
  const [localUserRate, setLocalUserRate] = useState<number>(userRating | 0);

  useEffect(() => setLocalUserRate(userRating), [userRating]);

  let openedRateTimer;

  const onRatingClick = () => {
    setIsRatingOpened(true);
    openedRateTimer = setTimeout(() => {
      setIsRatingOpened(false);
    }, 3000);
  };

  const starClickHandler = useCallback(
    (starIndex: number) => {
      clearTimeout(openedRateTimer);
      if (isLoading) return;
      setIsLoading(true);
      setLocalUserRate(starIndex);
      onStarClick(starIndex);
      setIsThanksMessage(true);
      setTimeout(() => {
        setIsRatingOpened(false);
        setIsThanksMessage(false);
        setIsLoading(false);
      }, 2000);
    },
    [onStarClick, isLoading, openedRateTimer],
  );

  const ratingStars = useMemo(() => {
    const buttons: Array<JSX.Element> = [];
    for (let i = 1; i <= 5; i++) {
      buttons.push(
        <Button
          key={`star-key-${i}`}
          type="button"
          onClick={() => {
            starClickHandler(i);
          }}
          common={{
            variation: 'text',
            color: theme.grey.secondary,
            iconside: 'left',
          }}
        >
          {i <= localUserRate ? (
            <svg
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.5105 6.58507L13.0556 5.79229L10.6171 0.848738C10.5505 0.713387 10.4409 0.603817 10.3056 0.537215C9.96614 0.369637 9.55364 0.509285 9.38391 0.848738L6.94543 5.79229L1.49055 6.58507C1.34016 6.60655 1.20266 6.67745 1.09739 6.78487C0.970119 6.91568 0.899987 7.09167 0.902404 7.27416C0.904821 7.45665 0.979589 7.63072 1.11028 7.75811L5.05696 11.606L4.12454 17.0394C4.10267 17.1658 4.11666 17.2957 4.16491 17.4146C4.21316 17.5334 4.29375 17.6364 4.39754 17.7118C4.50132 17.7871 4.62415 17.8319 4.75209 17.8411C4.88004 17.8502 5.00798 17.8233 5.12141 17.7634L10.0005 15.1982L14.8796 17.7634C15.0128 17.8343 15.1675 17.8579 15.3157 17.8321C15.6896 17.7677 15.9409 17.4132 15.8765 17.0394L14.9441 11.606L18.8907 7.75811C18.9982 7.65284 19.0691 7.51534 19.0906 7.36495C19.1486 6.98897 18.8864 6.64093 18.5105 6.58507Z"
                fill="#262626"
              />
            </svg>
          ) : (
            <svg
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.5105 6.58507L13.0556 5.79229L10.6171 0.848738C10.5505 0.713387 10.4409 0.603817 10.3056 0.537215C9.96614 0.369637 9.55364 0.509285 9.38391 0.848738L6.94543 5.79229L1.49055 6.58507C1.34016 6.60655 1.20266 6.67745 1.09739 6.78487C0.970119 6.91568 0.899987 7.09167 0.902404 7.27416C0.904821 7.45665 0.979589 7.63072 1.11028 7.75811L5.05696 11.606L4.12454 17.0394C4.10267 17.1658 4.11666 17.2957 4.16491 17.4146C4.21316 17.5334 4.29375 17.6364 4.39754 17.7118C4.50132 17.7871 4.62415 17.8319 4.75209 17.8411C4.88004 17.8502 5.00798 17.8233 5.12141 17.7634L10.0005 15.1982L14.8796 17.7634C15.0128 17.8343 15.1675 17.8579 15.3157 17.8321C15.6896 17.7677 15.9409 17.4132 15.8765 17.0394L14.9441 11.606L18.8907 7.75811C18.9982 7.65284 19.0691 7.51534 19.0906 7.36495C19.1486 6.98897 18.8864 6.64093 18.5105 6.58507ZM13.2833 11.0646L14.0589 15.5827L10.0005 13.4515L5.94211 15.5849L6.7177 11.0667L3.43489 7.86554L7.97239 7.20597L10.0005 3.096L12.0286 7.20597L16.5661 7.86554L13.2833 11.0646Z"
                fill="#262626"
              />
            </svg>
          )}
        </Button>,
      );
    }
    return buttons;
  }, [localUserRate, starClickHandler, theme.grey.secondary]);

  const starIcon = useMemo(() => {
    if (average === 0)
      return (
        <svg
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.5105 6.58507L13.0556 5.79229L10.6171 0.848738C10.5505 0.713387 10.4409 0.603817 10.3056 0.537215C9.96614 0.369637 9.55364 0.509285 9.38391 0.848738L6.94543 5.79229L1.49055 6.58507C1.34016 6.60655 1.20266 6.67745 1.09739 6.78487C0.970119 6.91568 0.899987 7.09167 0.902404 7.27416C0.904821 7.45665 0.979589 7.63072 1.11028 7.75811L5.05696 11.606L4.12454 17.0394C4.10267 17.1658 4.11666 17.2957 4.16491 17.4146C4.21316 17.5334 4.29375 17.6364 4.39754 17.7118C4.50132 17.7871 4.62415 17.8319 4.75209 17.8411C4.88004 17.8502 5.00798 17.8233 5.12141 17.7634L10.0005 15.1982L14.8796 17.7634C15.0128 17.8343 15.1675 17.8579 15.3157 17.8321C15.6896 17.7677 15.9409 17.4132 15.8765 17.0394L14.9441 11.606L18.8907 7.75811C18.9982 7.65284 19.0691 7.51534 19.0906 7.36495C19.1486 6.98897 18.8864 6.64093 18.5105 6.58507ZM13.2833 11.0646L14.0589 15.5827L10.0005 13.4515L5.94211 15.5849L6.7177 11.0667L3.43489 7.86554L7.97239 7.20597L10.0005 3.096L12.0286 7.20597L16.5661 7.86554L13.2833 11.0646Z"
            fill="#262626"
          />
        </svg>
      );
    if (average < 4)
      return (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.51 7.58513L14.0551 6.79235L11.6166 1.8488C11.55 1.71345 11.4405 1.60388 11.3051 1.53728C10.9656 1.3697 10.5531 1.50935 10.3834 1.8488L7.94495 6.79235L2.49006 7.58513C2.33967 7.60661 2.20217 7.67751 2.0969 7.78493C1.96963 7.91574 1.8995 8.09173 1.90192 8.27422C1.90433 8.45671 1.9791 8.63078 2.10979 8.75818L6.05647 12.606L5.12405 18.0394C5.10218 18.1658 5.11617 18.2958 5.16442 18.4147C5.21267 18.5335 5.29326 18.6365 5.39705 18.7118C5.50083 18.7872 5.62366 18.832 5.75161 18.8411C5.87955 18.8502 6.00749 18.8233 6.12092 18.7634L11 16.1982L15.8791 18.7634C16.0123 18.8343 16.167 18.858 16.3153 18.8322C16.6891 18.7677 16.9405 18.4133 16.876 18.0394L15.9436 12.606L19.8903 8.75818C19.9977 8.6529 20.0686 8.5154 20.0901 8.36501C20.1481 7.98903 19.886 7.64099 19.51 7.58513ZM14.2828 12.0646L15.0584 16.5828L11 14.4515V13.2916V12.0646V10.5416V8.36501V4.09607L13.0282 8.20603L17.5656 8.8656L14.2828 12.0646Z"
            fill="#262626"
          />
        </svg>
      );
    return (
      <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.5105 6.58507L13.0556 5.79229L10.6171 0.848738C10.5505 0.713387 10.4409 0.603817 10.3056 0.537215C9.96614 0.369637 9.55364 0.509285 9.38391 0.848738L6.94543 5.79229L1.49055 6.58507C1.34016 6.60655 1.20266 6.67745 1.09739 6.78487C0.970119 6.91568 0.899987 7.09167 0.902404 7.27416C0.904821 7.45665 0.979589 7.63072 1.11028 7.75811L5.05696 11.606L4.12454 17.0394C4.10267 17.1658 4.11666 17.2957 4.16491 17.4146C4.21316 17.5334 4.29375 17.6364 4.39754 17.7118C4.50132 17.7871 4.62415 17.8319 4.75209 17.8411C4.88004 17.8502 5.00798 17.8233 5.12141 17.7634L10.0005 15.1982L14.8796 17.7634C15.0128 17.8343 15.1675 17.8579 15.3157 17.8321C15.6896 17.7677 15.9409 17.4132 15.8765 17.0394L14.9441 11.606L18.8907 7.75811C18.9982 7.65284 19.0691 7.51534 19.0906 7.36495C19.1486 6.98897 18.8864 6.64093 18.5105 6.58507Z"
          fill="#262626"
        />
      </svg>
    );
  }, [average]);

  if (isRatingOpened)
    return (
      <div>
        {isThanksMessage && (
          <Typography type="div" bodyVariation="bodySmall">
            Thank you!
          </Typography>
        )}
        <Wrapper>{ratingStars}</Wrapper>
      </div>
    );

  return (
    <Wrapper>
      {starIcon}
      <RatingWrapper>
        <Typography type="span" common={{ fontWeight: 600, lineHeight: '1' }}>
          {average}
        </Typography>
        <Typography
          type="span"
          bodyVariation="bodySmall"
          common={{ lineHeight: '1', color: theme.grey.disabled }}
        >
          /5 &middot; {count}
        </Typography>
      </RatingWrapper>

      <Button
        type="button"
        onClick={onRatingClick}
        common={{
          variation: 'text',
          color: theme.grey.secondary,
          iconside: 'left',
        }}
      >
        {starIcon}
        Rate
      </Button>
    </Wrapper>
  );
};

export default Ratings;
