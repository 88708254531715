import React from 'react';
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount';
import { Box, Container, Typography } from '@mui/material';
import PageHeader from '../../../components/PageHeader';
import OffersList from '../../../components/OffersList';
import { useFetchOnlineTools } from '../../../queries/onlineTools/useOnlineToolsQueries';
import { GeneralToolsProps } from '../GeneralTools';
import Routes from '../../../routes';

const EducationTools: React.FC<GeneralToolsProps> = ({ filter }) => {
  const { data, isLoading } = useFetchOnlineTools(filter);
  const tools = data ? data.online_tools : null;

  return (
    <Container maxWidth="xl">
      <ScrollToTopOnMount />
      <PageHeader
        withDivider
        title="Education"
        subtitle={
          <Box>
            <Typography>
              In this section you can find resources to help you learn and grow.
              Everything from continues education courses to coaching and
              training providers.
            </Typography>
          </Box>
        }
      ></PageHeader>

      <OffersList
        isLoading={isLoading}
        boosts={tools}
        routeName={Routes.education.inner}
      />
    </Container>
  );
};

export default EducationTools;
