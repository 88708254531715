import React, { useMemo } from 'react';
import { Logo, LogoWrapper, RatingWrapper, Star } from './OfferCard.styles';
import CopyText from '../CopyText';
import StarEmpty from 'assets/images/star.svg';
import StarFull from 'assets/images/star_full.svg';
import StarHalf from 'assets/images/star_half.svg';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import { NavLink, useHistory } from 'react-router-dom';
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Typography,
  Link,
  Box,
  Stack,
} from '@mui/material';
import { shortenText } from '../../../utils/helpers';
import { useTheme } from '@mui/material/styles';
import { IServiceInnerPricing } from '../../queries/services/types';

interface IProps {
  background: string;
  logo: string;
  companyName: string;
  title: string;
  description: string;
  link: string;
  isLoading: boolean;
  prices: IServiceInnerPricing[];
}

const DESCRIPTION_CHAR_COUNT = 170;

const OfferCard = ({
  logo,
  background,
  companyName,
  title,
  description,
  link,
  prices,
}: IProps) => {
  const history = useHistory();
  const theme = useTheme();

  const getFeaturedPrice = (
    prices: IServiceInnerPricing[],
  ): IServiceInnerPricing | null => {
    return prices.find(price => price.featured) || null;
  };

  const featuredPrice = getFeaturedPrice(prices);

  link = featuredPrice?.ctaUrl ?? link;
  const linkText = featuredPrice?.ctaText ?? 'Learn more';

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 300ms',
        '&:hover': {
          cursor: 'pointer',
          boxShadow: 'rgb(0 0 0 / 25%) 0px 5px 10px',
          transform: 'scale(1.02)',
        },
      }}
      onClick={() => {
        featuredPrice
          ? window.open(link, '_blank')
          : history.push(`../../${link}`);
      }}
    >
      <CardMedia
        component="img"
        alt="card-media-image"
        height="130"
        image={background}
      />
      <Stack justifyContent={'space-between'} flexGrow={1}>
        <CardContent>
          <LogoWrapper>
            <Box mr={1}>
              {logo && <Logo src={logo} alt={`${companyName} logo`} />}
            </Box>
          </LogoWrapper>
          <Typography gutterBottom variant="h6" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {shortenText(description, DESCRIPTION_CHAR_COUNT)}
          </Typography>
        </CardContent>
        <CardActions>
          <Stack pt={2} spacing={2} width={'100%'}>
            <Button
              fullWidth
              variant="contained"
              size="large"
              component={Link}
              onClick={e => e.stopPropagation()}
              href={link}
              target={featuredPrice ? '_blank' : '_self'}
              sx={{
                '&:hover': {
                  color: theme.palette.common.white,
                },
              }}
            >
              {linkText}
            </Button>
          </Stack>
        </CardActions>
      </Stack>
    </Card>
  );
};

export default OfferCard;
