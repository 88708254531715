import api from '../api';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import * as Types from './types';
import { AxiosError } from 'axios';
import { ITeam } from './types';

type GetTeamsResponse = Array<Types.ITeam>;
type GetCouponsResponse = Array<Types.ICoupon>;

interface GetTeams<T> {
  options?: UseQueryOptions<GetTeamsResponse, AxiosError, T, any>;
}

interface GetCoupons<T> {
  options?: UseQueryOptions<GetCouponsResponse, AxiosError, T, any>;
}

interface GetTeam<T> {
  options?: UseQueryOptions<ITeamInfoAdmin, AxiosError, T, any>;
}

interface PostTeam<T> {
  options?: UseMutationOptions<AxiosError, Types.ITeam, any>;
}

interface ITeamInfoAdmin {
  team: ITeam;
  registrationUrl: string;
}

export function useGetTeam<T = ITeamInfoAdmin>(
  id?: number,
  props?: GetTeam<T>,
) {
  const { data } = useQuery(
    ['team', id],
    async () => {
      if (!id) {
        return Promise.resolve(null);
      }
      const result = await api.get(`admin/team/${id}`);
      return result.data;
    },
    props?.options,
  );

  return data; // return the data directly
}

export function useGetTeams<T = GetTeamsResponse>(props?: GetTeams<T>) {
  return useQuery(
    ['teams'],
    async () => {
      const result = await api.get<GetTeamsResponse>(`admin/team/`);
      return result.data;
    },
    props?.options,
  );
}
export function useGetCoupons<T = GetCouponsResponse>(props?: GetCoupons<T>) {
  return useQuery(
    ['coupons'],
    async () => {
      const result = await api.get<GetCouponsResponse>(`admin/team/coupons`);
      return result.data;
    },
    props?.options,
  );
}

export function useUpdateTeam<T = Types.ITeam>(props?: PostTeam<T>) {
  return useMutation(
    ['updateTeam'],
    async (data: ITeam) => {
      const result = await api.put(`admin/team/edit/${data.id}`, data);
      return result.data;
    },
    props?.options,
  );
}

export function useCreateTeam<T = Types.ITeam>(props?: PostTeam<T>) {
  return useMutation(
    ['createTeam'],
    async (data: ITeam) => {
      const result = await api.post(`admin/team/create`, data);
      return result.data;
    },
    props?.options,
  );
}
