import React from 'react';
import { Controller, Control } from 'react-hook-form';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { IconButton, InputAdornment, Popover, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface Props {
  control: Control<any>;
  name: string;
  label: string;
  placeholder?: string;
  rules?: any;
  required?: boolean;
  textFieldProps?: TextFieldProps;
  withCopyButton?: boolean;
}

const InputField: React.FC<Props> = ({
  control,
  name,
  label,
  placeholder,
  rules,
  required,
  textFieldProps,
  withCopyButton,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const popOverOpen = Boolean(anchorEl);

  const handleCopyButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    text: string,
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    navigator.clipboard.writeText(text).then(() => {
      setTimeout(() => {
        setAnchorEl(null);
      }, 1000);
    });
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <TextField
            required={required}
            fullWidth
            label={label}
            aria-invalid={!!error}
            error={!!error}
            helperText={error?.message}
            placeholder={placeholder ?? ''}
            InputProps={
              withCopyButton && field.value
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="copy icon"
                          onClick={event =>
                            handleCopyButtonClick(event, field.value)
                          }
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }
                : undefined
            }
            {...field}
            {...textFieldProps}
          />
        )}
      />
      <Popover
        open={popOverOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 2 }}>Copied!</Typography>
      </Popover>
    </>
  );
};

export default InputField;
