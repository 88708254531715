import { request } from 'utils/request';
import { IBoostRateData, IGetBoostsData } from './slice/types';

const boostsApi = {
  getBoosts: ({ page, category }: IGetBoostsData) => {
    const query = new URLSearchParams();
    if (page) query.append('page', page);
    if (category) query.append('category', category);

    return request({
      url: `/online_tools${query.toString() ? `?${query.toString()}` : ''}`,
    });
  },
  getTopBoosts: () =>
    request({
      url: '/online_tools/top',
    }),
  getBoost: (id: number) =>
    request({
      url: `/online_tools/${id}`,
    }),
  setBoostRate: (boostId: number, data: IBoostRateData) =>
    request({
      method: 'POST',
      url: `/online_tools/${boostId}/rate`,
      data,
    }),
};

export default boostsApi;
