import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  AppBar,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Toolbar,
  Typography,
} from '@mui/material';
import Logo from '../../../assets/images/pinnacleLogo.svg';
import { useHistory, useParams } from 'react-router-dom';
import { PricingPlans } from './pricingPlans';
import { useQuery } from '../../hooks/useQuery';
import PersonalInformation from '../../components/Registration/steps/personalInformation';
import PlanInformation from '../../components/Registration/planInformation';
import useResponsive from '../../hooks/useResponsive';
import LicenseInformation from '../../components/Registration/steps/licenseInformation';
import Checkout from '../../components/Registration/steps/checkout';
import Login from '../../components/Registration/steps/login';
import useUtmParameters from '../../hooks/useUtmParameters';
import { useGetTeamInfo } from '../../queries/registration/useRegistrationQueries';

export enum QueryParams {
  Step = 'step',
  Uuid = 'uuid',
  Return = 'return',
}

export interface IPricingPlan {
  name: string;
  planCode: string;
  shortName: string;
  price: string | null;
  priceDetails: string;
  pricingDetails: string[];
  features: string[];
}

const steps = [
  'Your Personal Information',
  'Your License Information',
  'Checkout',
  'Login',
];

const Registration = () => {
  const query = useQuery();
  const history = useHistory();
  const { plan } = useParams<{ plan: string }>();
  const step = parseInt(query.get(QueryParams.Step) || '1');
  const uuid = query.get(QueryParams.Uuid);
  const returningUser = query.get(QueryParams.Return) === 'true';
  const [planData, setPlanData] = useState<IPricingPlan | null>(null);
  const locationKeysRef = useRef<(string | undefined)[]>([]);
  const isMobile = useResponsive('down', 767);
  const isTablet = useResponsive('down', 900);
  const isMobileSmall = useResponsive('down', 425);
  const teamUuid = query.get('team');
  const teamInfo = useGetTeamInfo(teamUuid);

  useUtmParameters();

  // Setting plan data based on plan name in url
  useEffect(() => {
    if (teamUuid && !teamInfo.data) {
      return;
    }

    const planIndex = PricingPlans.findIndex(
      p => p.name.replace(/\s+/g, '-').toLowerCase() === plan,
    );
    if (planIndex > -1) {
      const planInfo = PricingPlans[planIndex];

      if (teamInfo?.data?.price) {
        planInfo.price = `$${teamInfo.data.price}`;
      }

      setPlanData(planInfo);
    } else {
      window.location.replace('https://pinnaclera.com/pricing-plans');
    }

    if (planData) {
      let stepName = 'unknown';
      switch (step) {
        case 1:
          stepName = 'Personal information';
          break;
        case 2:
          stepName = 'License information';
          break;
        case 3:
          stepName = 'Payment information';
          break;
        case 4:
          stepName = 'Login information';
          break;
      }

      window.dataLayer.push({
        product: planData?.planCode,
        step: step,
        step_title: stepName,
        event: 'singup_funnel',
      });
    }
  }, [plan, step, planData, teamUuid, teamInfo]);

  // Redirecting to step 1 if uuid is invalid or step is out of range
  useEffect(() => {
    if (!query.has(QueryParams.Step)) {
      // if query params do not contain step remove all query params except uuid and set step to 1
      query.forEach((value, key) => {
        if (key !== QueryParams.Uuid) {
          query.delete(key);
        }
      });
      query.set(QueryParams.Step, '1');
      history.push(`?${query.toString()}`);
    }
    if (step > 4 || (step > 1 && !uuid) || !step) {
      query.set(QueryParams.Step, '1');
      history.push(`?${query.toString()}`);
    }
  }, [history, query, step, uuid]);

  const changeStep = useCallback(
    (direction: 'forward' | 'backward', newUuid?: string) => {
      const nextStep = direction === 'forward' ? step + 1 : step - 1;
      const stepUuid = newUuid || uuid || '';
      query.set(QueryParams.Step, `${nextStep}`);
      query.set(QueryParams.Uuid, stepUuid);
      history.push(`?${query.toString()}`);
    },
    [history, query, step, uuid],
  );

  // Handle browser back button to preserve uuid
  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        locationKeysRef.current = [location.key];
      }

      if (history.action === 'POP') {
        if (locationKeysRef.current[1] === location.key) {
          locationKeysRef.current = locationKeysRef.current.slice(1);
          changeStep('backward');
        }
      }
    });
  }, [changeStep, history]);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 'none',
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'center',
            bgcolor: '#FFFFFF',
          }}
        >
          <img
            src={Logo}
            alt="Pinnacle Realty Advisors logo"
            width={180}
            height={40}
          />
        </Toolbar>
      </AppBar>
      <Box
        component={'main'}
        sx={{ pt: '64px' }}
        width={'100%'}
        position={'relative'}
        display={'flex'}
        justifyContent={'center'}
      >
        {planData ? (
          <Box
            maxWidth={960}
            pt={{ xs: 2, sm: 4 }}
            px={{ xs: 2, sm: 4 }}
            width={'100%'}
          >
            <Typography variant={'h5'} mb={5} textAlign={'center'}>
              {teamInfo?.data
                ? `"${teamInfo.data.team.name}" Team Member Registration`
                : planData.name}
            </Typography>
            <Stepper activeStep={step - 1} sx={{ mb: 5 }}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel
                      sx={
                        isMobileSmall
                          ? {
                              '& .MuiStepLabel-label': {
                                fontSize: '0.8rem',
                              },
                              '& .MuiStepLabel-labelContainer': {
                                display: 'inline-grid',
                                gridTemplateColumns: 'repeat(auto-fit, 100px)',
                              },
                            }
                          : {}
                      }
                    >
                      {!isTablet ? label : step - 1 === index && label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {step <= 4 && isMobile && (
              <Card sx={{ mb: 2, overflow: 'unset' }}>
                <PlanInformation {...planData} />
              </Card>
            )}
            {step === 4 && (
              <Card
                sx={{
                  mb: 2,
                  overflow: 'unset',
                  p: isMobile ? 1 : 2,
                  bgcolor: '#E3F2FD',
                }}
              >
                <CardContent sx={{ m: 'auto', maxWidth: 520 }}>
                  {returningUser ? (
                    <>
                      <Typography variant={'h5'} mb={2} textAlign={'center'}>
                        Welcome back. You have already signed up!
                      </Typography>
                      <Typography variant={'body1'} mb={2} textAlign={'center'}>
                        To continue your onboarding, please log in.
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography variant={'h5'} mb={2} textAlign={'center'}>
                        Your payment was successful!
                      </Typography>
                      <Typography variant={'body1'} mb={2} textAlign={'center'}>
                        A payment to Pinnacle Realty Advisors will appear on
                        your statement.
                      </Typography>
                    </>
                  )}
                </CardContent>
              </Card>
            )}
            <Card sx={{ overflow: 'unset', mb: 5 }}>
              <CardContent>
                <Stack direction={'row'} columnGap={2}>
                  <Box
                    p={isMobile ? 1 : 2}
                    flexBasis={isMobile ? '100%' : '60%'}
                  >
                    {step === 1 && (
                      <PersonalInformation
                        planCode={planData.planCode}
                        changeStep={changeStep}
                        uuid={uuid || ''}
                      />
                    )}
                    {step === 2 && (
                      <LicenseInformation
                        planCode={planData.planCode}
                        changeStep={changeStep}
                        uuid={uuid!}
                      />
                    )}
                    {step === 3 && (
                      <Checkout
                        planCode={planData.planCode}
                        changeStep={changeStep}
                        uuid={uuid!}
                        price={planData.price}
                      />
                    )}
                  </Box>
                  {!isMobile && step < 4 && (
                    <>
                      <Divider
                        variant="middle"
                        orientation="vertical"
                        flexItem
                      />
                      <PlanInformation {...planData} />
                    </>
                  )}
                </Stack>
                {step === 4 && (
                  <Login uuid={uuid!} planCode={planData.planCode} />
                )}
              </CardContent>
            </Card>
          </Box>
        ) : (
          <CircularProgress size={50} />
        )}
      </Box>
    </>
  );
};

export default Registration;
