import { useEffect } from 'react';

export const useScrollAfterChange = (
  changeTrigger: any,
  scrollPointId: string,
  scrollDivideAmount?: number,
) =>
  useEffect(() => {
    const HEADER_HEIGHT = 70;
    const el = document.getElementById(scrollPointId);
    if (
      el &&
      window.pageYOffset > el.offsetTop - (scrollDivideAmount || HEADER_HEIGHT)
    ) {
      window.scrollTo({
        top: el.offsetTop - (scrollDivideAmount || HEADER_HEIGHT),
        behavior: 'smooth',
        left: 0,
      });
    }
  }, [changeTrigger, scrollPointId, scrollDivideAmount]);
