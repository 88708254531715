/**
 *
 * Admin
 *
 */
import React, { useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import RouteConstants from '../../routes';
import Boosts from './pages/Boosts/Boosts';
import Users from './pages/Users/Users';
import UserAddEdit from './pages/UserAddEdit/UserAddEdit';
import EditUserSettings from './pages/EditUserSettings';
import BoostAddEdit from './pages/BoostAddEdit/BoostAddEdit';
import Deals from './pages/Deals/Deals';
import DealAddEdit from './pages/DealAddEdit/DealAddEdit';
import { Layout } from './styles';
import TabPane from 'app/components/TabPane';
import PageHeader from '../../components/PageHeader';
import { Container } from '@mui/material';
import { useStore } from '../../hooks/useStore';
import Teams from './pages/Teams/Teams';
import TeamAddEdit from './pages/TeamAddEdit/TeamAddEdit';
import Team from './pages/TeamAddEdit/Team';

function Admin() {
  const location = useLocation();
  const history = useHistory();
  const [activeLink, setActiveLink] = React.useState<string>(location.pathname);
  const user = useStore(s => s.user);

  useEffect(() => {
    if (!user?.admin) history.push(RouteConstants.myResources.index);
  }, [user, history]);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const sidebarLinks = React.useMemo(
    () => [
      {
        href: RouteConstants.admin.users,
        text: 'Users',
        isVisible: true,
      },
      {
        href: RouteConstants.admin.teams,
        text: 'Teams',
        isVisible: true,
      },
      {
        href: RouteConstants.admin.deals,
        text: 'Local Vendors',
        isVisible: true,
      },
      {
        href: RouteConstants.admin.boosts,
        text: 'Online Tools',
        isVisible: true,
      },
    ],
    [],
  );

  if (user?.admin)
    return (
      <Container maxWidth={'xl'}>
        <PageHeader title={'Admin'} withDivider />
        <Layout>
          <TabPane links={sidebarLinks} activeLink={activeLink} />
          <Switch>
            <Redirect
              exact
              from={RouteConstants.admin.index}
              to={RouteConstants.admin.users}
            />
            <Route
              path={[
                RouteConstants.admin.addDeal,
                RouteConstants.admin.editDeal,
              ]}
              component={DealAddEdit}
            />
            <Route path={RouteConstants.admin.deals} component={Deals} />
            <Route
              path={[
                RouteConstants.admin.addBoost,
                RouteConstants.admin.editBoost,
              ]}
              component={BoostAddEdit}
            />
            <Route
              exact
              path={RouteConstants.admin.boosts}
              component={Boosts}
            />
            <Route exact path={RouteConstants.admin.users} component={Users} />
            <Route
              path={[RouteConstants.admin.addUser]}
              component={UserAddEdit}
            />
            <Route
              path={[RouteConstants.admin.editUser]}
              component={EditUserSettings}
            />
            <Route exact path={RouteConstants.admin.teams} component={Teams} />
            <Route path={[RouteConstants.admin.editTeam]} component={Team} />
            <Route
              path={[RouteConstants.admin.addTeam]}
              component={TeamAddEdit}
            />
            <Route path="*">
              <Redirect to={RouteConstants.admin.users} />
            </Route>
          </Switch>
        </Layout>
      </Container>
    );

  return null;
}

export default Admin;
