import styled, { css } from 'styled-components';
import CheckMark from '../../../assets/images/CheckFilled.svg';

export const TextWrapper = styled.div`
  p,
  li {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    padding-bottom: 8px;
    padding-left: 32px;
  }

  p :last-child,
  li :last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  ul {
    list-style-type: none;
    margin-left: 10px;
    margin-bottom: 0;
  }

  ul li {
    margin-left: 20px;
    align-items: center;
    list-style-type: none;
    background-image: url('${CheckMark}');
    background-repeat: no-repeat;
    padding-left: 25px;
    background-position: 0 0.5em;
    background-size: 1em;
  }

  strong {
    font-weight: bold;
  }

  .disclaimer h3 {
    text-align: center;
  }

  .disclaimer ul li {
    margin-left: 27px;
    padding-bottom: 2px;
    align-items: normal;
    list-style-type: disc;
    background-image: none;
    padding-left: 0;
    background-position: initial;
    background-size: initial;
  }
`;

export const FeaturedImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  margin-top: 2rem;
  border-radius: 10px;
`;

export const Price = styled.div`
  font-weight: 300;
  font-size: 60px;
  letter-spacing: -0.5px;
  color: rgba(17, 21, 53, 0.87);
`;

export const PriceTypeWrapper = styled.span`
  font-weight: 600;
  font-size: 24px;
`;

export const AdditionalPriceText = styled.p`
  color: rgba(17, 21, 53, 0.87);
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  margin-left: 5px;
`;

export const ImageWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-gap: 24px;
  @media (max-width: 550px) {
    grid-gap: 12px;
  }
`;

export const ImageContainer = styled.div<{ background: string }>`
  height: 226px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)),
    url(${props => props.background}) no-repeat center center;
  background-size: cover;
  border-radius: 10px;
  cursor: pointer;
  position: relative;

  @media (max-width: 900px) {
    height: 300px;
  }

  @media (max-width: 550px) {
    height: 350px;
  }
`;

export const ThumbnailIcon = styled.img`
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const GridContainer = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: minmax(340px, 1fr) repeat(
      ${props => props.columns},
      minmax(255px, 300px)
    );
  grid-gap: 24px;

  ${props =>
    props.columns === 2
      ? css`
          @media (max-width: 950px) {
            grid-template-columns: 1fr 1fr;
            .service-card {
              grid-column: 1 / span 2;
            }
          }

          @media (max-width: 550px) {
            grid-template-columns: 1fr;
            .service-card {
              grid-column: 1 / span 1;
            }
          }
        `
      : css`
          @media (max-width: 650px) {
            grid-template-columns: 1fr;
            .service-card {
              grid-column: 1 / span 1;
            }
          }
        `}
`;
export const Image = styled.img`
  display: block;
  max-width: 100%;
  height: 50px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 8px;
`;

export const Button = styled.div`
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.12;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 4px;
  background-color: ${props => props.theme.blue.primary};
  color: ${props => props.theme.white};
  padding: 15px;
`;
