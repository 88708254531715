import { request } from 'utils/request';
import { IUpdateUserData, IUpdateUserPublicData } from './slice/types';
import { IUserInnerPageImageUrl } from '../Admin/slice/types';

const userProfileApi = {
  getUserInfo: () =>
    request({
      url: 'hq/user/info/',
    }),
  updateUserData: (data: IUpdateUserData) =>
    request({
      method: 'PUT',
      url: 'hq/edit/user/',
      data,
    }),
  updateUserPublicData: (data: IUpdateUserPublicData) =>
    request({
      method: 'POST',
      url: `/hq/user/inner-page`,
      data,
    }),
  updateUserPublicProfileImageUrl: (data: IUserInnerPageImageUrl) =>
    request({
      method: 'POST',
      url: `hq/user/inner-page-image`,
      data,
    }),
  uploadImage: (data: FormData) => {
    return request({
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      url: 'profile/upload',
      data,
    });
  },
  getStripeAccountUrl: () =>
    request({
      url: '/stripe/customer_portal',
    }),
  getMyTeam: () =>
    request({
      url: `/team/my-team`,
    }),
};

export default userProfileApi;
