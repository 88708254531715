import React from 'react';

export const svgIcons = {
  shareFacebook: () => (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9062 17.5938H12.7635V11.9357H14.4979H14.9374L14.9937 11.4998L15.3289 8.90667L15.4018 8.34258H14.833H12.7635V7.18613C12.7635 6.83139 12.8189 6.67275 12.8848 6.59442C12.9344 6.53549 13.0766 6.42285 13.5482 6.42285H14.9211H14.9232H15.4232V5.92285V3.60469V3.16771L14.9902 3.10919C14.7261 3.0735 13.8891 3.00156 12.9209 3.00156C11.8289 3.00156 10.8578 3.33665 10.1583 4.02818C9.45723 4.72123 9.08438 5.71741 9.08438 6.93047V8.34258H7.34355H6.84355V8.84258V11.4379V11.9379H7.34355H9.08438V17.5938H1.09375C0.989619 17.5938 0.90625 17.5104 0.90625 17.4062V1.59375C0.90625 1.48962 0.989619 1.40625 1.09375 1.40625H16.9062C17.0104 1.40625 17.0938 1.48962 17.0938 1.59375V17.4062C17.0938 17.5104 17.0104 17.5938 16.9062 17.5938Z"
        fill="#262626"
        stroke="#262626"
      />
    </svg>
  ),
  shareTwitter: () => (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9062 0.90625H1.09375C0.713477 0.90625 0.40625 1.21348 0.40625 1.59375V17.4062C0.40625 17.7865 0.713477 18.0938 1.09375 18.0938H16.9062C17.2865 18.0938 17.5938 17.7865 17.5938 17.4062V1.59375C17.5938 1.21348 17.2865 0.90625 16.9062 0.90625ZM13.6256 7.13027C13.632 7.23125 13.632 7.33652 13.632 7.43965C13.632 10.5936 11.2301 14.2266 6.84082 14.2266C5.4873 14.2266 4.23262 13.8334 3.17559 13.1566C3.36895 13.1781 3.55371 13.1867 3.75137 13.1867C4.86856 13.1867 5.89551 12.8086 6.71406 12.1684C5.66563 12.1469 4.78477 11.4594 4.48398 10.5141C4.85137 10.5678 5.18223 10.5678 5.56035 10.4711C5.02051 10.3614 4.53528 10.0682 4.18711 9.64133C3.83893 9.21444 3.64929 8.68017 3.65039 8.1293V8.09922C3.96621 8.27754 4.33789 8.38711 4.72676 8.40215C4.39986 8.18429 4.13177 7.88912 3.94625 7.54284C3.76074 7.19655 3.66355 6.80984 3.66328 6.41699C3.66328 5.97227 3.7793 5.56621 3.9877 5.21387C4.5869 5.9515 5.33462 6.5548 6.18225 6.98454C7.02988 7.41427 7.95845 7.66084 8.90762 7.7082C8.57031 6.08613 9.78203 4.77344 11.2387 4.77344C11.9262 4.77344 12.5449 5.06133 12.9811 5.52539C13.5203 5.42441 14.0359 5.22246 14.4957 4.95176C14.3174 5.50391 13.9436 5.97012 13.4473 6.26445C13.9285 6.21289 14.3926 6.07969 14.8223 5.89277C14.4979 6.36973 14.0918 6.79297 13.6256 7.13027Z"
        fill="#262626"
      />
    </svg>
  ),
  shareLinkedin: () => (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.171 16.0521H5.671V15.5521V7.34941V6.84941H5.171H2.6208H2.1208V7.34941V15.5521V16.0521H2.6208H5.171ZM5.8751 4.75112V4.7498C5.8751 4.35857 5.75908 3.97612 5.54172 3.65082C5.32436 3.32552 5.01542 3.07197 4.65397 2.92226C4.29251 2.77254 3.89478 2.73336 3.51106 2.80969C3.12734 2.88602 2.77487 3.07441 2.49823 3.35106C2.22158 3.6277 2.03318 3.98017 1.95686 4.36389C1.88053 4.74761 1.9197 5.14535 2.06942 5.5068C2.21914 5.86826 2.47268 6.1772 2.79798 6.39456C3.12329 6.61191 3.50574 6.72793 3.89697 6.72793C4.9881 6.72793 5.87223 5.84191 5.8751 4.75112ZM14.7187 16.0521H15.2187V15.5521V11.0533C15.2187 9.93822 15.1044 8.83469 14.5815 8.00461C14.0253 7.12151 13.0758 6.64531 11.6614 6.64531C10.8724 6.64531 10.2158 6.8843 9.71436 7.23256V6.84941H9.21436H6.76943H6.26943V7.34941V15.5521V16.0521H6.76943H9.31533H9.81533V15.5521V11.4937C9.81533 10.9682 9.87016 10.5544 10.0208 10.2814C10.0897 10.1567 10.1759 10.0668 10.289 10.0042C10.4049 9.94005 10.5787 9.88828 10.845 9.88828C11.0924 9.88828 11.2332 9.94503 11.3181 10.0042C11.404 10.0642 11.476 10.1569 11.5335 10.3023C11.6599 10.622 11.6706 11.0643 11.6706 11.5625V15.5521V16.0521H12.1706H14.7187ZM0.760254 1.40625H16.5728C16.6769 1.40625 16.7603 1.48962 16.7603 1.59375V17.4062C16.7603 17.5104 16.6769 17.5938 16.5728 17.5938H0.760254C0.656123 17.5938 0.572754 17.5104 0.572754 17.4062V1.59375C0.572754 1.48962 0.656123 1.40625 0.760254 1.40625Z"
        fill="#262626"
        stroke="#262626"
      />
    </svg>
  ),
  shareEmail: () => (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1044 2.92352L17.9116 3.07312V3.07323L10.7615 8.6272L10.7615 8.62722C10.6382 8.72304 10.4865 8.77505 10.3303 8.77505C10.1741 8.77505 10.0224 8.72304 9.89904 8.62722L2.56049 2.92138L2.56013 2.92109L2.36416 2.76843L2.59429 2.47272L3.11924 2.88115L3.25467 2.98652H3.2554L10.0266 8.25022L10.3335 8.48877L10.6404 8.25022L17.4116 2.98652H17.4145L17.5499 2.88115L18.0735 2.47376L18.3031 2.76873L18.1052 2.92288L18.105 2.92299L18.1049 2.9231L18.1044 2.92352ZM17.7747 1.4375L17.0686 1.98652H3.59848L2.89283 1.4375H17.7747ZM1.396 1.4375H2.12896L2.11 1.46187L1.26566 2.54683L1.2085 2.62029V1.625C1.2085 1.52087 1.29186 1.4375 1.396 1.4375ZM1.2085 15.375V3.13627L1.35514 3.2505L1.75537 3.56228V14.5156V15.0156H2.25537H18.4116H18.9116V14.5156V3.56282L19.3133 3.25104L19.4585 3.13835V15.375C19.4585 15.4791 19.3751 15.5625 19.271 15.5625H1.396C1.29186 15.5625 1.2085 15.4791 1.2085 15.375ZM19.4585 1.625V2.62243L19.4013 2.54898L18.557 1.46402L18.5364 1.4375H19.271C19.3751 1.4375 19.4585 1.52087 19.4585 1.625Z"
        fill="#FFFFFF"
        stroke="#FFFFFF"
      />
    </svg>
  ),
  shareLink: () => (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 0.5H3.9375C3.83437 0.5 3.75 0.584375 3.75 0.6875V2C3.75 2.10312 3.83437 2.1875 3.9375 2.1875H15.5625V18.3125C15.5625 18.4156 15.6469 18.5 15.75 18.5H17.0625C17.1656 18.5 17.25 18.4156 17.25 18.3125V1.25C17.25 0.835156 16.9148 0.5 16.5 0.5ZM13.5 3.5H1.5C1.08516 3.5 0.75 3.83516 0.75 4.25V16.6883C0.75 16.8875 0.829687 17.0773 0.970312 17.218L5.03203 21.2797C5.08359 21.3312 5.14219 21.3734 5.20547 21.4086V21.4531H5.30391C5.38594 21.4836 5.47266 21.5 5.56172 21.5H13.5C13.9148 21.5 14.25 21.1648 14.25 20.75V4.25C14.25 3.83516 13.9148 3.5 13.5 3.5ZM5.20312 19.0672L3.18516 17.0469H5.20312V19.0672ZM12.5625 19.8125H6.70312V16.4844C6.70312 15.9664 6.28359 15.5469 5.76562 15.5469H2.4375V5.1875H12.5625V19.8125Z"
        fill="#FFFFFF"
      />
    </svg>
  ),
  arrowLeft: () => (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.1875 6.20581L12.4375 6.20581"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.72485 10.724L1.18735 6.20601L5.72485 1.68726"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  google: () => (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.25 1.75H2.75C2.47344 1.75 2.25 1.97344 2.25 2.25V13.75C2.25 14.0266 2.47344 14.25 2.75 14.25H14.25C14.5266 14.25 14.75 14.0266 14.75 13.75V2.25C14.75 1.97344 14.5266 1.75 14.25 1.75ZM11.1094 10.9C10.475 11.4844 9.60938 11.8281 8.57656 11.8281C7.08125 11.8281 5.7875 10.9703 5.15781 9.72031C4.88952 9.18655 4.74985 8.5974 4.75 8C4.75 7.38125 4.89844 6.79688 5.15781 6.27969C5.7875 5.02812 7.08125 4.17031 8.57656 4.17031C9.60781 4.17031 10.4734 4.55 11.1375 5.16719L10.0406 6.26562C9.64375 5.88594 9.13906 5.69375 8.57812 5.69375C7.58125 5.69375 6.7375 6.36719 6.43594 7.27187C6.35938 7.50156 6.31563 7.74687 6.31563 8C6.31563 8.25313 6.35938 8.49844 6.43594 8.72812C6.7375 9.63281 7.58125 10.3062 8.57656 10.3062C9.09219 10.3062 9.52969 10.1703 9.87187 9.94063C10.2781 9.66875 10.5469 9.26406 10.6359 8.78438H8.57656V7.30312H12.1812C12.2266 7.55469 12.25 7.81563 12.25 8.08594C12.25 9.25313 11.8328 10.2328 11.1094 10.9Z"
        fill="#409D97"
      />
    </svg>
  ),
  loginFacebook: () => (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.25 1.75H2.75C2.47344 1.75 2.25 1.97344 2.25 2.25V13.75C2.25 14.0266 2.47344 14.25 2.75 14.25H14.25C14.5266 14.25 14.75 14.0266 14.75 13.75V2.25C14.75 1.97344 14.5266 1.75 14.25 1.75ZM12.8062 5.39844H11.8078C11.025 5.39844 10.8734 5.77031 10.8734 6.31719V7.52187H12.7422L12.4984 9.40781H10.8734V14.25H8.925V9.40938H7.29531V7.52187H8.925V6.13125C8.925 4.51719 9.91094 3.6375 11.3516 3.6375C12.0422 3.6375 12.6344 3.68906 12.8078 3.7125V5.39844H12.8062Z"
        fill="#409D97"
      />
    </svg>
  ),
  upload: () => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.85234 12.4923C8.86988 12.5147 8.89229 12.5328 8.91788 12.5453C8.94346 12.5578 8.97154 12.5642 9 12.5642C9.02846 12.5642 9.05654 12.5578 9.08212 12.5453C9.10771 12.5328 9.13012 12.5147 9.14766 12.4923L11.7727 9.17122C11.8687 9.04934 11.782 8.86887 11.625 8.86887H9.88828V0.937622C9.88828 0.834497 9.80391 0.750122 9.70078 0.750122H8.29453C8.19141 0.750122 8.10703 0.834497 8.10703 0.937622V8.86653H6.375C6.21797 8.86653 6.13125 9.047 6.22734 9.16887L8.85234 12.4923ZM17.5781 11.672H16.1719C16.0688 11.672 15.9844 11.7564 15.9844 11.8595V15.4689H2.01562V11.8595C2.01562 11.7564 1.93125 11.672 1.82812 11.672H0.421875C0.31875 11.672 0.234375 11.7564 0.234375 11.8595V16.5001C0.234375 16.915 0.569531 17.2501 0.984375 17.2501H17.0156C17.4305 17.2501 17.7656 16.915 17.7656 16.5001V11.8595C17.7656 11.7564 17.6812 11.672 17.5781 11.672Z"
        fill="#262626"
      />
    </svg>
  ),
  close: () => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.01187 7L13.1388 0.888672C13.2248 0.78711 13.1525 0.632812 13.0197 0.632812H11.4611C11.3693 0.632812 11.2814 0.673828 11.2209 0.744141L6.99234 5.78516L2.76383 0.744141C2.70523 0.673828 2.61734 0.632812 2.52359 0.632812H0.965C0.832188 0.632812 0.759922 0.78711 0.84586 0.888672L5.97281 7L0.84586 13.1113C0.826609 13.134 0.814259 13.1617 0.810275 13.1911C0.806291 13.2206 0.810841 13.2505 0.823386 13.2775C0.83593 13.3044 0.855941 13.3272 0.881043 13.3431C0.906145 13.359 0.935284 13.3674 0.965 13.3672H2.52359C2.61539 13.3672 2.70328 13.3262 2.76383 13.2559L6.99234 8.21484L11.2209 13.2559C11.2795 13.3262 11.3673 13.3672 11.4611 13.3672H13.0197C13.1525 13.3672 13.2248 13.2129 13.1388 13.1113L8.01187 7Z"
        fill="#4AB3AD"
      />
    </svg>
  ),
};
