import React, { useMemo } from 'react';
import { generatePath, useLocation } from 'react-router';
import { Empty, Skeleton } from 'antd';
import { List } from './OffersList.styles';
import OfferCard from '../OfferCard';
import { IBoost } from 'app/containers/Boosts/slice/types';
import RouteConstants from 'app/routes';

interface IProps {
  isLoading: boolean;
  boosts: Array<IBoost> | null;
  routeName?: string;
}

const OffersList = ({ isLoading, boosts, routeName }: IProps) => {
  const { search } = useLocation();

  const listItems = useMemo(() => {
    if (boosts) {
      return boosts.map(boost => (
        <OfferCard
          key={boost.onlineTool.id}
          logo={boost.onlineTool.logoUrl}
          background={boost.onlineTool.coverUrl || ''}
          companyName={boost.onlineTool.companyName}
          title={boost.onlineTool.title}
          description={boost.onlineTool.shortDescription || ''}
          prices={boost.onlineTool.pricing}
          link={`${generatePath(routeName ?? RouteConstants.boosts.boostInner, {
            boostId: boost.onlineTool.id,
          })}${search}`}
          isLoading={isLoading}
        />
      ));
    }
    return null;
  }, [boosts, search, isLoading, routeName]);

  if (isLoading && (!boosts || boosts?.length === 0))
    return <Skeleton active />;

  if (boosts && boosts.length > 0) return <List>{listItems}</List>;

  return <Empty description="No online tools found" />;
};

export default OffersList;
