import api from '../api';
import { IOnlineTools } from './types';
import { useQuery } from '@tanstack/react-query';

export function useFetchOnlineTools<T = IOnlineTools>(filters?: object) {
  const queryString = filters
    ? Object.keys(filters)
        .map(key => `${key}=${filters[key]}`)
        .join('&')
    : '';

  return useQuery(['boosts', queryString], async () => {
    const result = await api.get<T>(`/online_tools?${queryString}`);
    return result.data;
  });
}
