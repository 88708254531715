import { Theme } from '@mui/material';
import Button from './Button';
import TreeItem from './TreeView';
import TableRow from './TableRow';
import Tab from './Tab';
import Card from './Card';
import Step from './Step';

export default function ComponentsOverrides(theme: Theme) {
  return Object.assign(
    Card(theme),
    Button(theme),
    TreeItem(theme),
    TableRow(theme),
    Tab(theme),
    Step(theme),
  );
}
