import { PayloadAction } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { takeLatest, delay, put } from 'redux-saga/effects';
import { globalActions as actions } from '.';
import { ICoverImages } from './types';
import globalApi from '../globalApi';

function* handleErrorSaga(action: PayloadAction<any>) {
  try {
    const error = action.payload;
    console.log('error in handleErrorSaga:', error);
    if (error?.status === 401) {
      window.location.reload();
      yield delay(500);
      return;
    }

    if (error?.status === 404) return;

    if (error.data?.detail) {
      notification.error({
        message: error.data.detail,
        placement: 'bottomRight',
      });
      return;
    }

    notification.error({
      message: 'Unexpected error happened',
      placement: 'bottomRight',
    });
  } catch (err) {
    console.log(err);
  }
}

function* getCoverImageList() {
  try {
    const coverImages: ICoverImages = yield globalApi.getCoverImageList();
    yield put(actions.setCoverImageList(coverImages));
  } catch (err) {
    yield put(actions.handleError(err));
  }
}

export function* globalSaga() {
  yield takeLatest(actions.handleError.type, handleErrorSaga);
  yield takeLatest(actions.getCoverImageList.type, getCoverImageList);
}
