import { alpha } from '@mui/material/styles';

const GREY = {
  100_8: alpha('#FFFFFF', 0.8),
};

const PRIMARY = {
  main: '#056BA4',
  dark: '#004074',
  light: '#5298D5',
};

const SECONDARY = {
  main: '#1FA3F0',
  dark: '#0075BD',
  light: '#6DD4FF',
};

const PRIMARY_SHADES = {
  shade_004: alpha(PRIMARY.main, 0.04),
  shade_008: alpha(PRIMARY.main, 0.08),
  shade_012: alpha(PRIMARY.main, 0.12),
  shade_030: alpha(PRIMARY.main, 0.3),
  shade_050: alpha(PRIMARY.main, 0.5),
};

const palette = {
  common: { black: '#000', white: '#FFF', text_main: alpha('#111535DE', 0.87) },
  primary: { ...PRIMARY, ...PRIMARY_SHADES },
  secondary: SECONDARY,
  grey: GREY,
  black: { main: '#000' },
  stripe: { main: '#635BFF' },
  error: { main: '#F88379' },
};

export default palette;
