import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { boostsSaga } from './saga';
import {
  IBoostsState,
  IBoost,
  IBoostRatePayload,
  IBoosts,
  IGetBoostsData,
  ISetIsBoostsLoading,
} from './types';

export const initialState: IBoostsState = {
  isLoading: false,
  boosts: {
    online_tools: null,
    categories: null,
    filters: {
      pagination: {
        total_pages: 1,
        current_page: 1,
        page_size: 10,
        total: 0,
      },
      category: '',
    },
  },
  tools: {
    isLoading: false,
    online_tools: null,
  },
  boost: null,
};

const slice = createSlice({
  name: 'boosts',
  initialState,
  reducers: {
    getBoosts(state, action: PayloadAction<IGetBoostsData>) {
      if (action.payload.specificCategory) {
        if (action.payload.category === 'membership-tools') {
          state.tools.isLoading = true;
        }
      } else {
        state.isLoading = true;
      }
    },
    setBoosts(state, action: PayloadAction<IBoosts>) {
      state.boosts = action.payload;
    },
    setTools(state, action: PayloadAction<Array<IBoost>>) {
      state.tools.online_tools = action.payload;
    },
    getBoost(state, action: PayloadAction<number>) {
      state.isLoading = true;
    },
    setBoost(state, action: PayloadAction<IBoost | null>) {
      state.boost = action.payload;
    },
    setRating(state, action: PayloadAction<IBoostRatePayload>) {},
    setIsLoading(state, action: PayloadAction<ISetIsBoostsLoading>) {
      state.isLoading = action.payload.isLoading;
    },
  },
});

export const { actions: boostsActions } = slice;

export const useBoostsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: boostsSaga });
  return { actions: slice.actions };
};
