import React, { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import {
  ImageContainer,
  ImageWrapper,
  ThumbnailIcon,
} from '../OnlineToolPriceCard/styles';
import FsLightbox from 'fslightbox-react';
import PlayIcon from '../../../assets/images/PlayIcon.svg';
import { IAsset } from '../../containers/Boosts/slice/types';

interface IAssetsGalleryProps {
  assets: IAsset[];
}

const AssetsGallery = ({ assets }: IAssetsGalleryProps) => {
  const [lightBoxController, setLightBoxController] = useState<{
    toggler: boolean;
    contentIndex: number;
  }>({
    toggler: false,
    contentIndex: 0,
  });

  return (
    <>
      {assets.length > 0 && (
        <>
          <Box pb={4}>
            <Stack direction={'row'} gap={0.5} mb={2}>
              <FeedOutlinedIcon color={'primary'} />
              <Typography variant={'h6'}>Images & videos</Typography>
            </Stack>
            <ImageWrapper>
              {assets.map((item, index) => (
                <ImageContainer
                  key={index}
                  background={
                    (item.thumbnailUrl ? item.thumbnailUrl : item.imageUrl) +
                    '?preview'
                  }
                  onClick={() =>
                    setLightBoxController({
                      toggler: !lightBoxController.toggler,
                      contentIndex: index,
                    })
                  }
                >
                  {item.thumbnailUrl && (
                    <ThumbnailIcon src={PlayIcon} alt="PlayIcon" />
                  )}
                </ImageContainer>
              ))}
            </ImageWrapper>
          </Box>
          <FsLightbox
            toggler={lightBoxController.toggler}
            sourceIndex={lightBoxController.contentIndex}
            sources={assets.map(item => item.imageUrl)}
          />
        </>
      )}
    </>
  );
};

export default AssetsGallery;
