import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state?.deals || initialState;

export const selectDeals = createSelector([selectSlice], state => state.deals);

export const selectDeal = createSelector([selectSlice], state => state.deal);

export const selectIsLoading = createSelector(
  [selectSlice],
  state => state.isLoading,
);
