import { selectIsLoading, selectTeams } from '../../slice/selectors';
import React, { useEffect, useMemo } from 'react';
import {
  Header,
  Logo,
  Table,
  TeamListRow,
  TeamTableHeader,
} from '../../styles';
import { generatePath } from 'react-router-dom';
import Typography from 'app/components/Typography';
import RouteConstants from 'app/routes';
import { useDispatch, useSelector } from 'react-redux';
import { Empty, Skeleton } from 'antd';
import { useQuery } from 'app/hooks/useQuery';
import { Link } from 'app/components/Clicks';
import ScrollToTopOnMount from 'app/components/ScrollToTopOnMount';
import { useAdminSlice } from '../../slice';
import nologo from '../../../../../assets/images/nologo.jpg';

const Teams = () => {
  const teams = useSelector(selectTeams);
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch();
  const { actions } = useAdminSlice();
  const query = useQuery();

  useEffect(() => {
    dispatch(actions.getTeams());
  }, [actions, dispatch, query]);

  const tableRows = useMemo(() => {
    return teams?.map(team => (
      <TeamListRow
        key={`team-${team.id}`}
        to={generatePath(RouteConstants.admin.editTeam, {
          teamId: team.id,
        })}
        loading={isLoading ? 'true' : 'false'}
      >
        <Logo src={team.logo ?? nologo} alt="Team logo" />
        <Typography type="div">{team.name}</Typography>
        <Typography type="div">{team.uuid}</Typography>
      </TeamListRow>
    ));
  }, [teams, isLoading]);

  return (
    <div>
      <ScrollToTopOnMount />
      <Header>
        <Typography type="h" level={4}>
          All teams
        </Typography>
        <Link to={RouteConstants.admin.addTeam} common={{ small: 'true' }}>
          Add new
        </Link>
      </Header>
      <Table>
        <TeamTableHeader>
          <Typography type="div" bodyVariation="bodySmall">
            Logo
          </Typography>
          <Typography type="div" bodyVariation="bodySmall">
            Name
          </Typography>
          <Typography type="div" bodyVariation="bodySmall">
            UUID
          </Typography>
        </TeamTableHeader>
        {isLoading && (!teams || teams?.length === 0) ? (
          <Skeleton active style={{ padding: '1.6rem 2.4rem' }} />
        ) : teams && teams.length > 0 ? (
          tableRows
        ) : (
          <Empty
            description="No vendors found"
            style={{ padding: '1.6rem 2.4rem' }}
          />
        )}
      </Table>
    </div>
  );
};

export default Teams;
