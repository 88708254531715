export default function TreeItem(theme) {
  return {
    MuiTreeItem: {
      styleOverrides: {
        content: {
          borderRadius: 100,
          padding: '5px 8px',
          color: theme.palette.common.black,
          '.MuiTypography-body1, .MuiTreeItem-label': {
            color: theme.palette.common.black,
          },
          '&:hover': {
            backgroundColor: theme.palette.primary.shade_004,
            color: theme.palette.primary.light,
            '.MuiTreeItem-label, .MuiTypography-body1': {
              color: theme.palette.primary.light,
            },
          },
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: theme.palette.primary.shade_008,
            color: theme.palette.primary.main,
            '.MuiTreeItem-label, .MuiTypography-body1': {
              color: theme.palette.primary.main,
            },
            '&.Mui-focused': {
              backgroundColor: theme.palette.primary.shade_008,
              color: theme.palette.primary.main,
            },
          },
        },
      },
    },
  };
}
