import Axios, { AxiosRequestConfig } from 'axios';
import { API_URL } from './constants';

const baseURL = API_URL;

const client = Axios.create({
  withCredentials: true,
  baseURL,
  headers: { 'Content-Type': 'application/json' },
});

export const request = function (
  options: AxiosRequestConfig,
  isReturnFullResponse: boolean = false,
) {
  const onSuccess = function (response: any) {
    if (isReturnFullResponse) return response;
    return response.data;
  };

  const onError = function (error: any) {
    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};
