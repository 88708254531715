import { takeLatest, put } from 'redux-saga/effects';
import { boostsActions as actions } from '.';
import boostsApi from '../boostsApi';
import { globalActions } from 'app/containers/Global/slice';
import { IBoost, IBoostRatePayload, IBoosts, IGetBoostsData } from './types';
import { PayloadAction } from '@reduxjs/toolkit';
import { takeEvery } from '@redux-saga/core/effects';

function getNumberOfBoosts(boosts: IBoosts, numberOfBoosts: number) {
  const onlineTools = boosts.online_tools?.slice(0, numberOfBoosts);
  return onlineTools ? onlineTools : [];
}

function* getBoostsSaga(action: PayloadAction<IGetBoostsData>) {
  try {
    const boosts: IBoosts = yield boostsApi.getBoosts(action.payload);
    if (action.payload.specificCategory) {
      if (action.payload.category === 'membership-tools') {
        const tools = getNumberOfBoosts(boosts, 6);
        yield put(actions.setTools(tools));
      }
    } else {
      yield put(actions.setBoosts(boosts));
    }
  } catch (err) {
    yield put(globalActions.handleError(err));
  } finally {
    yield put(
      actions.setIsLoading({
        isLoading: false,
        boostCategory: action.payload.category ? action.payload.category : '',
      }),
    );
  }
}

function* getBoostSaga(action: PayloadAction<number>) {
  try {
    const boost: IBoost = yield boostsApi.getBoost(action.payload);
    yield put(actions.setBoost(boost));
  } catch (err) {
    yield put(globalActions.handleError(err));
  } finally {
    yield put(actions.setIsLoading({ isLoading: false }));
  }
}

function* setRatingSaga(action: PayloadAction<IBoostRatePayload>) {
  try {
    const { boostId, data } = action.payload;
    const boost: IBoost = yield boostsApi.setBoostRate(boostId, data);
    yield put(actions.setBoost(boost));
  } catch (err) {
    yield put(globalActions.handleError(err));
  } finally {
    yield put(actions.setIsLoading({ isLoading: false }));
  }
}

export function* boostsSaga() {
  yield takeEvery(actions.getBoosts.type, getBoostsSaga);
  yield takeLatest(actions.getBoost.type, getBoostSaga);
  yield takeLatest(actions.setRating.type, setRatingSaga);
}
