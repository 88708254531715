import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { globalSaga } from './saga';
import { GlobalState, ICoverImages, ISnackBarAction } from './types';

export const initialState: GlobalState = {
  redirectTo: null,
  isHeaderBack: false,
  coverImages: [],
  coverImagesFetchStatus: 'idle',
  snackbar: {
    isOpen: false,
    message: '',
    type: undefined,
    anchor: undefined,
  },
};

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setRedirectTo(state, action: PayloadAction<string | null>) {
      state.redirectTo = action.payload;
    },
    handleError(state, action: PayloadAction<any>) {},
    showSnackbar(state, action: PayloadAction<ISnackBarAction>) {
      state.snackbar.isOpen = true;
      state.snackbar.message = action.payload.message;
      state.snackbar.type = action.payload.type;
      state.snackbar.anchor = action.payload.anchor;
    },
    hideSnackbar(state) {
      state.snackbar.isOpen = false;
    },
    setIsHeaderBack(state, action: PayloadAction<boolean>) {
      state.isHeaderBack = action.payload;
    },
    getCoverImageList(state) {
      state.coverImagesFetchStatus = 'loading';
    },
    setCoverImageList(state, action: PayloadAction<ICoverImages>) {
      state.coverImagesFetchStatus = 'success';
      state.coverImages = action.payload;
    },
  },
});

export const { actions: globalActions } = slice;

export const useGlobalSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: globalSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useGlobalSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
