import React, { useEffect, useMemo } from 'react';
import { Header, Table, TableHeader, TableRow, Logo } from '../../styles';
import { generatePath } from 'react-router-dom';
import Typography from 'app/components/Typography';
import RouteConstants from 'app/routes';
import { selectDeals, selectIsLoading } from '../../slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Empty, Skeleton } from 'antd';
import { useQuery } from 'app/hooks/useQuery';
import { Link } from 'app/components/Clicks';
import ScrollToTopOnMount from 'app/components/ScrollToTopOnMount';
import { useAdminSlice } from '../../slice';

const Deals = () => {
  const deals = useSelector(selectDeals);
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch();
  const { actions } = useAdminSlice();
  const query = useQuery();

  useEffect(() => {
    dispatch(actions.getDeals());
  }, [actions, dispatch, query]);

  const tableRows = useMemo(() => {
    return deals?.map(deal => (
      <TableRow
        key={`deal-${deal.id}`}
        to={generatePath(RouteConstants.admin.editDeal, {
          dealId: deal.id,
        })}
        loading={isLoading ? 'true' : 'false'}
      >
        {deal.logoUrl ? (
          <div>
            <Logo src={deal.logoUrl} alt="Company logo" />
            <Typography type="div" bodyVariation="bodySmall">
              {deal.companyName}
            </Typography>
          </div>
        ) : (
          <Typography type="div" common={{ fontWeight: 600 }}>
            {deal.companyName}
          </Typography>
        )}
        <Typography type="div">{deal.title}</Typography>
      </TableRow>
    ));
  }, [deals, isLoading]);

  return (
    <div>
      <ScrollToTopOnMount />
      <Header>
        <Typography type="h" level={4}>
          All vendors
        </Typography>
        <Link to={RouteConstants.admin.addDeal} common={{ small: 'true' }}>
          Add new
        </Link>
      </Header>
      <Table>
        <TableHeader>
          <Typography type="div" bodyVariation="bodySmall">
            Partner
          </Typography>
          <Typography type="div" bodyVariation="bodySmall">
            Deal title
          </Typography>
        </TableHeader>
        {isLoading && (!deals || deals?.length === 0) ? (
          <Skeleton active style={{ padding: '1.6rem 2.4rem' }} />
        ) : deals && deals.length > 0 ? (
          tableRows
        ) : (
          <Empty
            description="No vendors found"
            style={{ padding: '1.6rem 2.4rem' }}
          />
        )}
      </Table>
    </div>
  );
};

export default Deals;
