import styled, { keyframes } from 'styled-components';
import { TableCell, TableHead, TableRow } from '@mui/material';
const blur = keyframes`
  0%,
  90% {
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
  }
  50% {
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
  }
`;
interface IRowProps {
  loading: 'true' | 'false';
}

export const Table = styled.div`
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin-top: 2rem;
  display: block;

  > * {
    &:not(:last-child) {
      border-bottom: 1px solid #f0f0f0;
    }
  }
`;

export const MembersTableHeader = styled(TableHead)`
  height: 56px;
  left: 0px;
  top: 0px;
  padding: 0px 0px 0px 90px;
  justify: space-between;
  background: rgba(25, 118, 210, 0.08);
  border-radius: 10px 10px 0px 0px;
`;

export const MemberTableRow = styled(TableRow)<IRowProps>`
  border-bottom: none;
  padding: 0px 16px;
`;

export const MemberTableCell = styled(TableCell)``;

export const Logo = styled.img`
  display: block;
  max-height: 2.5rem;
  object-fit: contain;
  margin-bottom: 8px;
  clip-path: circle(50% at 50% 50%);
`;
