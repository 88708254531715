import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import {
  IProfile,
  IUpdateUserData,
  IUserData,
  IUpdateUserPublicData,
} from './types';
import { userProfileSaga } from './saga';
import { fetchStatus } from '../../Global/slice/types';
import { IMyTeam, IUserInnerPageImageUrl } from '../../Admin/slice/types';
import { IUser } from '../../../queries/auth/types';

export const initialState: IProfile = {
  userData: null,
  stripeAccountUrl: null,
  myTeam: null,
  stripeUrlFetchStatus: 'idle',
  userDataFetchStatus: 'idle',
  myTeamDataFetchStatus: 'idle',
};

const slice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    getUserInfo(state) {
      state.userDataFetchStatus = 'loading';
    },
    setUserInfo(state, action: PayloadAction<IUser>) {
      state.userData = {
        user: action.payload,
      };
      state.userDataFetchStatus = 'success';
    },
    setUserInfoWithAddress(state, action: PayloadAction<IUserData>) {
      state.userData = action.payload;
      state.userDataFetchStatus = 'success';
    },
    setMyTeam(state, action: PayloadAction<IMyTeam>) {
      state.myTeam = action.payload;
      state.myTeamDataFetchStatus = 'success';
    },
    getMyTeam(state) {
      state.myTeamDataFetchStatus = 'loading';
    },
    updateUserInfo(state, action: PayloadAction<IUpdateUserData>) {
      state.userDataFetchStatus = 'loading';
    },
    updateUserPublicInfo(state, action: PayloadAction<IUpdateUserPublicData>) {
      state.userDataFetchStatus = 'loading';
    },
    updateUserPublicProfilePicture(state, action: PayloadAction<File | null>) {
      state.userDataFetchStatus = 'loading';
    },
    updateUserPublicProfileImageUrl(
      state,
      action: PayloadAction<IUserInnerPageImageUrl>,
    ) {
      state.userDataFetchStatus = 'loading';
    },
    getStripeAccountUrl(state) {
      state.stripeUrlFetchStatus = 'loading';
    },
    setStripeAccountUrl(state, action: PayloadAction<string>) {
      state.stripeAccountUrl = action.payload;
      state.stripeUrlFetchStatus = 'success';
    },
    setStripeUrlFetchStatus(state, action: PayloadAction<fetchStatus>) {
      state.stripeUrlFetchStatus = action.payload;
    },
    setFetchStatusByKey(
      state,
      action: PayloadAction<{ key: string; status: fetchStatus }>,
    ) {
      state[action.payload.key] = action.payload.status;
    },
  },
});

export const { actions: userProfileActions } = slice;

export const useUserProfileSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: userProfileSaga });
  return { actions: slice.actions };
};
