import React, { useMemo } from 'react';
import { Container, Typography } from '@mui/material';
import PageHeader from '../../components/PageHeader';
import { wikiItems } from './wiki';
import { WikiList } from '../../components/Wiki';
import { useStore } from '../../hooks/useStore';

const Wiki = () => {
  const user = useStore(s => s.user);

  const filteredWikiLinks = useMemo(
    () =>
      wikiItems.filter(wikiItem => {
        wikiItem.items = wikiItem.items.filter(item => {
          item.links = item.links.filter(link =>
            link.states.some(
              state => state === user?.licenseState || state === 'ALL',
            ),
          );
          return item.states.some(
            state => state === user?.licenseState || state === 'ALL',
          );
        });
        return wikiItem.items.length > 0;
      }),
    [user?.licenseState],
  );

  return (
    <Container maxWidth="xl">
      <PageHeader
        title="Wiki - Useful Links"
        subtitle={
          <Typography variant={'body1'}>
            Helpful Links for Pinnacle Realty Advisors agents.
          </Typography>
        }
        withDivider
      />
      <WikiList wikiItems={filteredWikiLinks} />
    </Container>
  );
};

export default Wiki;
