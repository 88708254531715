import React from 'react';
import { GlobalStyles as MUIGlobalStyles } from '@mui/material';

const GlobalStyles = () => {
  return (
    <MUIGlobalStyles
      styles={{
        html: {
          WebkitFontSmoothing: 'auto',
        },
        body: {
          backgroundColor: '#FAFAF8 !important',
          fontFamily: 'Inter, sans-serif !important',
        },
      }}
    />
  );
};

export default GlobalStyles;
