import api from '../api';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import * as Types from './types';

type UserDataAxiosResponse = Array<Types.IUser>;
type UserDataResponse = Array<Types.IUser>;

// TODO: Fix naming
export const fetchUserData = async () => {
  const result = await api.get<UserDataAxiosResponse>('auth/me');
  return result.data;
};

interface GetUserData<T> {
  options?: UseQueryOptions<UserDataResponse, AxiosError, T, any>;
}

export function useGetUserData<T = UserDataResponse>(props?: GetUserData<T>) {
  return useQuery(['user'], fetchUserData, props?.options);
}

type LoginParams = { email: string; password: string };
interface useLoginProps {
  options?: UseMutationOptions<
    Array<Types.IUser>,
    AxiosError,
    LoginParams,
    any
  >;
}

export function useLogin(props?: useLoginProps) {
  return useMutation(async (params: LoginParams) => {
    const result = await api.post<UserDataAxiosResponse>('auth/login', params);
    return result.data;
  }, props?.options);
}

interface UseLogoutProps {
  options?: UseMutationOptions<void, AxiosError>;
}

export function useLogout(props?: UseLogoutProps) {
  return useMutation<void, AxiosError, void>(async () => {
    await api.get('auth/logout');
  }, props?.options);
}

type initResetPasswordParams = { email: string };
interface useInitPasswordResetProps {
  options?: UseMutationOptions<
    Array<Types.IUser>,
    AxiosError,
    Types.IInitiatePassResetData,
    any
  >;
}

export function useInitiatePasswordReset(props?: useInitPasswordResetProps) {
  return useMutation(async (params: initResetPasswordParams) => {
    const result = await api.post('auth/initiate-password-change', params);
    return result.data;
  }, props?.options);
}

interface usePasswordResetProps {
  options?: UseMutationOptions<AxiosError, Types.IResetPassData, any>;
}

export function useResetPassword(props?: usePasswordResetProps) {
  return useMutation(async (params: Types.IResetPassData) => {
    const result = await api.post('auth/change-password', params);
    return result.data;
  }, props?.options);
}

// TODO: Not used
interface useChangePasswordProps {
  options?: UseMutationOptions<AxiosError, Types.IChangePassData, any>;
}

export function useChangePassword(props?: useChangePasswordProps) {
  return useMutation(async (params: Types.IChangePassData) => {
    const result = await api.post('profile/updatePassword', params);
    return result.data;
  }, props?.options);
}
