import React, { useEffect, useMemo, useState } from 'react';
import {
  Form,
  Input,
  Upload,
  Skeleton,
  InputNumber,
  Checkbox,
  Select,
} from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import {
  BoostForm,
  IBoostEditParams,
  IBoostData,
  QuillEditor,
} from '../../slice/types';
import { FormCard, Header } from '../../styles';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getFileNameFromUrl } from 'utils/helpers';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Typography from 'app/components/Typography';
import ScrollToTopOnMount from 'app/components/ScrollToTopOnMount';
import { Button, Link } from 'app/components/Clicks';
import { svgIcons } from 'utils/svgIcons';
import RouteConstants from 'app/routes';
import { useAdminSlice } from '../../slice';
import {
  selectBoost,
  selectIsSaving,
  selectIsLoading,
  selectIsCategoriesLoading,
  selectBoostsCategories,
} from '../../slice/selectors';
import { isImageSizeValid } from 'utils/helpers';
import AssetsGalleryAdmin from '../../../../components/AssetsGallery/admin';
import OnlineToolPriceAdmin from '../../../../components/OnlineToolPriceCard/admin';

const FORM_ID = 'boost-form';
const { Option } = Select;

const BoostAddEdit = () => {
  const [form] = Form.useForm();
  const { boostId } = useParams<IBoostEditParams>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const boost = useSelector(selectBoost);
  const isLoading = useSelector(selectIsLoading);
  const isSaving = useSelector(selectIsSaving);
  const dispatch = useDispatch();
  const { actions } = useAdminSlice();
  const [logoList, setLogoList] = useState<Array<UploadFile<any>>>([]);
  const [coverList, setCoverList] = useState<Array<UploadFile<any>>>([]);
  const [featuredImageDesktopList, setFeaturedImageDesktopList] = useState<
    Array<UploadFile<any>>
  >([]);
  const [featuredImageMobileList, setFeaturedImageMobileList] = useState<
    Array<UploadFile<any>>
  >([]);
  const [description, setDescription] = useState('');
  const [partnerDescription, setPartnerDescription] = useState('');
  const history = useHistory();
  const isCategoriesLoading = useSelector(selectIsCategoriesLoading);
  const categories = useSelector(selectBoostsCategories);

  useEffect(() => {
    if (boostId) {
      setIsEdit(true);
      dispatch(actions.getBoost(Number(boostId)));
    } else {
      setIsEdit(false);
    }
  }, [boostId, dispatch, actions, history]);

  useEffect(() => {
    dispatch(actions.getBoostsCategories());
  }, [actions, dispatch]);

  useEffect(() => {
    if (isEdit && boost) {
      let logoObj: UploadFile<any> | null = null;
      let coverObj: UploadFile<any> | null = null;
      let featuredImageDesktopObj: UploadFile<any> | null = null;
      let featuredImageMobileObj: UploadFile<any> | null = null;

      if (boost.logoUrl) {
        logoObj = {
          uid: '0',
          name: getFileNameFromUrl(boost.logoUrl),
          status: 'done',
          url: boost.logoUrl,
        };
        setLogoList([logoObj]);
        form.setFieldsValue({
          [BoostForm.LogoObj]: [logoObj],
        });
      }

      if (boost.coverUrl) {
        coverObj = {
          uid: '0',
          name: getFileNameFromUrl(boost.coverUrl),
          status: 'done',
          url: boost.coverUrl,
        };
        setCoverList([coverObj]);
        form.setFieldsValue({
          [BoostForm.CoverObj]: [coverObj],
        });
      }

      if (boost.featuredImageDesktopUrl) {
        featuredImageDesktopObj = {
          uid: '0',
          name: getFileNameFromUrl(boost.featuredImageDesktopUrl),
          status: 'done',
          url: boost.featuredImageDesktopUrl,
        };
        setFeaturedImageDesktopList([featuredImageDesktopObj]);
        form.setFieldsValue({
          [BoostForm.FeaturedImageDesktop]: [featuredImageDesktopObj],
        });
      }

      if (boost.featuredImageMobileUrl) {
        featuredImageMobileObj = {
          uid: '0',
          name: getFileNameFromUrl(boost.featuredImageMobileUrl),
          status: 'done',
          url: boost.featuredImageMobileUrl,
        };
        setFeaturedImageMobileList([featuredImageMobileObj]);
        form.setFieldsValue({
          [BoostForm.FeaturedImageMobile]: [featuredImageMobileObj],
        });
      }

      setDescription(boost.description || '');
      setPartnerDescription(boost.partnerDescription || '');

      form.setFieldsValue({
        [BoostForm.CompanyName]: boost.companyName,
        [BoostForm.Title]: boost.title,
        [BoostForm.ShortDescription]: boost.shortDescription,
        [BoostForm.Rank]: boost.rank,
        [BoostForm.Top]: boost.top,
        [BoostForm.Active]: boost.active,
        [BoostForm.Commercial]: boost.commercial,
        [BoostForm.Residential]: boost.residential,
        [BoostForm.Category]: boost.category?.id,
      });
    }
  }, [isEdit, boost, form]);

  const catOptions = useMemo(() => {
    const options: React.ReactNode[] = [];
    categories?.forEach(cat => {
      options.push(
        <Option value={cat.id} key={cat.slug}>
          {cat.name}
        </Option>,
      );
      if (cat.children)
        cat.children.forEach(child => {
          options.push(
            <Option
              value={child.id}
              key={child.slug}
              className="realthy-child-opt"
            >
              {child.name}
            </Option>,
          );
        });
    });
    return options;
  }, [categories]);

  const onFormSubmit = ({
    companyName,
    title,
    shortDescription,
    logoObj,
    coverObj,
    featuredImageDesktopObj,
    featuredImageMobileObj,
    rank,
    top,
    active,
    category,
    commercial,
    residential,
  }) => {
    const data: IBoostData = {
      companyName: companyName,
      title: title,
      shortDescription: shortDescription,
      description: description === QuillEditor.EmptyHtml ? '' : description,
      partnerDescription:
        partnerDescription === QuillEditor.EmptyHtml ? '' : partnerDescription,
      rank: rank,
      top: top,
      active: active,
      logoUrl: isEdit ? boost?.logoUrl || '' : '',
      coverUrl: isEdit ? boost?.coverUrl || '' : '',
      featuredImageDesktopUrl: isEdit
        ? boost?.featuredImageDesktopUrl || null
        : null,
      featuredImageMobileUrl: isEdit
        ? boost?.featuredImageMobileUrl || null
        : null,
      category: category,
      commercial: commercial,
      residential: residential,
    };

    if (
      isEdit &&
      boostId &&
      data.companyName &&
      data.title &&
      data.shortDescription &&
      (data.logoUrl || logoObj[0]?.originFileObj)
    ) {
      dispatch(
        actions.saveBoost({
          boostId: Number(boostId),
          data,
          logoFile: logoObj[0].originFileObj,
          coverFile: coverObj ? coverObj[0].originFileObj : null,
          featuredImageDesktopFile: featuredImageDesktopObj
            ? featuredImageDesktopObj[0].originFileObj
            : null,
          featuredImageMobileFile: featuredImageMobileObj
            ? featuredImageMobileObj[0].originFileObj
            : null,
        }),
      );
      return;
    }

    if (
      data.companyName &&
      data.title &&
      data.shortDescription &&
      logoObj[0]?.originFileObj
    ) {
      dispatch(
        actions.createBoost({
          data,
          logoFile: logoObj ? logoObj[0].originFileObj : null,
        }),
      );
    }
  };

  const getLogoUploadValue = (e: any) => {
    if (Array.isArray(e)) {
      setLogoList(e);
      return e;
    }
    setLogoList(e.fileList);
    return e && e.fileList;
  };

  const getCoverUploadValue = (e: any) => {
    if (Array.isArray(e)) {
      setCoverList(e);
      return e;
    }
    setCoverList(e.fileList);
    return e && e.fileList;
  };

  const getFeaturedImageDesktopUploadValue = (e: any) => {
    if (Array.isArray(e)) {
      setFeaturedImageDesktopList(e);
      return e;
    }
    setFeaturedImageDesktopList(e.fileList);
    return e && e.fileList;
  };

  const getFeaturedImageMobileUploadValue = (e: any) => {
    if (Array.isArray(e)) {
      setFeaturedImageMobileList(e);
      return e;
    }
    setFeaturedImageMobileList(e.fileList);
    return e && e.fileList;
  };

  return (
    <div>
      <ScrollToTopOnMount />
      <Header>
        <Typography type="h" level={3}>
          {isEdit ? 'Edit online tool' : 'Add new online tool'}
        </Typography>
        <Link
          to={RouteConstants.admin.boosts}
          common={{ variation: 'secondary', small: 'true' }}
        >
          Cancel
        </Link>
      </Header>
      {isLoading ? (
        <Skeleton active style={{ marginTop: '3.5rem' }} />
      ) : (
        <FormCard>
          <Form
            name={FORM_ID}
            form={form}
            id={FORM_ID}
            onFinish={onFormSubmit}
            layout="vertical"
          >
            <Typography
              type="h"
              level={4}
              common={{ margin: '3.5rem 0 1.5rem 0' }}
            >
              Is this online tool active?
            </Typography>

            <Form.Item name={BoostForm.Active} valuePropName="checked">
              <Checkbox>This online tool is visible for users.</Checkbox>
            </Form.Item>

            <Typography
              type="h"
              level={4}
              common={{ margin: '3.5rem 0 1.5rem 0' }}
            >
              Partner
            </Typography>
            <Form.Item
              name={BoostForm.CompanyName}
              rules={[
                {
                  required: true,
                  message: 'Please input a company name of a partner',
                },
              ]}
              label="Company name"
            >
              <Input placeholder="Company name" size="large" />
            </Form.Item>

            <Form.Item
              name={BoostForm.LogoObj}
              label="Logo"
              extra="Max size 20KB"
              valuePropName="file"
              getValueFromEvent={getLogoUploadValue}
              rules={[
                {
                  required: true,
                  message: "Please upload partner's logo",
                },
                {
                  validator: (rule, value) => {
                    if (
                      Array.isArray(value) &&
                      !isImageSizeValid(value[0]?.size, 20)
                    ) {
                      return Promise.reject('File cannot be larger than 20KB');
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Upload
                name="partner_logo"
                listType="picture"
                fileList={logoList}
                accept=".jpg,.jpeg,.png,.svg"
                maxCount={1}
                beforeUpload={() => false}
              >
                <Button
                  type="button"
                  common={{ iconside: 'left', variation: 'secondary' }}
                >
                  {svgIcons.upload()} Upload Logo
                </Button>
              </Upload>
            </Form.Item>

            <Form.Item
              name={BoostForm.FeaturedImageDesktop}
              label="Desktop featured image"
              extra="Max size 300KB. Suggested resolution: 1130x400"
              valuePropName="file"
              getValueFromEvent={getFeaturedImageDesktopUploadValue}
              rules={[
                {
                  required: false,
                  message: "Please upload partner's featured image",
                },
                {
                  validator: (rule, value) => {
                    if (
                      Array.isArray(value) &&
                      !isImageSizeValid(value[0]?.size, 300)
                    ) {
                      return Promise.reject('File cannot be larger than 300KB');
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Upload
                name="featured_image_desktop"
                listType="picture"
                fileList={featuredImageDesktopList}
                accept=".jpg,.jpeg,.png,.svg"
                maxCount={1}
                beforeUpload={() => false}
              >
                <Button
                  type="button"
                  common={{ iconside: 'left', variation: 'secondary' }}
                >
                  {svgIcons.upload()} Upload image
                </Button>
              </Upload>
            </Form.Item>

            <Form.Item
              name={BoostForm.FeaturedImageMobile}
              label="Mobile featured image"
              extra="Max size 100KB. Suggested resolution: 520x180"
              valuePropName="file"
              getValueFromEvent={getFeaturedImageMobileUploadValue}
              rules={[
                {
                  required: false,
                  message: "Please upload partner's featured image",
                },
                {
                  validator: (rule, value) => {
                    if (
                      Array.isArray(value) &&
                      !isImageSizeValid(value[0]?.size, 100)
                    ) {
                      return Promise.reject('File cannot be larger than 100KB');
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Upload
                name="featured_image_mobile"
                listType="picture"
                fileList={featuredImageMobileList}
                accept=".jpg,.jpeg,.png,.svg"
                maxCount={1}
                beforeUpload={() => false}
              >
                <Button
                  type="button"
                  common={{ iconside: 'left', variation: 'secondary' }}
                >
                  {svgIcons.upload()} Upload image
                </Button>
              </Upload>
            </Form.Item>

            <Typography
              type="h"
              level={4}
              common={{ margin: '3.5rem 0 1.5rem 0' }}
            >
              Online tool
            </Typography>
            <Form.Item
              name={BoostForm.Category}
              label="Category"
              rules={[{ required: true, message: 'Please select a category' }]}
            >
              <Select
                placeholder="Select a category"
                loading={isCategoriesLoading}
                allowClear
              >
                {catOptions}
              </Select>
            </Form.Item>
            <Form.Item
              name={BoostForm.Title}
              rules={[
                {
                  required: true,
                  message: 'Please input a online tool title',
                },
              ]}
              label="Title"
            >
              <Input placeholder="Boost title" size="large" />
            </Form.Item>

            <Form.Item
              name={BoostForm.ShortDescription}
              label="Short description"
              rules={[
                {
                  required: true,
                  message:
                    'Please input a short description for the online tool',
                },
              ]}
            >
              <Input.TextArea showCount maxLength={350} />
            </Form.Item>

            <Typography
              type="div"
              bodyVariation="bodySmall"
              common={{
                isUppercase: true,
                fontWeight: 400,
                margin: '0 0 8px 0',
              }}
            >
              Description
            </Typography>
            <ReactQuill
              value={description}
              onChange={setDescription}
              style={{ marginBottom: '24px' }}
            />

            <Typography
              type="div"
              bodyVariation="bodySmall"
              common={{
                isUppercase: true,
                fontWeight: 400,
                margin: '0 0 8px 0',
              }}
            >
              Pricing details
            </Typography>
            <OnlineToolPriceAdmin prices={boost?.pricing} onlineTool={boost} />

            <Form.Item
              name={BoostForm.CoverObj}
              label="Cover image"
              extra="Max size 100KB. Suggested resolution: 130x475"
              valuePropName="file"
              getValueFromEvent={getCoverUploadValue}
              rules={[
                {
                  validator: (rule, value) => {
                    if (
                      Array.isArray(value) &&
                      !isImageSizeValid(value[0]?.size, 100)
                    ) {
                      return Promise.reject('File cannot be larger than 100KB');
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Upload
                name="boost_cover"
                listType="picture"
                accept=".jpg,.jpeg,.png,.svg"
                fileList={coverList}
                maxCount={1}
                beforeUpload={() => false}
              >
                <Button
                  type="button"
                  common={{ iconside: 'left', variation: 'secondary' }}
                >
                  {svgIcons.upload()} Upload Cover
                </Button>
              </Upload>
            </Form.Item>

            <Form.Item
              name={BoostForm.Top}
              valuePropName="checked"
              label="Top online tool"
            >
              <Checkbox>This is a TOP online tool.</Checkbox>
            </Form.Item>

            <Form.Item
              name={BoostForm.Commercial}
              valuePropName="checked"
              label="Commercial tool"
            >
              <Checkbox>Commercial tool.</Checkbox>
            </Form.Item>

            <Form.Item
              name={BoostForm.Residential}
              valuePropName="checked"
              label="Residential tool"
            >
              <Checkbox>Residential tool.</Checkbox>
            </Form.Item>

            <Form.Item
              name={BoostForm.Rank}
              label="Position in All Online Tools list"
              extra="The LOWER is the number, the HIGHER position in the list this online tool will take. 0 is the highest."
              rules={[
                {
                  validator: (rule, value) => {
                    if (value && !/^[1-9]\d*$/.test(value)) {
                      return Promise.reject('Please enter a positive integer');
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <InputNumber min={0} placeholder="0" />
            </Form.Item>

            <Typography
              type="div"
              bodyVariation="bodySmall"
              common={{
                isUppercase: true,
                fontWeight: 400,
                margin: '0 0 8px 0',
              }}
            >
              Assets Gallery
            </Typography>
            <AssetsGalleryAdmin
              assets={boost?.assetsGallery?.images}
              tool={boost}
            />

            <Form.Item>
              <Button
                type="submit"
                loading={isSaving ? 'true' : 'false'}
                common={{ margin: '0 2rem 0 0' }}
              >
                {isEdit ? 'Save changes' : 'Add online tool'}
              </Button>
              <Link
                to={RouteConstants.admin.boosts}
                common={{ variation: 'secondary' }}
              >
                Cancel
              </Link>
            </Form.Item>
          </Form>
        </FormCard>
      )}
    </div>
  );
};

export default BoostAddEdit;
