export const Plans = [
  {
    name: 'PRA Annual',
    value: 'pra_annual',
  },
  {
    name: 'PRA Premier',
    value: 'pra_premier',
  },
  {
    name: 'PRA Premier team member',
    value: 'pra_premier_member',
  },
  {
    name: 'PRA Commercial',
    value: 'pra_commercial',
  },
  {
    name: 'PRA Commercial team member',
    value: 'pra_commercial_member',
  },
  {
    name: 'WL Pro',
    value: 'wl_pro',
  },
  {
    name: 'WL Pro  team member',
    value: 'wl_pro_member',
  },
  {
    name: 'WL LLC',
    value: 'wl_llc',
  },
  {
    name: 'WL Commercial',
    value: 'wl_commercial',
  },
  {
    name: 'WL Commercial team member',
    value: 'wl_commercial_member',
  },
];
