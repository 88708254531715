import styled, { css } from 'styled-components';
import { media } from 'styles/media';

interface IProps {
  fontWeight?: 300 | 400 | 600 | 700;
  lineHeight?: string;
  textAlign?: 'start' | 'end' | 'center' | 'justify' | 'left' | 'right';
  color?: string;
  isUnderlined?: boolean;
  isUppercase?: boolean;
  isItalic?: boolean;
  margin?: string;
  variation?: 'display' | 'bodyXL' | 'bodyLarge' | 'bodySmall' | 'table';
}

const getCommonStyles = props => {
  return `
    color: ${props?.color ? props.color : '#262626'};
    ${props?.isUnderlined ? 'text-decoration: underline;' : ''}
    ${props?.isUppercase ? 'text-transform: uppercase;' : ''}
    ${props?.isItalic ? 'font-style: italic;' : ''}
    margin: ${props?.margin ? props.margin : '0 0 0 0'};
    ${props?.textAlign ? `text-align: ${props.textAlign};` : ''}
  `;
};

const getBodyStyles = props => {
  switch (props.variation) {
    case 'bodyXL':
      return `
        font-size: 25px;
        line-height: ${props.lineHeight ? props.lineHeight : 1.262};
        font-weight: ${props.fontWeight ? props.fontWeight : 400};
      `;
    case 'bodyLarge':
      return `
        font-size: 20px;
        line-height: ${props.lineHeight ? props.lineHeight : 1.5};
        font-weight: ${props.fontWeight ? props.fontWeight : 400};
      `;
    case 'table':
      return `
        font-size: 14px;
        line-height: ${props.lineHeight ? props.lineHeight : 1.25};
        font-weight: ${props.fontWeight ? props.fontWeight : 400};
      `;
    case 'bodySmall':
      return `
        font-size: 14px;
        line-height: ${props.lineHeight ? props.lineHeight : 1.262};
        font-weight: ${props.fontWeight ? props.fontWeight : 400};
      `;
    case 'body':
    default:
      return `
        font-size: 16px;
        line-height: ${props.lineHeight ? props.lineHeight : 1.62};
        font-weight: ${props.fontWeight ? props.fontWeight : 400};
      `;
  }
};

export const H1 = styled.h1<IProps>`
  ${props => getCommonStyles(props)}
  line-height: ${props => (props.lineHeight ? props.lineHeight : 1.262)};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 300)};

  ${props =>
    props.variation === 'display'
      ? css`
          font-size: 6.1rem;

          ${media.phoneLand`
            font-size: 5.25rem; //42px
          `}
        `
      : css`
          font-size: 4.9rem;

          ${media.phoneLand`
            font-size: 4.25rem; //34px
            line-height: 1.5;
          `}
        `};
`;

export const H2 = styled.h2<IProps>`
  ${props => getCommonStyles(props)}
  line-height: ${props => (props.lineHeight ? props.lineHeight : 1.262)};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 600)};
  font-size: 3.9rem;

  ${media.phoneLand`
    font-size: 3.875rem; //31px
  `}
`;

export const H3 = styled.h3<IProps>`
  ${props => getCommonStyles(props)}
  line-height: ${props => (props.lineHeight ? props.lineHeight : 1.262)};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 600)};
  font-size: 31px;

  ${media.phoneLand`
    font-size: 27px;
  `}
`;

export const H4 = styled.h4<IProps>`
  ${props => getCommonStyles(props)}
  line-height: ${props => (props.lineHeight ? props.lineHeight : 1.262)};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 600)};
  font-size: 25px;
`;

export const H5 = styled.h5<IProps>`
  ${props => getCommonStyles(props)}
  line-height: ${props => (props.lineHeight ? props.lineHeight : 1.262)};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 600)};
  font-size: 20px;
`;

export const P = styled.p<IProps>`
  ${props => getCommonStyles(props)}
  ${props => getBodyStyles(props)}
`;

export const Span = styled.span<IProps>`
  ${props => getCommonStyles(props)}
  ${props => getBodyStyles(props)}
`;

export const Div = styled.div<IProps>`
  ${props => getCommonStyles(props)}
  ${props => getBodyStyles(props)}
`;
