import React, { useEffect, useMemo, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { Box, Button, IconButton, styled, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';
import { useInView } from 'react-intersection-observer';
import { IGeo } from '../../containers/Deals/slice/types';
import { ICategory } from '../../containers/Boosts/slice/types';

export enum QueryParams {
  Page = 'page',
  Location = 'state',
  SubLocation = 'city',
  Category = 'category',
  SubCategory = 'sub-category',
}

interface IFilterDrawer {
  rootUrl: string;
  drawerOpen: boolean;
  handleDrawerClose: () => void;
  locations: Array<IGeo> | null;
  categories: Array<ICategory> | null;
}

interface ITTreeViewControl {
  expanded: string[];
  selected: string[];
}

const STATES_TO_SHOW = 5;

const StyledTreeView = styled(props => <TreeView {...props} />)(
  ({ theme }) => ({
    overflowY: 'auto',
    maxHeight: 340,
    '::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: 7,
    },
    ' ::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: theme.palette.primary.dark,
    },
  }),
);

const FilterName = styled(props => <Typography {...props} />)(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: 600,
  color: theme.palette.common.black,
  paddingBottom: 20,
}));

const FilterDrawer = ({
  rootUrl,
  drawerOpen,
  handleDrawerClose,
  locations,
  categories,
}: IFilterDrawer) => {
  const history = useHistory();
  const query = useQuery();
  const [showAllStates, setShowAllStates] = useState(false);
  const [isClearDisabled, setIsClearDisabled] = useState(true);
  const [activeCategory, setActiveCategory] = useState<ITTreeViewControl>({
    expanded: [],
    selected: [],
  });
  const [activeLocation, setActiveLocation] = useState<ITTreeViewControl>({
    expanded: [],
    selected: [],
  });
  const [selectedCategory, setSelectedCategory] = useState('');
  const { ref: myRef, inView: myElementIsVisible } = useInView();

  // splitting words that are longer than 20 words in to separate strings
  // TODO: simplify
  const formatName = (name: string) => {
    const formatted =
      name.length > 20 && name.match(/.{1,20}([A-Z][a-z]+|[0-9]+)/g);
    return formatted ? formatted.join(' ') : name;
  };

  useEffect(() => {
    const selectedElements = document.querySelectorAll('[aria-selected=true]');
    if (myElementIsVisible && selectedElements) {
      selectedElements.forEach(el => {
        el.scrollIntoView({ block: 'center' });
      });
    }
  }, [myElementIsVisible]);

  useEffect(() => {
    if (
      query.get(QueryParams.Location) ||
      query.get(QueryParams.SubLocation) ||
      query.get(QueryParams.Category) ||
      query.get(QueryParams.SubCategory)
    ) {
      setIsClearDisabled(false);
    } else {
      setIsClearDisabled(true);
    }
  }, [query]);

  useEffect(() => {
    const selectedCat = categories?.find(
      cat => cat.slug === query.get(QueryParams.Category),
    )?.slug;
    if (selectedCat) {
      setSelectedCategory(selectedCat);
    }
  }, [categories, query]);

  useEffect(() => {
    const selectedCatChild = query.get(QueryParams.SubCategory);
    setActiveCategory({
      expanded: selectedCategory ? [...[], selectedCategory] : [],
      selected: [
        ...[],
        selectedCatChild
          ? selectedCatChild
          : selectedCategory
          ? selectedCategory
          : '',
      ],
    });
  }, [categories, query, selectedCategory]);

  useEffect(() => {
    const selectedLoc = locations?.find(
      loc => loc.code === query.get(QueryParams.Location),
    )?.code;
    const selectedCity = query.get(QueryParams.SubLocation);
    setActiveLocation({
      expanded: selectedLoc ? [...[], selectedLoc] : [],
      selected: [
        ...[],
        selectedCity ? selectedCity : selectedLoc ? selectedLoc : '',
      ],
    });
  }, [locations, query]);

  useEffect(() => {
    const selectedLocIndex = locations?.findIndex(
      loc => loc.code === query.get(QueryParams.Location),
    );
    if (selectedLocIndex && selectedLocIndex > STATES_TO_SHOW) {
      setShowAllStates(true);
    }
  }, [locations, query]);

  const TreeItemLabel = (name: string, count?: number) => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography>{name}</Typography>
        <Typography>({count})</Typography>
      </Box>
    );
  };

  const locationOptions = useMemo(() => {
    const mappingArr = showAllStates
      ? locations
      : locations?.slice(0, STATES_TO_SHOW);

    return (
      <>
        {mappingArr?.map(loc => (
          <TreeItem
            sx={{ marginRight: '5px' }}
            key={`loc-group-${loc.code}`}
            nodeId={loc.code}
            label={TreeItemLabel(loc.name, loc.count)}
            onClick={() => {
              query.set(QueryParams.Location, loc.code);
              query.delete(QueryParams.SubLocation);
              history.push(`${rootUrl}?${query.toString()}`);
            }}
          >
            {loc?.cities?.map(city => (
              <TreeItem
                key={city.code}
                nodeId={city.code}
                label={TreeItemLabel(city.name, city.count)}
                onClick={() => {
                  query.set(QueryParams.SubLocation, city.code);
                  history.push(`${rootUrl}?${query.toString()}`);
                }}
              />
            ))}
          </TreeItem>
        ))}
      </>
    );
  }, [showAllStates, locations, query, history, rootUrl]);

  const categoryOptions = useMemo(() => {
    return (
      <>
        {categories?.map(category => (
          <TreeItem
            sx={{ marginRight: '5px' }}
            key={`category-group-${category.id}`}
            nodeId={category.slug!}
            label={TreeItemLabel(formatName(category.name!), category.count)}
            onClick={() => {
              if (category.slug) {
                query.set(QueryParams.Category, category.slug);
                query.delete(QueryParams.SubCategory);
              }
              history.push(`${rootUrl}?${query.toString()}`);
            }}
          >
            {category.children?.map(children => (
              <TreeItem
                key={children.id}
                nodeId={children.slug!}
                onClick={() => {
                  if (children.slug)
                    query.set(QueryParams.SubCategory, children.slug);
                  history.push(`${rootUrl}?${query.toString()}`);
                }}
                label={TreeItemLabel(
                  formatName(children.name!),
                  children.count,
                )}
              />
            ))}
          </TreeItem>
        ))}
      </>
    );
  }, [categories, history, query, rootUrl]);

  return (
    <Drawer
      anchor={'right'}
      open={drawerOpen}
      onClose={handleDrawerClose}
      PaperProps={{
        sx: {
          width: 320,
        },
      }}
    >
      <Box sx={{ px: 3 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 4,
          }}
        >
          <Typography sx={{ textTransform: 'uppercase', fontWeight: 600 }}>
            Filter
          </Typography>
          <IconButton onClick={handleDrawerClose} edge={'end'}>
            <CloseIcon />
          </IconButton>
        </Box>
        <FilterName>Location</FilterName>
        <StyledTreeView
          id={'location-container'}
          aria-label="vendor filter options"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={activeLocation.expanded}
          selected={activeLocation.selected}
        >
          {locationOptions}
        </StyledTreeView>
        {!showAllStates && (
          <Button
            sx={{ mt: 2 }}
            size="small"
            onClick={() => setShowAllStates(true)}
          >
            Show all states
          </Button>
        )}
        <Box sx={{ pt: 4 }} ref={myRef}>
          <FilterName>Category</FilterName>
          <StyledTreeView
            aria-label="vendor filter options"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={activeCategory.expanded}
            selected={activeCategory.selected}
          >
            {categoryOptions}
          </StyledTreeView>
        </Box>
        <Box sx={{ display: 'flex', py: 4 }}>
          <Button
            variant="outlined"
            fullWidth
            sx={{ mr: 1 }}
            disabled={isClearDisabled}
            onClick={() => history.push(rootUrl)}
          >
            Clear All
          </Button>
          <Button variant="contained" fullWidth onClick={handleDrawerClose}>
            Done
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default FilterDrawer;
