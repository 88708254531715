import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  Paper,
  alpha,
  Modal,
} from '@mui/material';
import { IBoostObject } from '../../containers/Boosts/slice/types';
import AddCircleIconOutlined from '@mui/icons-material/AddCircleOutlined';
import { styled } from '@mui/system';
import api from '../../queries/api';
import { useDispatch } from 'react-redux';
import { useAdminSlice } from '../../containers/Admin/slice';
import { IServiceInnerPricing } from '../../queries/services/types';
import OnlineToolPriceCard from './index';
import { useTheme } from '@mui/material/styles';
import PriceEditForm from './priceEditForm';

const ImageWrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 24px;
  justify-content: space-between;
  align-items: start;
`;

const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backdropFilter: 'blur(2px)',
  display: 'none',
  zIndex: 1,
});

const ButtonWrapper = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 2,
});

const ButtonsContainer = styled('div')({
  display: 'none',
  flexDirection: 'row',
  gap: '10px',
  zIndex: 1,
  cursor: 'pointer',
});

const PriceContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '100%',
  '&:hover .overlay': {
    display: 'flex',
  },
  '&:hover .buttonsContainer': {
    display: 'flex',
  },
}));

interface IProps {
  prices?: IServiceInnerPricing[];
  onlineTool: IBoostObject | null;
}

const OnlineToolPriceAdmin = ({ prices, onlineTool }: IProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { actions } = useAdminSlice();
  const [isHovered, setHovered] = useState(false);
  const [open, setOpen] = useState(false);
  const [editingPrice, setEditingPrice] = useState<IServiceInnerPricing | null>(
    null,
  );

  const handleOpen = (price: IServiceInnerPricing | null = null) => {
    setEditingPrice(price); // set the price object that's being edited
    setOpen(true); // open the modal
  };

  const handleClose = () => {
    setEditingPrice(null);
    setOpen(false);
  };

  return (
    <>
      <Paper sx={{ mt: 1, mb: 1 }}>
        <Box sx={{ p: 1 }}>
          <ImageWrapper>
            {prices?.map((pricingData, index) => (
              <PriceContainer
                key={index}
                sx={{ p: 1 }}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                <OnlineToolPriceCard key={index} price={pricingData} />
                <Overlay className="overlay" />
                <ButtonWrapper>
                  <ButtonsContainer
                    className="buttonsContainer"
                    isHovered={isHovered}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleOpen(pricingData)}
                    >
                      Change
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        api
                          .delete<IBoostObject>(
                            `admin/online_tools/${onlineTool?.id}/pricing/${pricingData.id}`,
                          )
                          .then(deleteResponse => {
                            dispatch(actions.setBoost(deleteResponse.data));
                          });
                      }}
                    >
                      DELETE
                    </Button>
                  </ButtonsContainer>
                </ButtonWrapper>
              </PriceContainer>
            ))}
            <Card>
              <CardActionArea onClick={() => handleOpen()}>
                <CardMedia>
                  <AddCircleIconOutlined
                    sx={{
                      width: '100%',
                      height: '300px',
                      padding: '100px',
                      color: alpha(theme.palette.secondary.main, 0.5),
                    }}
                  />
                </CardMedia>
              </CardActionArea>
            </Card>
          </ImageWrapper>
        </Box>
      </Paper>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 700,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2 id="simple-modal-title">{editingPrice ? 'Edit' : 'Add'} Price</h2>
          <PriceEditForm
            defaultValues={editingPrice || {}}
            onSubmit={data => {
              const url = editingPrice
                ? `admin/online_tools/${onlineTool?.id}/editPricing/${editingPrice.id}`
                : `admin/online_tools/${onlineTool?.id}/addPricing`;
              api.post<IBoostObject>(url, data).then(updateResponse => {
                dispatch(actions.setBoost(updateResponse.data));
                setOpen(false);
              });
            }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default OnlineToolPriceAdmin;
