import ListItemText from '@mui/material/ListItemText';
import React, { ReactNode } from 'react';
import { ISideBarItems } from './index';
import { alpha, ListItemIcon, styled, useTheme } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import { NavLink } from 'react-router-dom';
import { TransitionProps } from '@mui/material/transitions';

interface ISidebarItem extends TransitionProps {
  item?: ISideBarItems;
  children?: ReactNode;
  active?: (path: string, exact?: boolean) => boolean;
  onClick: () => void;
}

const StyledListItem = styled(props => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.grey[100_8],
  '&:hover': {
    color: theme.palette.common.white,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const SidebarItem = ({ item, active, onClick, children }: ISidebarItem) => {
  const theme = useTheme();

  const isItemActive =
    item?.path && active ? active(item.path, item.exact) : false;

  const activeRootStyle = {
    color: theme.palette.common.white,
    bgcolor: alpha(theme.palette.primary.light, 0.2),
    borderRight: `4px solid ${theme.palette.secondary.light}`,
  };

  const secondaryRootStyle = {
    pl: 2,
  };

  return (
    <StyledListItem
      onClick={onClick}
      key={item?.title}
      component={item?.path && NavLink}
      to={item?.path}
      sx={{
        ...{
          ...(isItemActive && activeRootStyle),
          ...(item?.secondary && secondaryRootStyle),
        },
      }}
    >
      {item?.icon && item?.title && (
        <>
          <ListItemIconStyle>{item.icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={item.title} />
        </>
      )}
      {children && children}
    </StyledListItem>
  );
};

export default SidebarItem;
