import { request } from 'utils/request';

const globalApi = {
  getCoverImageList: () =>
    request({
      url: '/hq/user/inner-page/cover-images',
    }),
};

export default globalApi;
