import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Slide,
  Stack,
  Typography,
  Link,
  Divider,
} from '@mui/material';
import { Link as RouterLink, NavLink } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RouteConstants from '../../../../routes';
import { useInitiatePasswordReset } from '../../../../queries/auth/useAuthQueries';
import { InputField } from '../../../../components/FormInputs';

const ResetPassword = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { mutate: initiatePasswordReset, isLoading } = useInitiatePasswordReset(
    {
      options: {
        onSuccess: () => {
          setFormSubmitted(true);
        },
      },
    },
  );

  const form = useForm<{ email: string }>({
    defaultValues: {
      email: '',
    },
  });

  const { handleSubmit, control, getValues } = form;

  const handleResetPassSubmit = ({ email }) => {
    initiatePasswordReset({ email });
  };

  return (
    <Container maxWidth="sm" sx={{ height: '100%', py: 3 }}>
      <Stack height={'100%'} justifyContent={'center'} py={2}>
        {formSubmitted ? (
          <Slide
            direction="up"
            in={formSubmitted}
            mountOnEnter
            unmountOnExit
            timeout={500}
          >
            <Stack spacing={2}>
              <Typography align={'center'} variant={'h5'} mb={1}>
                Password Recovery
              </Typography>
              <Typography>
                If we found a user with email address{' '}
                <strong>{getValues('email')}</strong> in the account, you will
                receive an receive an email from us shortly.
              </Typography>
              <Typography>
                If you do not receive the email within a few minutes, please
                check your junk/spam email folder.
              </Typography>
              <Typography>
                Unsure which email you used for your hq.pinnaclera.com account,
                or not sure what account you are associated with? Please email{' '}
                <Link href={'mailto:success@pinnaclera.com'}>
                  success@pinnaclera.com
                </Link>
                .
              </Typography>
              <Divider />
              <Typography align={'center'}>
                Remember the password? -{' '}
                <Link component={NavLink} to={RouteConstants.auth.login}>
                  Go Back to Login
                </Link>
              </Typography>
            </Stack>
          </Slide>
        ) : (
          <>
            <Box pb={{ xs: 3, sm: 5, md: 8 }}>
              <Button
                size="medium"
                startIcon={<ArrowBackIcon />}
                component={RouterLink}
                to={RouteConstants.auth.index}
                sx={{ pl: 0 }}
              >
                Back to login
              </Button>
              <Typography variant={'h5'}>
                Reset your password to log in to PinnacleHQ
              </Typography>
            </Box>
            <FormProvider {...form}>
              <form onSubmit={handleSubmit(handleResetPassSubmit)}>
                <Stack spacing={2} alignItems={'flex-end'} width={'100%'}>
                  <InputField
                    control={control}
                    name="email"
                    label="Email address"
                    rules={{ required: 'Please input your email address' }}
                  />
                </Stack>
                <LoadingButton
                  variant={'contained'}
                  type="submit"
                  sx={{ mt: 3 }}
                  fullWidth
                  loading={isLoading}
                >
                  Reset password
                </LoadingButton>
              </form>
            </FormProvider>
          </>
        )}
      </Stack>
    </Container>
  );
};

export default ResetPassword;
