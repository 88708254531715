import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  Paper,
  CircularProgress,
} from '@mui/material';
import { ImageWrapper } from '../OnlineToolPriceCard/styles';
import { IAsset, IBoostObject } from '../../containers/Boosts/slice/types';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { styled } from '@mui/system';
import { useMutation } from '@tanstack/react-query';
import api from '../../queries/api';
import { useDispatch } from 'react-redux';
import { useAdminSlice } from '../../containers/Admin/slice';

interface IAssetsGalleryProps {
  assets: IAsset[] | undefined;
  tool: IBoostObject | null;
}

const ImageContainer = styled(Paper)(({ theme }) => ({
  position: 'relative',
  height: '200px',
  '&:hover img': {
    filter: 'blur(2px)',
  },
  '&:hover div': {
    display: 'flex',
  },
}));

const StyledImage = styled('img')({
  width: '100%',
  maxHeight: '200px',
  objectFit: 'contain',
});

const ButtonsContainer = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'none',
  flexDirection: 'row',
  gap: '10px',
  zIndex: 1,
});

const LoadingContainer = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  zIndex: 1,
});

interface IImageUploadResponse {
  id: number;
  imageUrl: string;
}

const AssetsGalleryAdmin = ({ assets, tool }: IAssetsGalleryProps) => {
  const [currentAssetId, setCurrentAssetId] = useState<number | null>(null);
  const [loadingAssetId, setLoadingAssetId] = useState<number | null>(null);
  const [loadingNewImage, setLoadingNewImage] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const { actions } = useAdminSlice();

  const uploadImageMutation = useMutation(
    (file: File) => {
      const formData = new FormData();
      formData.append('images', file, '[PROXY]');
      return api.post<IImageUploadResponse>(`admin/images/upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
    {
      onMutate: () => {
        if (currentAssetId === null) {
          setLoadingNewImage(true);
        } else {
          setLoadingAssetId(currentAssetId);
        }
      },
      onSuccess: data => {
        api
          .post<IBoostObject>(
            `admin/assets_gallery/add_image/${tool?.id}/${data.data[0].id}${
              currentAssetId ? `/${currentAssetId}` : ''
            }`,
          )
          .then(updateResponse => {
            dispatch(actions.setBoost(updateResponse.data));
            setLoadingAssetId(null);
            setLoadingNewImage(false);
          });
      },
    },
  );

  const handleFileChange = event => {
    uploadImageMutation.mutate(event.target.files[0]);

    if (event.target) {
      event.target.value = '';
    }
  };

  const handleChangeClick = (id: number | null = null) => {
    setCurrentAssetId(id);
    fileInputRef.current?.click();
  };
  const handleDeleteImage = (id: number | null = null) => {
    setLoadingAssetId(id);

    api
      .delete<IBoostObject>(
        `admin/assets_gallery/delete_image/${tool?.id}/${id}`,
      )
      .then(deleteResponse => {
        dispatch(actions.setBoost(deleteResponse.data));
        setLoadingAssetId(null);
      });
  };

  return (
    <Paper sx={{ mt: 1, mb: 1 }}>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <Box sx={{ p: 1 }}>
        <ImageWrapper>
          {assets &&
            assets.length > 0 &&
            assets.map((item, index) => (
              <Card key={item.id}>
                <CardMedia>
                  <ImageContainer key={item.imageUrl}>
                    {loadingAssetId === item.id && (
                      <LoadingContainer>
                        <CircularProgress />
                      </LoadingContainer>
                    )}
                    <ButtonsContainer>
                      <Button
                        variant="contained"
                        onClick={() => handleChangeClick(item.id)}
                      >
                        Change
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleDeleteImage(item.id)}
                      >
                        DELETE
                      </Button>
                    </ButtonsContainer>
                    <StyledImage src={item.imageUrl} alt="image" />
                  </ImageContainer>
                </CardMedia>
              </Card>
            ))}
          <Card>
            <CardActionArea onClick={() => handleChangeClick()}>
              <CardMedia>
                {loadingNewImage && (
                  <LoadingContainer>
                    <CircularProgress />
                  </LoadingContainer>
                )}
                <AddPhotoAlternateOutlinedIcon
                  sx={{ width: '100%', height: '200px', padding: '60px' }}
                  color={'secondary'}
                />
              </CardMedia>
            </CardActionArea>
          </Card>
        </ImageWrapper>
      </Box>
    </Paper>
  );
};

export default AssetsGalleryAdmin;
