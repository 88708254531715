import { alpha } from '@mui/material';

export default function TableRow(theme) {
  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover:hover': {
            cursor: 'pointer',
            backgroundColor: alpha('#1976D2', 0.08),
          },
        },
      },
    },
  };
}
