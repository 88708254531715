import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PersonIcon from '@mui/icons-material/Person';
import PortraitIcon from '@mui/icons-material/Portrait';
import GroupsIcon from '@mui/icons-material/Groups';
import UserProfile from './tabs/userProfile';
import { Container, Grid } from '@mui/material';
import PageHeader from '../../components/PageHeader';
import PublicProfile from './tabs/publicProfile';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUserProfileSlice } from './slice';
import { selectUserDataFetchStatus, selectUserInfo } from './slice/selectors';
import YourTeam from './tabs/yourTeam';
import RouteConstants from '../../routes';
import { useHistory } from 'react-router-dom';
import Documents from './tabs/documents';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';

const TabContainer = props => (
  <TabPanel value={props.value}>
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      rowSpacing={3}
      columnSpacing={{ xs: 2, md: 3 }}
      {...props}
    />
  </TabPanel>
);

interface UserProfileProps {
  tab?: string;
  userData?: any;
  userDataFetchStatus?: any;
  filter?: object;
}

const UserSettings: React.FC<UserProfileProps> = ({ tab }) => {
  const [value, setValue] = useState(tab ?? RouteConstants.profile.index);
  const dispatch = useDispatch();
  const { actions } = useUserProfileSlice();
  const userData = useSelector(selectUserInfo);
  const userDataFetchStatus = useSelector(selectUserDataFetchStatus);
  const history = useHistory();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    history.push(newValue);
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(actions.getUserInfo());
  }, [actions, dispatch]);

  return (
    <Container maxWidth="xl" sx={{ pb: 6 }}>
      <PageHeader title={'Profile'} />
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mb={2}>
            <TabList
              onChange={(event, value) => handleChange(event, value)}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <Tab
                icon={<PersonIcon />}
                label="Account"
                iconPosition="start"
                value={RouteConstants.profile.index}
              />
              <Tab
                icon={<PortraitIcon />}
                iconPosition="start"
                label="My website information"
                value={RouteConstants.profile.public}
              />
              <Tab
                icon={<FolderOpenOutlinedIcon />}
                iconPosition="start"
                label="My Pinnacle documents"
                value={RouteConstants.profile.documents}
              />
              <Tab
                icon={<GroupsIcon />}
                iconPosition="start"
                label="My team"
                value={RouteConstants.profile.team}
              />
            </TabList>
          </Box>
          <TabContainer value={RouteConstants.profile.index}>
            <UserProfile
              userData={userData}
              userDataFetchStatus={userDataFetchStatus}
            />
          </TabContainer>
          <TabContainer value={RouteConstants.profile.public}>
            <PublicProfile userId={userData?.id!} />
          </TabContainer>
          <TabContainer value={RouteConstants.profile.team}>
            <YourTeam />
          </TabContainer>
          <TabContainer value={RouteConstants.profile.documents}>
            <Documents />
          </TabContainer>
        </TabContext>
      </Box>
    </Container>
  );
};

export default UserSettings;
