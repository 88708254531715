import styled, { css } from 'styled-components';
import { media } from 'styles/media';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${media.phoneLand`
    flex-direction: row;
    flex-wrap: wrap;
  `}

  > * {
    ${media.phoneLand`
      margin-bottom: 5.5rem;
    `}

    &:not(:last-child) {
      margin-bottom: 2.5rem;

      ${media.phoneLand`
        margin-bottom: 2rem;
        margin-right: 2rem;
      `}
    }
  }
`;

interface LinkTextProps {
  isActive: boolean;
}

export const LinkText = styled.span<LinkTextProps>`
  color: ${props => (props.isActive ? '#006D75' : props.theme.grey.secondary)};
  font-weight: ${props => (props.isActive ? '600' : '400')};

  ${media.phoneLand`
    padding-bottom: 15px;
    border-bottom: 2px solid transparent;
  `}

  ${props =>
    props.isActive
      ? css`
          ${media.phoneLand`
            color: ${props => props.theme.green.primary};
            border-color: ${props => props.theme.green.primary};
          `}
        `
      : ''}
`;
