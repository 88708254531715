import React from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  Button,
} from '@mui/material';
import GoogleDrive from '../../../../assets/images/GoogleDrive.svg';
import NoResults from '../../../components/NoResults';
import { useStore } from '../../../hooks/useStore';

const Documents = () => {
  const user = useStore(s => s.user);
  return (
    <Grid container>
      {user?.gDriveLink ? (
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Box pb={1}>
                <img src={GoogleDrive} alt={'google drive logo'} />
                <Typography variant={'h6'} pt={1}>
                  Google Drive
                </Typography>
              </Box>
              <Typography>
                You will access all the documents signed with the brokerage by
                following the link below.
              </Typography>
            </CardContent>
            <CardActions>
              <Button component={'a'} href={user.gDriveLink} target={'_blank'}>
                Open Link
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <NoResults
            title={'Sorry! No documents.'}
            subtitle={[
              'Once signed, here will be stored signed documents between you and Pinnacle Realty advisors.',
            ]}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Documents;
