import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Drawer,
  IconButton,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import { ICoverImages } from '../../containers/Global/slice/types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const CoverImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 8px;
  max-height: 75px;
`;

const CoverImageContainer = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 8px;
  max-height: 130px;
  cursor: pointer;
`;

type Props = {
  coverImages: ICoverImages;
  userCoverImage: string;
  changeCoverImage: (imageUrl: string) => void;
  isCoverImageLoading: boolean;
};

const ProfileCoverImageSelect: React.FC<Props> = ({
  coverImages,
  userCoverImage,
  changeCoverImage,
  isCoverImageLoading,
}) => {
  const [filterDrawerOpen, setFilterDrawer] = useState(false);
  const [selectedCoverImage, setSelectedCoverImage] = useState(userCoverImage);

  const handleDrawerToggle = () => {
    setFilterDrawer(!filterDrawerOpen);
  };

  const handleCoverImageChange = () => {
    changeCoverImage(selectedCoverImage);
    handleDrawerToggle();
  };

  const cancelCoverImageSelection = () => {
    setSelectedCoverImage(userCoverImage);
    handleDrawerToggle();
  };

  useEffect(() => {
    setSelectedCoverImage(userCoverImage);
  }, [userCoverImage]);

  useEffect(() => {
    //preloading image
    coverImages.forEach(({ alt, url }) => {
      const img = new Image();
      img.src = url;
      img.alt = alt;
    });
  });

  return (
    <>
      <Card sx={{ width: '100%' }}>
        <CardContent>
          <Typography variant={'h6'} pt={1} pb={2}>
            Cover Image
          </Typography>
          <CoverImage src={selectedCoverImage} />
        </CardContent>
        <CardActions sx={{ p: 2 }}>
          <LoadingButton
            startIcon={<EditIcon />}
            variant={'outlined'}
            type="submit"
            onClick={handleDrawerToggle}
            loading={isCoverImageLoading}
          >
            Edit
          </LoadingButton>
        </CardActions>
      </Card>
      <Drawer
        anchor={'right'}
        open={filterDrawerOpen}
        onClose={cancelCoverImageSelection}
        PaperProps={{
          sx: {
            padding: 1.5,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 4,
          }}
        >
          <Typography sx={{ textTransform: 'uppercase', fontWeight: 600 }}>
            Choose cover image
          </Typography>
          <IconButton onClick={cancelCoverImageSelection} edge={'end'}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box mb={6}>
          {coverImages.map((coverImage, index) => (
            <Box key={index} sx={{ mb: 2, position: 'relative' }}>
              <CoverImageContainer
                src={coverImage.url}
                onClick={() => setSelectedCoverImage(coverImage.url)}
              />
              {selectedCoverImage === coverImage.url ? (
                <CheckCircleIcon
                  sx={{
                    position: 'absolute',
                    top: 16,
                    right: 16,
                    color: '#FFFFFF',
                  }}
                />
              ) : (
                <RadioButtonUncheckedIcon
                  sx={{
                    position: 'absolute',
                    top: 16,
                    right: 16,
                    color: '#FFFFFF',
                  }}
                />
              )}
            </Box>
          ))}
        </Box>
        <Box
          mt={2}
          position={'fixed'}
          bottom={0}
          width={'100%'}
          bgcolor={'#FFFFFF'}
          py={2}
        >
          <Button variant="contained" onClick={handleCoverImageChange}>
            Save changes
          </Button>
          <Button sx={{ mr: 1 }} onClick={cancelCoverImageSelection}>
            Cancel
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

export default ProfileCoverImageSelect;
