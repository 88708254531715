/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
// Use consistent styling
import 'sanitize.css/sanitize.css';

// Import root app
import { App } from 'app';
import Theme from './theme';
import { ThemeProvider } from 'styles/theme/ThemeProvider';
import { HelmetProvider } from 'react-helmet-async';
import { configureAppStore } from 'store/configureStore';
import reportWebVitals from 'reportWebVitals';
import { SnackBarProvider } from './app/components/CustomSnackbar';
const store = configureAppStore();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={store}>
    <Theme>
      <SnackBarProvider>
        <ThemeProvider>
          <HelmetProvider>
            {/*<React.StrictMode>*/}
            <App />
            {/*</React.StrictMode>*/}
          </HelmetProvider>
        </ThemeProvider>
      </SnackBarProvider>
    </Theme>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
