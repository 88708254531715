import React, { ReactNode } from 'react';
import { RouterLink } from './styles';
import { LinkProps } from 'react-router-dom';

interface IProps extends LinkProps {
  common?: {
    variation?: 'primary' | 'secondary' | 'text';
    color?: 'green' | 'yellow' | string;
    small?: 'true' | 'false';
    fullwidth?: 'true' | 'false';
    fullwidthmobile?: 'true' | 'false';
    margin?: string;
    iconside?: 'left' | 'right';
  };
  children: ReactNode;
}

const Link = ({ common, children, ...props }: IProps) => {
  return (
    <RouterLink {...props} {...common}>
      {children}
    </RouterLink>
  );
};

export default Link;
