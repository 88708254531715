import React from 'react';
import { useCallback } from 'react';
import { Typography } from '@mui/material';

export const usePrepareCategories = () => {
  return useCallback(categories => {
    return categories.map((cat, index) => (
      <Typography key={`${cat.name}-${index}`} sx={{ fontStyle: 'italic' }}>
        {cat.name}
        {index !== categories.length - 1 ? ', ' : ''}
      </Typography>
    ));
  }, []);
};
