import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.global || initialState;

export const selectRedirectTo = createSelector(
  [selectSlice],
  state => state.redirectTo,
);

export const selectSnackbarState = createSelector(
  [selectSlice],
  state => state.snackbar,
);

export const selectCoverImages = createSelector(
  [selectSlice],
  state => state.coverImages,
);

export const selectGlobal = createSelector([selectSlice], state => state);
