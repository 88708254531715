import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectIsLoading, selectTeam } from '../../slice/selectors';
import {
  Table,
  TeamUserListRow,
  TeamUserTableHeader,
  UserListRow,
  UserTableHeader,
} from '../../styles';
import { generatePath } from 'react-router-dom';
import RouteConstants from '../../../../routes';
import ScrollToTopOnMount from '../../../../components/ScrollToTopOnMount';
import { Empty, Skeleton } from 'antd';
import Checkbox from '@mui/material/Checkbox';
import { useGetTeam } from '../../../../queries/admin/team';

interface MembersProps {
  teamId: number;
}

const Members = ({ teamId }: MembersProps) => {
  const data = useGetTeam(teamId);
  const isLoading = useSelector(selectIsLoading);

  const tableRows = useMemo(() => {
    return data?.team?.users?.map(user => (
      <TeamUserListRow
        key={`user-${user.id}`}
        to={generatePath(RouteConstants.admin.editUser, {
          userId: user.id,
        })}
        loading={isLoading ? 'true' : 'false'}
      >
        <Typography>
          {user.name} {user.lastName}
        </Typography>
        <Typography>{user.email}</Typography>
        {user.teamLead ? <Checkbox disabled checked /> : <Checkbox disabled />}
      </TeamUserListRow>
    ));
  }, [data, isLoading]);

  return (
    <div style={{ width: '100%' }}>
      <ScrollToTopOnMount />
      <Table>
        <TeamUserTableHeader>
          <Typography variant="body2">Name</Typography>
          <Typography variant="body2">Email</Typography>
          <Typography variant="body2">Team Lead</Typography>
        </TeamUserTableHeader>
        {isLoading && (!data || data?.team?.users?.length === 0) ? (
          <Skeleton active style={{ padding: '1.6rem 2.4rem' }} />
        ) : data && data.team.users && data.team.users.length > 0 ? (
          tableRows
        ) : (
          <Empty
            description="No members found"
            style={{ padding: '1.6rem 2.4rem' }}
          />
        )}
      </Table>
    </div>
  );
};

export default Members;
