import api from '../api';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import * as Types from './types';
import Cookies from 'universal-cookie';

export type SignUpResponseError = AxiosError<{
  code: number;
  context: { passwordSet?: boolean; email?: string; stripe_error?: string };
  message: string;
}>;

interface useRegistrationProps {
  options?: UseMutationOptions<
    Types.ISignUpResponse,
    SignUpResponseError,
    Types.SignUpDataType,
    any
  >;
}

export function useRegistration(props?: useRegistrationProps) {
  return useMutation(async (params: Types.SignUpDataType) => {
    const cookies = new Cookies();

    params['utm_campaign'] = cookies.get('utm_campaign') ?? '(not set)';
    params['utm_source'] = cookies.get('utm_source') ?? '(direct)';
    params['utm_medium'] = cookies.get('utm_medium') ?? '(none)';

    const url = params.uuid ? `signup/${params.uuid}` : 'signup';
    const result = await api.post<Types.ISignUpResponse>(url, params);
    return result.data;
  }, props?.options);
}

type GetAssociationResponse = Array<Types.IAssociation>;

interface GetAssociations<T> {
  options?: UseQueryOptions<GetAssociationResponse, AxiosError, T, any>;
}

export function useGetAssociations<T = GetAssociationResponse>(
  state: string,
  props?: GetAssociations<T>,
) {
  return useQuery(
    ['associations', state],
    async () => {
      const result = await api.get<GetAssociationResponse>(
        `associations/${state}`,
      );
      return result.data;
    },
    props?.options,
  );
}

interface GetSignUpData<T> {
  options?: UseQueryOptions<Types.ISignUpResponse, SignUpResponseError, T, any>;
}

export function useGetSignUpData<T = Types.ISignUpResponse>(
  uuid: string,
  props?: GetSignUpData<T>,
) {
  return useQuery(
    ['signUp', uuid],
    async () => {
      const result = await api.get<Types.ISignUpResponse>(`signup/${uuid}`);
      return result.data;
    },
    {
      retry: (failureCount, error: any) => {
        if (error?.response?.status === 403) {
          return false;
        } else {
          return failureCount < 3;
        }
      },
      refetchOnWindowFocus: false,
      ...props?.options,
    },
  );
}

export function useGetTeamInfo<T = Types.ITeamInfo>(uuid: string | null) {
  return useQuery(['team', uuid], async () => {
    if (uuid) {
      const result = await api.get<T>(`/auth/team/${uuid}`);
      return result.data;
    }

    return null;
  });
}
