import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  FormHelperText,
  Grid,
  Stack,
  Typography,
  Link,
} from '@mui/material';
import { FormProvider } from 'antd/es/form/context';
import { Header } from '../../containers/Admin/styles';
import { InputField } from '../FormInputs';
import { LoadingButton } from '@mui/lab';
import useResponsive from '../../hooks/useResponsive';
import { useForm } from 'react-hook-form';
import { ITeam } from '../../queries/admin/types';
import DefaultProfileImg from '../../../assets/images/Avatar.png';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import nologo from '../../../assets/images/nologo.jpg';
import { useUpdateTeamInfo } from '../../queries/team/useTeamQueries';
import { useSnackBar } from '../CustomSnackbar';
import { useUploadImage } from '../../queries/uploadFile';

const IMAGE_SIZE_LIMIT = 200;
const MIN_IMAGE_WIDTH = 148;
const MIN_IMAGE_HEIGHT = 148;

const PublicTeamInfo = ({ data }: { data: ITeam }) => {
  const isMobile = useResponsive('down', 565);
  const updateTeamInfo = useUpdateTeamInfo();
  const snackbar = useSnackBar();
  const uploadImage = useUploadImage();

  const [imageIsValid, imageIsValidUpdater] = useState({
    error: false,
    errorMessage: '',
  });
  const form = useForm({
    defaultValues: {
      name: '',
      website: '',
      logo: '',
    },
  });

  const { handleSubmit, control, reset } = form;

  useEffect(() => {
    if (data) {
      reset({
        name: data.name,
        website: data.website,
        logo: data.logo,
      });
    }
  }, [data, reset]);

  const validateImageAndUpload = imageFile => {
    if (imageFile) {
      if (imageFile.size / 1024 > IMAGE_SIZE_LIMIT) {
        imageIsValidUpdater({
          error: true,
          errorMessage: `Image size must be less or equal to ${IMAGE_SIZE_LIMIT} KB`,
        });
      } else if (imageFile.width < MIN_IMAGE_WIDTH) {
        imageIsValidUpdater({
          error: true,
          errorMessage: `Image width must be greater then ${MIN_IMAGE_WIDTH} pixels`,
        });
      } else if (imageFile.height < MIN_IMAGE_HEIGHT) {
        imageIsValidUpdater({
          error: true,
          errorMessage: `Image width must be greater then  ${MIN_IMAGE_HEIGHT} pixels`,
        });
      } else {
        imageIsValidUpdater({
          error: false,
          errorMessage: '',
        });
        uploadImage.mutate(imageFile, {
          onSuccess: url => {
            data.logo = url;
            saveData(data);
          },
        });
      }
    }
  };

  const handleImageChange = event => {
    event.preventDefault();
    const imageFile = event.target.files[0];
    // Creating image object to validate image height and weight
    const imageObject = new window.Image();
    imageObject.src = URL.createObjectURL(imageFile);
    validateImageAndUpload(imageFile);

    event.target.value = null;
  };

  const saveData = formData => {
    updateTeamInfo.mutate(formData, {
      onSuccess: updatedTeamInfo => {
        snackbar.showSnackBar(
          <Typography fontSize={'inherit'} color={'inherit'}>
            Team information updated successfully.
          </Typography>,
          'success',
        );
      },
    });
  };

  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item xs={8}>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(data => saveData(data))}>
            <Card elevation={2} sx={{ position: 'relative' }}>
              <CardContent>
                <Header>
                  <Typography sx={{ pb: 1 }} variant="h6">
                    Manage your team data
                  </Typography>
                </Header>

                {data.isPublic ? (
                  <Alert
                    severity="success"
                    variant="outlined"
                    sx={{ m: 2, ml: 0 }}
                  >
                    Your team is visible on our public teams list. To remove it
                    from the list please use our chat feature in the bottom
                    right corner or email{' '}
                    <Link
                      href={'mailto:success@pinnaclera.com'}
                      target={'_blank'}
                    >
                      success@pinnaclera.com
                    </Link>
                    .
                  </Alert>
                ) : (
                  <Alert
                    severity="warning"
                    variant="outlined"
                    sx={{ m: 2, ml: 0 }}
                  >
                    Your team is not visible on our public teams list. To show
                    it please use our chat feature in the bottom right corner or
                    email{' '}
                    <Link
                      href={'mailto:success@pinnaclera.com'}
                      target={'_blank'}
                    >
                      success@pinnaclera.com
                    </Link>
                    .
                  </Alert>
                )}

                <Stack
                  direction={'row'}
                  gap={2}
                  width={'100%'}
                  flexWrap={isMobile ? 'wrap' : 'nowrap'}
                >
                  <Grid container spacing={2} sx={{ p: 2 }}>
                    <InputField
                      control={control}
                      name="name"
                      label="Team name"
                      rules={{
                        required: 'Please input name',
                      }}
                      textFieldProps={{ disabled: true }}
                    />
                    <InputField
                      control={control}
                      name="website"
                      label="Website URL"
                      textFieldProps={{ sx: { marginTop: '16px' } }}
                      rules={{
                        required: 'Please enter valid URL',
                      }}
                    />
                    <LoadingButton
                      variant={'contained'}
                      loading={false}
                      type={'submit'}
                      sx={{ mt: 2 }}
                    >
                      Save
                    </LoadingButton>
                  </Grid>
                </Stack>
              </CardContent>
            </Card>
          </form>
        </FormProvider>
      </Grid>
      <Grid item xs={4}>
        <Card sx={{ width: '100%' }}>
          <CardContent>
            <Typography variant={'h6'} pt={1} pb={2}>
              Team logo
            </Typography>
            <img
              src={data.logo ? data.logo : nologo}
              style={{
                width: '148px',
                height: '148px',
                objectFit: 'contain',
              }}
            />
            <Typography variant={'subtitle1'} fontSize={14}>
              Max file size {IMAGE_SIZE_LIMIT} KB
            </Typography>
            <Typography variant={'subtitle1'} fontSize={14}>
              Min dimensions {MIN_IMAGE_WIDTH}px * {MIN_IMAGE_HEIGHT}px
            </Typography>
            <Typography variant={'subtitle1'} fontSize={14}>
              Accepted formats: jpg, jpeg, png
            </Typography>
            {imageIsValid.errorMessage && (
              <FormHelperText error>{imageIsValid.errorMessage}</FormHelperText>
            )}
          </CardContent>
          <CardActions sx={{ pl: 2 }}>
            <LoadingButton
              startIcon={<AddPhotoAlternateOutlinedIcon />}
              variant={'outlined'}
              component={'label'}
            >
              Upload
              <input
                type={'file'}
                accept=".jpg,.jpeg,.png"
                onChange={handleImageChange}
                name="logo"
                hidden
              />
            </LoadingButton>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PublicTeamInfo;
