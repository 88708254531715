import RouteConstants from 'app/routes';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useStore } from '../app/hooks/useStore';

interface IProps extends RouteProps {
  component: any;
  tab?: string;
}

const PrivateRoute = ({ component: Component, tab, ...rest }: IProps) => {
  const isAuthorized = useStore(s => !!s.user?.uuid);

  return isAuthorized ? (
    <Route {...rest} render={props => <Component {...props} />} />
  ) : (
    <Redirect to={RouteConstants.auth.login} />
  );
};

export default PrivateRoute;
