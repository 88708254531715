import React, { useMemo } from 'react';
import { Controller, Control } from 'react-hook-form';
import { MenuItem, Select } from '@mui/material';
import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { SelectProps } from '@mui/material/Select/Select';

export type DropdownOptions = { name: string; value: string }[];

interface Props {
  control: Control<any>;
  name: string;
  label: string;
  options: DropdownOptions;
  rules?: any;
  selectProps?: SelectProps;
  disabled?: boolean;
  onInputChange?: () => void;
}

const InputDropdown: React.FC<Props> = ({
  control,
  name,
  label,
  options,
  rules,
  selectProps,
  disabled,
  onInputChange,
}) => {
  const selectOptions = useMemo(() => {
    return options?.map(option => (
      <MenuItem value={option.value} key={option.value}>
        {option.name}
      </MenuItem>
    ));
  }, [options]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <FormControl fullWidth error={!!error} disabled={disabled}>
          <InputLabel id={`${name}-id`}>{label}</InputLabel>
          <Select
            labelId={`${name}-id`}
            label={label}
            MenuProps={{
              sx: {
                maxHeight: '300px',
              },
            }}
            sx={{
              '& .MuiSelect-select:hover': {
                cursor: disabled ? 'not-allowed' : 'pointer',
              },
              '& .MuiSelect-select': {
                whiteSpace: 'normal',
              },
              '& .MuiSelect-iconOutlined': {
                display: value ? 'none' : '',
              },
            }}
            onChange={event => {
              onChange(event);
              onInputChange && onInputChange();
            }}
            onBlur={onBlur}
            value={value}
            {...selectProps}
          >
            {selectOptions}
          </Select>
          {error?.message && (
            <FormHelperText error>{error.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default InputDropdown;
