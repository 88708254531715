/**
 *
 * Boosts
 *
 */
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import RouteConstants from '../../routes';
import AllBoosts from './pages/AllBoosts/AllBoosts';
import BoostInner from './pages/BoostInner/BoostInner';

function Boosts() {
  return (
    <Switch>
      <Redirect
        exact
        from={RouteConstants.boosts.index}
        to={RouteConstants.boosts.all}
      />
      <Route path={RouteConstants.boosts.all} component={AllBoosts} />
      <Route path={RouteConstants.boosts.boostInner} component={BoostInner} />
      <Route path="*">
        <Redirect to={RouteConstants.boosts.all} />
      </Route>
    </Switch>
  );
}

export default Boosts;
