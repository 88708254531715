import styled, { css, keyframes } from 'styled-components';
import { media } from 'styles/media';
import { Link } from 'react-router-dom';

export const Section = styled.section`
  padding: 15rem 0 10rem 0;
  min-height: 94vh;
`;

export const Layout = styled.div`
  display: grid;
  grid-template-columns: 8rem 1fr;

  ${media.phoneLand`
    grid-template-columns: 1fr;
  `}
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const FormCard = styled.div`
  .ant-form-item-label {
    > label {
      text-transform: uppercase;
    }

    .ant-form-item-required::before {
      content: '' !important;
    }
  }

  .ant-upload-list-item-name {
    width: 407px;

    ${media.desktopSmall`
      width: 261px;
    `}

    ${media.tabLand`
      width: 405px;
    `}

    ${media.tabLandSmall`
      width: 286px;
    `}

    ${media.tabPort`
      width: 373px;
    `}

    ${media.phoneLand`
      width: 383px;
    `}

    ${media.phone`
      width: 224px;
    `}
  }
`;

export const Table = styled.div`
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin-top: 2rem;
  display: block;

  > * {
    &:not(:last-child) {
      border-bottom: 1px solid #f0f0f0;
    }
  }
`;

export const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 14rem 1fr 1fr;
  grid-gap: 3rem;
  align-items: center;
  padding: 1.6rem 0 1.6rem 2.4rem;

  ${media.phone`
    grid-template-columns: 10rem 1fr;
  `}
`;

export const UserTableHeader = styled.div`
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-gap: 3rem;
  align-items: center;
  padding: 1.6rem 0 1.6rem 2.4rem;

  ${media.phone`
    grid-template-columns: 10rem 1fr;
  `}
`;
export const TeamUserTableHeader = styled.div`
  display: grid;
  grid-template-columns: 40% 40% 10%;
  grid-gap: 3rem;
  align-items: center;
  padding: 1.6rem 0 1.6rem 2.4rem;

  ${media.phone`
    grid-template-columns: 10rem 1fr;
  `}
`;

export const TeamTableHeader = styled.div`
  display: grid;
  grid-template-columns: 20% 30% 40%;
  grid-gap: 3rem;
  align-items: center;
  padding: 1.6rem 0 1.6rem 2.4rem;

  ${media.phone`
    grid-template-columns: 10rem 1fr;
  `}
`;

export const UserListRow = styled(Link)<IRowProps>`
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-gap: 3rem;
  align-items: center;
  padding: 0 2.4rem;

  ${props =>
    props.loading === 'true'
      ? css`
          -webkit-filter: blur(2px);
          -moz-filter: blur(2px);
          -o-filter: blur(2px);
          -ms-filter: blur(2px);
          animation: ${blur} 1.5s ease 0s infinite;
        `
      : ''};

  ${props =>
    props.active && props.active === 'false'
      ? css`
          -webkit-filter: blur(0.5px);
          -moz-filter: blur(0.5px);
          -o-filter: blur(0.5px);
          -ms-filter: blur(0.5px);
          text-decoration: line-through;
          text-decoration-color: black;
        `
      : ''};

  &:active,
  &:hover {
    background-color: ${props =>
      props.loading === 'true' ? 'transparent' : '#fafafa'};
  }

  ${media.phone`
    grid-template-columns: 10rem 1fr;
  `}
`;

export const TeamUserListRow = styled(Link)<IRowProps>`
  display: grid;
  grid-template-columns: 40% 40% 10%;
  grid-gap: 3rem;
  align-items: center;
  padding: 0 2.4rem;

  ${props =>
    props.loading === 'true'
      ? css`
          -webkit-filter: blur(2px);
          -moz-filter: blur(2px);
          -o-filter: blur(2px);
          -ms-filter: blur(2px);
          animation: ${blur} 1.5s ease 0s infinite;
        `
      : ''};

  &:active,
  &:hover {
    background-color: ${props =>
      props.loading === 'true' ? 'transparent' : '#fafafa'};
  }

  ${media.phone`
    grid-template-columns: 10rem 1fr;
  `}
`;

export const TeamListRow = styled(Link)<IRowProps>`
  display: grid;
  grid-template-columns: 20% 30% 40%;
  grid-gap: 3rem;
  align-items: center;
  padding: 0 2.4rem;

  ${props =>
    props.loading === 'true'
      ? css`
          -webkit-filter: blur(2px);
          -moz-filter: blur(2px);
          -o-filter: blur(2px);
          -ms-filter: blur(2px);
          animation: ${blur} 1.5s ease 0s infinite;
        `
      : ''};

  &:active,
  &:hover {
    background-color: ${props =>
      props.loading === 'true' ? 'transparent' : '#fafafa'};
  }

  ${media.phone`
    grid-template-columns: 10rem 1fr;
  `}
`;

const blur = keyframes`
  0%,
  90% {
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
  }
  50% {
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
  }
`;
interface IRowProps {
  loading: 'true' | 'false';
  active?: 'true' | 'false';
}

export const TableRow = styled(Link)<IRowProps>`
  display: grid;
  grid-template-columns: 14rem 1fr 1fr;
  grid-gap: 3rem;
  align-items: center;
  padding: 1.6rem 2.4rem;

  ${props =>
    props.loading === 'true'
      ? css`
          -webkit-filter: blur(2px);
          -moz-filter: blur(2px);
          -o-filter: blur(2px);
          -ms-filter: blur(2px);
          animation: ${blur} 1.5s ease 0s infinite;
        `
      : ''};

  &:active,
  &:hover {
    background-color: ${props =>
      props.loading === 'true' ? 'transparent' : '#fafafa'};
  }

  ${media.phone`
    grid-template-columns: 10rem 1fr 1fr;
  `}
`;

export const Logo = styled.img`
  display: block;
  max-height: 4rem;
  max-width: 100%;
  object-fit: contain;
  margin-bottom: 8px;
`;
