import { request } from 'utils/request';
import {
  IBoostData,
  IDealData,
  UserInnerPage,
  IUserData,
  IUserInnerPageImageUrl,
  ITeamData,
} from './slice/types';

const adminApi = {
  getUsers: () =>
    request({
      url: 'admin/users/',
    }),
  getTeams: () =>
    request({
      url: 'admin/team/',
    }),
  getTeam: (teamId: string) =>
    request({
      url: `admin/team/${teamId}`,
    }),
  updateUser: (userId: string, data: IUserData) =>
    request({
      method: 'PUT',
      url: `admin/users/edit/${userId}`,
      data,
    }),
  createUser: (data: IUserData) =>
    request({
      method: 'POST',
      url: 'admin/user/create',
      data,
    }),
  createTeam: (data: ITeamData) =>
    request({
      method: 'POST',
      url: 'admin/team/create',
      data,
    }),
  updateTeam: (teamId: string, data: ITeamData) =>
    request({
      method: 'PUT',
      url: `admin/team/edit/${teamId}`,
      data,
    }),
  getUser: (userId: string) =>
    request({
      url: `admin/users/${userId}`,
    }),
  getBoosts: () =>
    request({
      url: 'admin/online_tools/',
    }),
  getBoost: (boostId: number) =>
    request({
      url: `admin/online_tools/${boostId}`,
    }),
  saveBoost: (boostId: number, data: IBoostData) =>
    request({
      method: 'PUT',
      url: `admin/online_tools/edit/${boostId}`,
      data,
    }),
  createBoost: (data: IBoostData) =>
    request({
      method: 'POST',
      url: 'admin/online_tools/create',
      data,
    }),
  getDeals: () =>
    request({
      url: 'admin/vendors/',
    }),
  getDeal: (dealId: number) =>
    request({
      url: `admin/vendors/${dealId}`,
    }),
  updateDeal: (dealId: number, data: IDealData) =>
    request({
      method: 'PUT',
      url: `admin/vendors/edit/${dealId}`,
      data,
    }),
  createDeal: (data: IDealData) =>
    request({
      method: 'POST',
      url: 'admin/vendors/create',
      data,
    }),
  uploadImage: (data: FormData) =>
    request({
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      url: 'admin/images/upload',
      data,
    }),
  getDealsCategories: () =>
    request({
      url: 'admin/vendors_categories/',
    }),
  getBoostsCategories: () =>
    request({
      url: 'admin/online_tools_categories/',
    }),
  getLocations: () =>
    request({
      url: 'admin/vendors/geo',
    }),
  getUserStripeUrl: (userId: string) =>
    request({
      url: `/stripe/customer_portal/${userId}`,
    }),
  generateUserDocusignDocument: (userId: string) =>
    request({
      method: 'POST',
      url: `/docusign/send-envelope/${userId}`,
    }),
  updateUserPublicData: (data: UserInnerPage, userId: string) =>
    request({
      method: 'POST',
      url: `/admin/user/inner-page/${userId}`,
      data,
    }),
  updateUserInnerPageImageUrl: (data: IUserInnerPageImageUrl, userId: string) =>
    request({
      method: 'POST',
      url: `/admin/user/inner-page/image/${userId}`,
      data,
    }),
};

export default adminApi;
