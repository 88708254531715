import React, { useState } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  FormHelperText,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DefaultProfileImg from '../../../assets/images/Avatar.png';
import styled from 'styled-components';

const ProfilePicture = styled.img`
  width: 148px;
  height: 148px;
  clip-path: circle(50% at 50% 50%);
  margin-bottom: 8px;
  object-fit: cover;
`;

const IMAGE_SIZE_LIMIT = 512;
const MIN_IMAGE_WIDTH = 200;
const MIN_IMAGE_HEIGHT = 200;

interface Props {
  currentProfilePicture?: string;
  changeProfilePicture: (profilePicture: File | null) => void;
  isProfilePictureLoading: boolean;
}

interface ImageFile extends File {
  width: number;
  height: number;
}

const ProfilePictureUpload: React.FC<Props> = ({
  currentProfilePicture,
  changeProfilePicture,
  isProfilePictureLoading,
}) => {
  const [imageIsValid, imageIsValidUpdater] = useState({
    error: false,
    errorMessage: '',
  });

  const handleImageChange = event => {
    event.preventDefault();
    const imageFile = event.target.files[0];
    // Creating image object to validate image height and weight
    const imageObject = new window.Image();
    imageObject.src = URL.createObjectURL(imageFile);
    imageObject.onload = () => {
      imageFile.width = imageObject.naturalWidth;
      imageFile.height = imageObject.naturalHeight;
      validateImageAndUpload(imageFile);
    };
    event.target.value = null;
  };

  const handleImageRemove = () => {
    changeProfilePicture(null);
  };

  const validateImageAndUpload = (imageFile: ImageFile) => {
    if (imageFile) {
      if (imageFile.size / 1024 > IMAGE_SIZE_LIMIT) {
        imageIsValidUpdater({
          error: true,
          errorMessage: `Image size must be less or equal to ${IMAGE_SIZE_LIMIT} KB`,
        });
      } else if (imageFile.width < MIN_IMAGE_WIDTH) {
        imageIsValidUpdater({
          error: true,
          errorMessage: `Image width must be greater then ${MIN_IMAGE_WIDTH} pixels`,
        });
      } else if (imageFile.height < MIN_IMAGE_HEIGHT) {
        imageIsValidUpdater({
          error: true,
          errorMessage: `Image width must be greater then  ${MIN_IMAGE_HEIGHT} pixels`,
        });
      } else {
        imageIsValidUpdater({
          error: false,
          errorMessage: '',
        });
        changeProfilePicture(imageFile);
      }
    }
  };

  return (
    <Card sx={{ width: '100%' }}>
      <CardContent>
        <Typography variant={'h6'} pt={1} pb={2}>
          Profile picture
        </Typography>
        <ProfilePicture
          src={
            currentProfilePicture ? currentProfilePicture : DefaultProfileImg
          }
        />
        <Typography variant={'subtitle1'} fontSize={14}>
          Max file size {IMAGE_SIZE_LIMIT} KB
        </Typography>
        <Typography variant={'subtitle1'} fontSize={14}>
          Min dimensions {MIN_IMAGE_WIDTH}px * {MIN_IMAGE_HEIGHT}px
        </Typography>
        <Typography variant={'subtitle1'} fontSize={14}>
          Accepted formats: jpg, jpeg, png
        </Typography>
        {imageIsValid.errorMessage && (
          <FormHelperText error>{imageIsValid.errorMessage}</FormHelperText>
        )}
      </CardContent>
      <CardActions sx={{ p: 2 }}>
        <LoadingButton
          startIcon={<AddPhotoAlternateOutlinedIcon />}
          variant={'outlined'}
          component={'label'}
          loading={isProfilePictureLoading}
          sx={{ mr: 1 }}
        >
          Upload
          <input
            type={'file'}
            accept=".jpg,.jpeg,.png"
            onChange={handleImageChange}
            name="profilePicture"
            hidden
          />
        </LoadingButton>
        <LoadingButton
          startIcon={<DeleteOutlineOutlinedIcon />}
          variant={'outlined'}
          type="submit"
          loading={isProfilePictureLoading}
          disabled={!currentProfilePicture}
          onClick={handleImageRemove}
        >
          Remove
        </LoadingButton>
      </CardActions>
    </Card>
  );
};

export default ProfilePictureUpload;
