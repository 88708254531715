import React from 'react';
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount';
import { Box, Container, Link, Typography } from '@mui/material';
import PageHeader from '../../../components/PageHeader';
import OffersList from '../../../components/OffersList';
import { useFetchOnlineTools } from '../../../queries/onlineTools/useOnlineToolsQueries';
import Categories from '../../../components/Categories';
import RouteConstants from '../../../routes';
import { useScrollAfterChange } from '../../../hooks/useScrollAfterChange';
import { useQuery } from '../../../hooks/useQuery';
import { BoostsQueryParams } from '../../Boosts/slice/types';

const SCROLL_POINT_ID = 'scroll-point';

export interface IFilter {
  category?: string;
  residential?: string;
  commercial?: string;
}
export interface GeneralToolsProps {
  filter?: IFilter;
  innerPageRoute?: string;
  listRoute?: string;
}
const GeneralTools: React.FC<GeneralToolsProps> = ({
  filter = {},
  innerPageRoute,
  listRoute,
}) => {
  const query = useQuery();

  if (query.has(BoostsQueryParams.Category)) {
    filter.category = query.get(BoostsQueryParams.Category) || undefined;
  } else {
    delete filter.category;
  }

  const { data, isLoading } = useFetchOnlineTools(filter);
  const tools = data ? data.online_tools : null;
  const categories = data ? data.categories : [];

  useScrollAfterChange(tools, SCROLL_POINT_ID);

  return (
    <Container maxWidth="xl">
      <ScrollToTopOnMount />
      <PageHeader
        withDivider
        title="Tools & Resources for you"
        subtitle={
          <Box>
            <Typography>
              More than 50 different, carefully selected tools & resources for
              choosing what best suits your business needs.
            </Typography>
            <Typography>
              Everything from lead generation & marketing tools to continuing
              education courses and virtual staging solutions. Our team is
              constantly adding new tools and improving the offers for you. If
              you have a preferred partner and are looking for it on PinnacleHQ,
              contact us via{' '}
              <Link href={'mailto:partnerships@pinnaclera.com'}>
                partnerships@pinnaclera.com
              </Link>
              , and our team will add it to the platform.
            </Typography>
          </Box>
        }
      >
        <Categories
          rootUrl={listRoute ?? RouteConstants.boosts.all}
          categories={categories}
        />
      </PageHeader>
      <OffersList
        isLoading={isLoading}
        boosts={tools}
        routeName={innerPageRoute}
      />
    </Container>
  );
};

export default GeneralTools;
