import React from 'react';
import {
  Container,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Link,
  StepContent,
  styled,
  stepConnectorClasses,
  StepConnector,
  Stack,
  Card,
  CardContent,
  CardActions,
  Button,
} from '@mui/material';
import PageHeader from '../../components/PageHeader';
import { useStore } from '../../hooks/useStore';
import ContactTile from '../../components/ContactTile';
import Row from '../../components/Row';
import { FLexRow } from '../../components/DealsList/DealsList.styles';
import RouteConstants from '../../routes';

const OutlinedStepper = styled(props => <Step {...props} />)(({ theme }) => ({
  '& .MuiStepLabel-root .Mui-active .MuiStepIcon-root': {
    height: '40px',
    width: '40px',
  },
  '& .MuiStepLabel-label .Mui-active .MuiStepLabel-alternativeLabel': {
    color: theme.palette.primary.main, // Just text label (ACTIVE)
  },
  '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
    fill: theme.palette.primary.main, // circle's number (ACTIVE)
  },
  '& .MuiStepper-root.MuiStepConnector-vertical': {
    marginLeft: '20px',
  },
}));

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    marginLeft: '8px',
    borderColor: theme.palette.primary.main,
  },
}));

const OutlinedStepContent = styled(props => <StepContent {...props} />)(
  ({ theme }) => ({
    marginLeft: '20px',
    borderColor: theme.palette.primary.main,
  }),
);

const Onboarding = () => {
  const user = useStore(s => s.user);

  return (
    <Container maxWidth="xl">
      <PageHeader
        withDivider
        title="New Agent On-boarding Process"
        subtitle={
          <Typography variant={'h6'}>
            <b>
              To ensure compliance with our brokerage, it is imperative to
              sequentially complete Steps 1-4. Failure to fulfill all four steps
              within 30 days could result in your removal from license
              affiliation, local association and MLS. Please use our chat
              feature or email{' '}
              <Link href={'mailto:success@pinnaclera.com'} target={'_blank'}>
                success@pinnaclera.com
              </Link>{' '}
              if you need assistance.
            </b>
          </Typography>
        }
      />

      <Grid container pb={4}>
        <Grid item xs={12} md={8}>
          <Stepper orientation="vertical" connector={<CustomConnector />}>
            <OutlinedStepper active>
              <StepLabel>
                <Typography variant={'h6'}>
                  Review and Sign Onboarding Documents
                </Typography>
              </StepLabel>
              <OutlinedStepContent>
                <Stack spacing={2}>
                  <Typography>
                    You will be receiving a DocuSign packet from our team
                    containing your Pinnacle required documents, state licensing
                    and federal tax forms.
                  </Typography>
                  <Typography>
                    If you signed up on the weekend or after hours, we’ll send
                    you the packet the next business day.
                  </Typography>
                </Stack>
              </OutlinedStepContent>
            </OutlinedStepper>
            {user?.licenseState === 'CA' && (
              <OutlinedStepper active>
                <StepLabel>
                  <Typography variant={'h6'}>
                    Add Pinnacle as your Broker in the DRE eLicensing Platform
                  </Typography>
                </StepLabel>
                <OutlinedStepContent>
                  <Stack spacing={2}>
                    <Typography>
                      You must{' '}
                      <Link
                        href={
                          'https://docs.google.com/document/d/16VuWumRMmaS1OcIrWGTLlXqcH-ejZNwdTB0lnFhPins/edit'
                        }
                        target={'_blank'}
                      >
                        request affiliation
                      </Link>{' '}
                      through the eLicensing portal using our license number
                      02220139. We will accept your sponsorship request.
                    </Typography>
                  </Stack>
                </OutlinedStepContent>
              </OutlinedStepper>
            )}
            {user?.licenseState === 'TX' && (
              <OutlinedStepper active>
                <StepLabel>
                  <Typography variant={'h6'}>
                    Accept the License Invitation from TREC!
                  </Typography>
                </StepLabel>
                <OutlinedStepContent>
                  <Stack spacing={2}>
                    <Typography>
                      We will then send you an invitation through{' '}
                      <Link
                        href={'https://www.trec.texas.gov/'}
                        target={'_blank'}
                      >
                        TREC's
                      </Link>{' '}
                      online portal to join our brokerage after we’ve received
                      notification that you’ve completed the DocuSign envelope.
                    </Typography>
                    <Typography>
                      TREC will email you directly, and you will need to approve
                      your affiliation with Pinnacle Realty Advisors (even if
                      you’re a White Label agent) through TREC’s online portal.
                      You will receive a confirmation email from TREC with your
                      new license that references PR Advisors, LLC (our legal
                      entity name) as your new broker.
                    </Typography>
                    <Typography>
                      <Link
                        href={
                          'https://docs.google.com/document/d/1RKrCbNL9i3cWD2KXBKHoqP2j4rwHvgCH78YnRQk15qo/edit?usp=sharing'
                        }
                        target={'_blank'}
                      >
                        Step-by-step Instructions for How to Accept a TREC
                        Invite for Sponsorship
                      </Link>
                    </Typography>
                  </Stack>
                </OutlinedStepContent>
              </OutlinedStepper>
            )}
            {user?.licenseState === 'LA' && (
              <OutlinedStepper active>
                <StepLabel>
                  <Typography variant={'h6'}>
                    Accept the License Invitation from LREC!
                  </Typography>
                </StepLabel>
                <OutlinedStepContent>
                  <Stack spacing={2}>
                    <Typography>
                      Log in to{' '}
                      <Link
                        href={'https://portal.lrec.gov/Home/Login'}
                        target={'_blank'}
                      >
                        myLREC
                      </Link>{' '}
                      portal and terminate current broker sponsorship and
                      request new sponsorship under our corporate brokerage, PR
                      Advisors LLC, <b>BROK.995705218-CORP</b>.
                    </Typography>
                    <Typography>
                      DO NOT PUT THE BROKER'S LICENSE NUMBER. YOU MUST PUT THE
                      CORPORATE BROKERAGE: PR Advisors LLC, BROK.995705218-CORP.
                    </Typography>
                  </Stack>
                </OutlinedStepContent>
              </OutlinedStepper>
            )}
            {user?.licenseState && ['NC'].includes(user?.licenseState) && (
              <OutlinedStepper active>
                <StepLabel>
                  <Typography variant={'h6'}>
                    Update your information with the North Carolina Real Estate
                    Commission
                  </Typography>
                </StepLabel>
                <OutlinedStepContent>
                  <Stack spacing={2}>
                    <Typography>
                      If you are moving from another brokerage you need to
                      complete the "
                      <Link
                        href={'https://www.ncrec.gov/TerminateAffiliation'}
                        target={'_blank'}
                      >
                        Request to Terminate Affiliation with a Firm
                      </Link>
                      " online form. After you complete your termination or if
                      you are a new agent, you will fill out the "
                      <Link
                        href={'https://www.ncrec.gov/licenseeactivation'}
                        target={'_blank'}
                      >
                        License Activation/Affiliation
                      </Link>
                      " online form.
                    </Typography>
                  </Stack>
                </OutlinedStepContent>
              </OutlinedStepper>
            )}
            {user?.licenseState && ['FL'].includes(user?.licenseState) && (
              <OutlinedStepper active>
                <StepLabel>
                  <Typography variant={'h6'}>
                    We will update your License Sponsorship with the Florida
                    DBPR
                  </Typography>
                </StepLabel>
                <OutlinedStepContent>
                  <Stack spacing={2}>
                    <Typography>
                      Let our Onboarding Team know when you need this to happen
                      as the process is pretty instantaneous!
                    </Typography>
                  </Stack>
                </OutlinedStepContent>
              </OutlinedStepper>
            )}
            {user?.licenseState && ['AR'].includes(user?.licenseState) && (
              <OutlinedStepper active>
                <StepLabel>
                  <Typography variant={'h6'}>
                    Update information in the Arkansas Real Estate Commission!
                  </Typography>
                </StepLabel>
                <OutlinedStepContent>
                  <Stack spacing={2}>
                    <Typography>
                      If you are a newly licensed agent, we will send you the{' '}
                      <Link
                        href={
                          'https://arec.arkansas.gov/wp-content/uploads/2021/08/2021Activationform.pdf'
                        }
                        target={'_blank'}
                      >
                        AREC Real Estate Activation Form
                      </Link>{' '}
                      for your signature. Our team will get the executed
                      Activation Form mailed to AREC (you will also receive a
                      copy). AREC typically takes 10 days to process this
                      paperwork.
                    </Typography>
                    <Typography>
                      If you are transferring your license to Pinnacle from
                      another brokerage, you will need to complete the{' '}
                      <Link
                        href={
                          'https://arec.arkansas.gov/wp-content/uploads/2021/08/License-Transfer-Form.pdf'
                        }
                        target={'_blank'}
                      >
                        AREC Real Estate License Transfer Form
                      </Link>
                      . You and your departing broker will both need to sign and
                      return it to us at{' '}
                      <Link href={'mailto:onboarding@pinnaclera.com'}>
                        onboarding@pinnaclera.com
                      </Link>
                      . Our team will get the executed License Transfer Form
                      mailed to AREC (you will also receive a copy). The
                      Executed License Transfer Form also acts as your temporary
                      license until AREC mails you your updated license.
                    </Typography>
                  </Stack>
                </OutlinedStepContent>
              </OutlinedStepper>
            )}
            <OutlinedStepper active>
              <StepLabel>
                <Typography variant={'h6'}>
                  Update your Information with your Local Association
                </Typography>
              </StepLabel>
              <OutlinedStepContent>
                {user?.licenseState === 'TX' && (
                  <Stack spacing={2}>
                    <Typography>
                      Please take a moment to join/update your local association
                      ASAP to stay compliant with TREC. Instructions are
                      attached and provided in this{' '}
                      <Link
                        href={
                          'https://docs.google.com/document/d/1siXSJAgOnzG0rI3OtShNZ-u765KbFhtmVSrn7qyYsDA/edit'
                        }
                        target={'_blank'}
                      >
                        Association Application/Transfer Instructions
                      </Link>{' '}
                      document. You can not update your association until Step
                      2(above) is completed.
                    </Typography>
                    <Typography>
                      Please confirm your membership by asking the association
                      for a letter of good standing and emailing it to{' '}
                      <Link
                        href={'mailto:onboarding@pinnaclera.com'}
                        target={'_blank'}
                      >
                        onboarding@pinnaclera.com
                      </Link>
                    </Typography>
                    <Typography>
                      (You have 30 days to do this to be compliant)
                    </Typography>
                  </Stack>
                )}
                {user?.licenseState === 'LA' && (
                  <Stack spacing={2}>
                    <Typography>
                      If you are a transfer agent, we will facilitate the
                      updating of your local association using the MLS transfer
                      form.
                    </Typography>
                    <Typography>
                      If you are a new agent, you have 30 days to visit your
                      local association in person to join and sign up for your
                      orientation. Please reference our{' '}
                      <Link
                        href={
                          'https://docs.google.com/document/d/1gFOgFttAaRrTUI4bPsBgQDhjMuPthgDbdlfeNujNHhk/edit?usp=sharing'
                        }
                        target={'_blank'}
                      >
                        Association Application Instructions
                      </Link>
                      .
                    </Typography>
                  </Stack>
                )}
                {user?.licenseState === 'CA' && (
                  <Stack spacing={2}>
                    <Typography>
                      After you receive confirmation of affiliation with
                      Pinnacle, please take a moment to join/update your local
                      association ASAP to stay compliant with DRE. Instructions
                      are attached and provided in this{' '}
                      <Link
                        href={
                          'https://docs.google.com/document/d/1_dobwoi5akDzALXGia37O2ro_wATrimJFdI-Zu4ZBmo/edit?usp=sharing'
                        }
                        target={'_blank'}
                      >
                        Association Application/Transfer Instructions
                      </Link>{' '}
                      document. You can not update your association until Step 2
                      (above) is completed.
                    </Typography>
                    <Typography>
                      (You have 30 days to do this to be compliant)
                    </Typography>
                  </Stack>
                )}
                {user?.licenseState === 'AR' && (
                  <Stack spacing={2}>
                    <Typography>
                      Please take a moment to join/update your local association
                      ASAP to stay compliant with AREC. Instructions are
                      attached and provided in this{' '}
                      <Link
                        href={
                          'https://docs.google.com/document/d/18JrrIphtRMt6nFJ-TCW2NI_zdLK3BV77m_VkKsrFGT0/edit?usp=sharing'
                        }
                        target={'_blank'}
                      >
                        Association Application/Transfer Instructions
                      </Link>{' '}
                      document. You can not update your association until Step 2
                      (above) is completed.
                    </Typography>
                  </Stack>
                )}
                {user?.licenseState && ['NC'].includes(user?.licenseState) && (
                  <Stack spacing={2}>
                    <Typography>
                      Please take a moment to join/update your local association
                      ASAP to stay compliant with NCREC. Instructions are
                      attached and provided in this{' '}
                      <Link
                        href={
                          'https://docs.google.com/document/d/1wL8FUou28Gp8yRQEftK9YIJ9GcTCdNNb3v8LYuEr26k/edit?usp=sharing'
                        }
                        target={'_blank'}
                      >
                        Association Application/Transfer Instructions
                      </Link>{' '}
                      document. You can not update your association until Step 2
                      (above) is completed.
                    </Typography>
                  </Stack>
                )}
                {user?.licenseState && ['FL'].includes(user?.licenseState) && (
                  <Stack spacing={2}>
                    <Typography>
                      Please take a moment to join/update your local association
                      ASAP to stay compliant with FREC. Instructions are
                      attached and provided in this{' '}
                      <Link
                        href={
                          'https://docs.google.com/document/d/1Urdqv2i2H-VDtX1DbtOIo9VanKpLOS6uNoqWIj6exyo/edit?usp=sharing'
                        }
                        target={'_blank'}
                      >
                        Association Application/Transfer Instructions
                      </Link>{' '}
                      document. You can not update your association until Step 2
                      (above) is completed.
                    </Typography>
                    <Typography>
                      (You have 30 days to do this to be compliant)
                    </Typography>
                  </Stack>
                )}
              </OutlinedStepContent>
            </OutlinedStepper>
            <OutlinedStepper active>
              <StepLabel>
                <Typography variant={'h6'}>
                  Attend one of our <b>Mandatory</b> "Pinnacle 101" Workshops
                </Typography>
              </StepLabel>
              <OutlinedStepContent>
                <Typography mb={4}>
                  Register{' '}
                  <Link
                    href={
                      'https://calendly.com/pinnaclerealtyadvisors/pinnacle-101-workshop'
                    }
                    target={'_blank'}
                  >
                    here
                  </Link>
                  .
                </Typography>
                <Typography>
                  After this class, you will gain access to and learn about all
                  of Pinnacle’s tools and resources. Most importantly, you will
                  learn the process of getting paid. Attendance in this class is
                  mandatory to receive payment.
                </Typography>
              </OutlinedStepContent>
            </OutlinedStepper>
            <OutlinedStepper active>
              <StepLabel>
                <Typography variant={'h6'}>Accounts to Setup</Typography>
              </StepLabel>
              <OutlinedStepContent>
                <Typography>
                  Welcome emails will be sent after you attend Pinnacle 101.
                  Please accept the invitation right away so your link does not
                  expire.
                </Typography>
                <Stack spacing={2} pt={2}>
                  <Typography>
                    <Link href={'https://skyslope.com/'} target={'_blank'}>
                      SkySlope:
                    </Link>{' '}
                    This is our transaction management software. You must upload
                    all of your documents to SkySlope in order to get paid.
                    Please pick a day and time to attend a training session.{' '}
                    <Link
                      href={'https://learn.skyslope.com/'}
                      target={'_blank'}
                    >
                      Click here to schedule
                    </Link>
                    .
                  </Typography>
                  <Typography>
                    <Link
                      href={
                        'https://join.slack.com/t/pinnacleraagents/shared_invite/zt-10rp08h9d-BbtUPwd372RDvpSRGsiRkw'
                      }
                      target={'_blank'}
                    >
                      Slack:
                    </Link>{' '}
                    This is our internal messaging system. You can connect with
                    anyone from staff as well as PRA agents across different
                    states. We also drop free leads into our chat forum,
                    distributed on a first-to-respond basis.
                  </Typography>
                  <Typography>
                    <Link
                      href={'https://www.depositlink.com/'}
                      target={'_blank'}
                    >
                      DepositLink:
                    </Link>{' '}
                    This is a <b>FREE</b> service we use to electronically
                    transfer commissions to our agents. If you do not want to
                    use this <b>FREE</b> service and would prefer to use a
                    transfer service, there is a $25 fee for each wire transfer
                    (the banks charge this fee, not us). You will not be
                    prompted to add your bank details until you close your first
                    deal.
                  </Typography>
                </Stack>
              </OutlinedStepContent>
            </OutlinedStepper>
          </Stepper>
        </Grid>
      </Grid>
      <Grid container spacing={2} pb={4}>
        <Grid item xs={12} md={6}>
          <Card>
            <Stack justifyContent={'space-between'} flexGrow={1}>
              <CardContent>
                <Typography variant={'h6'} pb={1}>
                  How to Get Paid
                </Typography>
              </CardContent>
              <CardActions>
                <Stack direction={'row'} flexWrap={'wrap'}>
                  <Button component={'a'} href={RouteConstants.getPaid.index}>
                    Get Paid
                  </Button>
                </Stack>
              </CardActions>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <Stack justifyContent={'space-between'} flexGrow={1}>
              <CardContent>
                <Typography variant={'h6'} pb={1}>
                  Make sure to Update your Marketing Collateral
                </Typography>
              </CardContent>
              <CardActions>
                <Stack direction={'row'} flexWrap={'wrap'}>
                  <Button component={'a'} href={RouteConstants.wiki.index}>
                    Useful links
                  </Button>
                </Stack>
              </CardActions>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Onboarding;
