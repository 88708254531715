import { Snackbar } from '@mui/material';
import { AlertColor } from '@mui/lab';
import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';
import MuiAlert from '@mui/material/Alert';
import { useStore } from '../../hooks/useStore';

type SnackBarContextActions = {
  showSnackBar: (message: string | ReactNode, typeColor: AlertColor) => void;
};

const SnackBarContext = createContext({} as SnackBarContextActions);

interface SnackBarContextProviderProps {
  children: ReactNode;
}

const useSnackBar = (): SnackBarContextActions => {
  const context = useContext(SnackBarContext);

  if (!context) {
    throw new Error('useSnackBar must be used within an SnackBarProvider');
  }

  return context;
};

const SnackBarProvider: React.FC<SnackBarContextProviderProps> = ({
  children,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string | ReactNode>('');
  const [typeColor, setTypeColor] = useState<AlertColor>('info');
  const isAuthorized = useStore(s => !!s.user?.uuid);

  const showSnackBar = (message: string | ReactNode, color: AlertColor) => {
    setMessage(message);
    setTypeColor(color);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const snackbarStyle = useMemo(() => {
    if (isAuthorized) {
      return { top: { xs: 90, lg: 30 }, left: { lg: 'calc(50% + 130px)' } };
    } else {
      return { top: 30 };
    }
  }, [isAuthorized]);

  return (
    <SnackBarContext.Provider value={{ showSnackBar }}>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={snackbarStyle}
        onClose={handleClose}
      >
        <MuiAlert
          onClose={handleClose}
          severity={typeColor}
          variant="filled"
          elevation={6}
          sx={{ width: '100%' }}
        >
          {message}
        </MuiAlert>
      </Snackbar>
      {children}
    </SnackBarContext.Provider>
  );
};

export { SnackBarProvider, useSnackBar };
