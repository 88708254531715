import create from 'zustand';
import { IUser } from '../queries/auth/types';

interface StoreState {
  user: IUser | null;
  redirectTo: string | null;
  setRedirectTo: (redirect: string | null) => void;
  setUser: (user: IUser) => void;
  resetUser: () => void;
}

export const useSetUserData = () => {
  return useStore(store => store.setUser);
};

export const useResetUserData = () => {
  return useStore(store => store.resetUser);
};

export const useRedirectTo = () => {
  return useStore(store => store.setRedirectTo);
};

export const useStore = create<StoreState>(set => ({
  user: null,
  redirectTo: null,
  setRedirectTo: (redirect: string | null) => {
    set({ redirectTo: redirect });
  },
  setUser: (user: IUser) => {
    set({ user: user });
  },
  resetUser: () => {
    set({ user: null });
  },
}));
