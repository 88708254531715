import React, { useEffect } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  TextField,
  Typography,
  Box,
  Link,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { States } from '../States';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useUserProfileSlice } from '../slice';
import {
  selectStripeUrl,
  selectStripeUrlIsLoading,
  selectUserAddressInfo,
} from '../slice/selectors';
import {
  InputField,
  DatePickerInput,
  InputDropdown,
} from '../../../components/FormInputs';
import useResponsive from '../../../hooks/useResponsive';
import StripeLogo from '../../../../assets/images/StripeLogo.svg';
import normalizeUrl from 'normalize-url';
import { fetchStatus } from '../../Global/slice/types';
import { IUser } from '../../../queries/auth/types';

const GridContainer = props => (
  <Grid
    container
    direction="row"
    justifyContent="space-between"
    alignItems="flex-start"
    rowSpacing={3}
    columnSpacing={{ xs: 2, md: 3 }}
    {...props}
  />
);

type Inputs = {
  name: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dateFirstLicensed: string;
  licenseExpirationDate: string;
  licenseState: string;
  address: {
    streetAddress: string;
    state: string;
    zipCode: string;
    city: string;
  };
};

interface Props {
  userData?: IUser;
  userDataFetchStatus: fetchStatus;
}

const UserProfile: React.FC<Props> = ({ userData, userDataFetchStatus }) => {
  const userAddress = useSelector(selectUserAddressInfo);
  const stripeUrlFetchStatus = useSelector(selectStripeUrlIsLoading);
  const stripeUrl = useSelector(selectStripeUrl);
  const dispatch = useDispatch();
  const { actions } = useUserProfileSlice();
  const isMobile = useResponsive('down', 565);

  const form = useForm<Inputs>({
    defaultValues: {
      name: '',
      lastName: '',
      phoneNumber: '',
      dateFirstLicensed: '',
      licenseExpirationDate: '',
      licenseState: '',
      address: {
        streetAddress: '',
        state: '',
        zipCode: '',
        city: '',
      },
    },
  });

  const { handleSubmit, control, reset } = form;

  useEffect(() => {
    reset({
      name: userData?.name || '',
      lastName: userData?.lastName || '',
      dateFirstLicensed: userData?.dateFirstLicensed || '',
      licenseExpirationDate: userData?.licenseExpirationDate || '',
      phoneNumber: userData?.phoneNumber || '',
      licenseState: userData?.licenseState || '',
      address: {
        streetAddress: userAddress?.streetAddress || '',
        state: userAddress?.state || '',
        zipCode: userAddress?.zipCode || '',
        city: userAddress?.city || '',
      },
    });
  }, [reset, userData, userAddress]);

  const handleFormSubmit = ({
    name,
    lastName,
    phoneNumber,
    dateFirstLicensed,
    licenseExpirationDate,
    licenseState,
    address,
  }) => {
    dispatch(
      actions.updateUserInfo({
        name,
        lastName,
        phoneNumber,
        dateFirstLicensed: dateFirstLicensed
          ? moment(dateFirstLicensed).format()
          : '',
        licenseExpirationDate: licenseExpirationDate
          ? moment(licenseExpirationDate).format()
          : '',
        licenseState: licenseState,
        city: address.city,
        addressState: address.state,
        zipCode: address.zipCode,
        streetAddress: address.streetAddress,
      }),
    );
  };

  useEffect(() => {
    if (stripeUrlFetchStatus === 'success' && stripeUrl) {
      window.open(normalizeUrl(stripeUrl), '_blank');
      dispatch(actions.setStripeUrlFetchStatus('idle'));
    }
  }, [stripeUrlFetchStatus, stripeUrl, dispatch, actions]);

  const loadStripeUrl = () => {
    dispatch(actions.getStripeAccountUrl());
  };

  return (
    <GridContainer>
      <Grid item xs={12} md={7} order={{ xs: 2, md: 1 }}>
        <Card>
          <FormProvider {...form}>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <CardContent>
                <Typography variant={'h6'} pb={3}>
                  Personal information
                </Typography>
                <Stack
                  direction={'row'}
                  gap={2}
                  width={'100%'}
                  pb={2}
                  flexWrap={isMobile ? 'wrap' : 'nowrap'}
                >
                  <InputField
                    control={control}
                    name="name"
                    label="Name *"
                    rules={{ required: 'Please input your name' }}
                  />
                  <InputField
                    control={control}
                    name="lastName"
                    label="Last name *"
                    rules={{ required: 'Please input your last name' }}
                  />
                </Stack>
                <Stack
                  direction={'row'}
                  gap={2}
                  width={'100%'}
                  flexWrap={isMobile ? 'wrap' : 'nowrap'}
                >
                  <InputField
                    control={control}
                    name="phoneNumber"
                    label="Phone number *"
                    rules={{ required: 'Please input your phone number' }}
                  />
                  <TextField
                    fullWidth
                    label="Email address *"
                    value={userData?.email ? userData.email : ''}
                    disabled
                  />
                </Stack>
                <Typography py={2} variant={'subtitle1'}>
                  Home Address
                </Typography>
                <Stack
                  direction={'row'}
                  flexWrap={'wrap'}
                  gap={2}
                  width={'100%'}
                >
                  <Stack
                    gap={2}
                    flexGrow={1}
                    flexWrap={isMobile ? 'wrap' : 'nowrap'}
                  >
                    <InputField
                      control={control}
                      name="address.streetAddress"
                      label="Street Address *"
                      rules={{ required: 'Please input your street address' }}
                    />
                    <InputDropdown
                      name={'address.state'}
                      label="State *"
                      control={control}
                      options={States}
                      rules={{ required: 'Please select your address state' }}
                    />
                  </Stack>
                  <Stack
                    gap={2}
                    flexGrow={1}
                    flexWrap={isMobile ? 'wrap' : 'nowrap'}
                  >
                    <InputField
                      control={control}
                      name="address.zipCode"
                      label="Zip code *"
                      rules={{ required: 'Please input your zip code' }}
                    />
                    <InputField
                      control={control}
                      name="address.city"
                      label="City *"
                      rules={{ required: 'Please input your city' }}
                    />
                  </Stack>
                </Stack>
                <Typography variant={'h6'} py={2}>
                  Real Estate information
                </Typography>
                <Stack
                  direction={'row'}
                  gap={2}
                  width={'100%'}
                  pb={2}
                  flexWrap={isMobile ? 'wrap' : 'nowrap'}
                >
                  <TextField
                    fullWidth
                    label="Real Estate License #"
                    disabled
                    value={
                      userData?.licenseNumber ? userData.licenseNumber : ''
                    }
                  />
                  <InputDropdown
                    name={'licenseState'}
                    label="License State *"
                    control={control}
                    options={States}
                    disabled
                  />
                  <DatePickerInput
                    name="licenseExpirationDate"
                    label="License Expiration Date"
                    control={control}
                  />
                </Stack>
                <Stack
                  direction={'row'}
                  gap={2}
                  width={'100%'}
                  pb={2}
                  flexWrap={isMobile ? 'wrap' : 'nowrap'}
                ></Stack>
                <TextField
                  fullWidth
                  label="Primary Local Association"
                  value={
                    userData?.localAssociation ? userData.localAssociation : ''
                  }
                  disabled
                />
              </CardContent>
              <CardActions>
                <LoadingButton
                  variant={'contained'}
                  type="submit"
                  sx={{ mt: 3 }}
                  loading={userDataFetchStatus === 'loading'}
                >
                  Save changes
                </LoadingButton>
              </CardActions>
            </form>
          </FormProvider>
        </Card>
      </Grid>
      <Grid item xs={12} md={5} order={{ xs: 1, md: 2 }}>
        <Stack
          spacing={4}
          direction={{ xs: 'column', sm: 'row', md: 'column' }}
        >
          <Card sx={{ width: '100%' }}>
            <CardContent>
              <img src={StripeLogo} alt={'stripe logo'} />
              <Box>
                <Typography variant="h5" paddingY={2}>
                  Your payment details.
                </Typography>
                {userData?.stripeId ? (
                  <Typography>
                    Change or update your payment details for Pinnacle Realty
                    Advisors payments.
                  </Typography>
                ) : (
                  <Typography>
                    Need to update or change payment details? Contact us at{' '}
                    <Link href={'mailto:operations@pinnaclera.com'}>
                      operations@pinnaclera.com
                    </Link>
                  </Typography>
                )}
              </Box>
            </CardContent>
            <CardActions sx={{ p: 2 }}>
              <LoadingButton
                variant={'contained'}
                sx={{ mt: 3 }}
                loading={stripeUrlFetchStatus === 'loading'}
                disabled={!userData?.stripeId}
                onClick={loadStripeUrl}
              >
                Manage Payment Method
              </LoadingButton>
            </CardActions>
          </Card>
        </Stack>
      </Grid>
    </GridContainer>
  );
};

export default UserProfile;
