import styled, { keyframes, css } from 'styled-components';
const blur = keyframes`
  0%,
  90% {
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
  }
  50% {
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
  }
`;

interface IWrapperProps {
  isLoading: boolean;
}

export const Wrapper = styled.div<IWrapperProps>`
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.15s, transform 0.15s, filter 0.15s;
  ${props =>
    props.isLoading
      ? css`
          -webkit-filter: blur(2px);
          -moz-filter: blur(2px);
          -o-filter: blur(2px);
          -ms-filter: blur(2px);
          animation: ${blur} 1.5s ease 0s infinite;
        `
      : ''};

  &:hover {
    box-shadow: ${props =>
      props.isLoading ? 'none' : '0px 5px 10px rgba(0, 0, 0, 0.25)'};
    transform: ${props => (props.isLoading ? 'none' : 'translateY(-0.5rem)')};
  }
`;

export const LogoWrapper = styled.div`
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem !important;
`;

export const Logo = styled.img`
  width: 100%;
  height: 100%;
  max-height: 54px;
  max-width: 180px;
  object-fit: contain;
  mix-blend-mode: multiply;
`;

export const RatingWrapper = styled.div`
  display: flex !important;
  align-items: center;
`;

export const Star = styled.img`
  display: block;
  width: 18px;
  height: 17px;
  object-fit: contain;
  object-position: center bottom;
  margin-right: 7px;
`;
