import React from 'react';
import { Wrapper, SmallInner } from './Row.styles';

interface IProps {
  children: React.ReactNode;
  isSmall?: boolean;
  style?: React.CSSProperties;
}

const Row = ({ children, isSmall, style }: IProps) => {
  return (
    <Wrapper style={style}>
      {isSmall ? <SmallInner>{children}</SmallInner> : children}
    </Wrapper>
  );
};

export default Row;
