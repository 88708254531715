import React from 'react';
import { Logo } from './VendorsList.styles';
import Drawer from '@mui/material/Drawer';
import { IDeal } from 'app/containers/Deals/slice/types';
import { Widget } from '@typeform/embed-react';
import { usePrepareLocations } from 'app/hooks/usePrepareLocations';
import { usePrepareCategories } from 'app/hooks/usePrepareCategories';
import {
  Box,
  TableBody,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  Typography,
  Paper,
  alpha,
  LinearProgress,
  Toolbar,
  Button,
  Stack,
  IconButton,
} from '@mui/material';
import { pxToRem } from '../../../utils/helpers';
import StarIcon from '@mui/icons-material/Star';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useTheme } from '@mui/material/styles';
import RouteConstants from '../../routes';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { shortenText } from '../../../utils/helpers';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../../containers/UserSettings/slice/selectors';
import { IUser } from '../../queries/auth/types';

const TYPEFORM_ID = 'Haz1bf7T';
const DESCRIPTION_CHAR_COUNT = 100;

interface IProps {
  isLoading: boolean;
  deals: Array<IDeal> | null;
  isSpecial?: boolean;
  onFilterClick?: () => void;
  isDrawerVisible?: boolean;
  toggleVendorDrawer?: () => void;
  userData?: IUser;
}

interface HeadCell {
  id: string;
  label: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'vendor',
    label: 'Vendor',
  },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'services-offered',
    label: 'Services offered',
  },
  {
    id: 'location',
    label: 'Location',
  },
  {
    id: 'rating',
    label: 'Rating',
  },
];

const VendorsList = ({
  isLoading,
  deals,
  isSpecial,
  onFilterClick,
  isDrawerVisible,
  toggleVendorDrawer,
  userData,
}: IProps) => {
  const { search } = useLocation();
  const prepareLocations = usePrepareLocations();
  const prepareCategories = usePrepareCategories();
  const theme = useTheme();
  const history = useHistory();

  const onRowClick = (dealId: number) => {
    const path = `${generatePath(RouteConstants.deals.dealsInner, {
      dealId,
    })}${search}`;
    history.push(path);
  };

  return (
    <Paper sx={{ width: '100%', mb: 2, borderRadius: '10px 10px 0 0 ' }}>
      <Toolbar
        disableGutters
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          bgcolor: theme.palette.common.white,
        }}
      >
        <Button onClick={onFilterClick}>
          Filters
          <FilterListIcon sx={{ ml: 1 }} />
        </Button>
      </Toolbar>
      <TableContainer>
        <Table sx={{ minWidth: 850 }} aria-labelledby="table">
          <TableHead sx={{ backgroundColor: alpha('#1976D2', 0.08) }}>
            <TableRow>
              {headCells.map(headCell => (
                <TableCell key={headCell.id}>
                  <Typography
                    sx={{
                      textTransform: 'uppercase',
                      fontWeight: 600,
                      fontSize: pxToRem(11),
                      lineHeight: pxToRem(20),
                    }}
                  >
                    {headCell.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {deals &&
              deals?.length > 0 &&
              deals.map(deal => (
                <TableRow
                  hover
                  key={deal.vendor.id}
                  onClick={() => onRowClick(deal.vendor.id)}
                >
                  <TableCell scope="row" width={'15%'} align={'left'}>
                    {deal.vendor.logoUrl ? (
                      <Logo
                        src={deal.vendor.logoUrl}
                        alt={`${deal.vendor.companyName} logo`}
                      />
                    ) : (
                      <Typography>{deal.vendor.companyName}</Typography>
                    )}
                  </TableCell>
                  <TableCell scope="row" width={'45%'}>
                    {isSpecial && deal.vendor.clientValue
                      ? shortenText(
                          deal.vendor.clientValue,
                          DESCRIPTION_CHAR_COUNT,
                        )
                      : deal.vendor.shortDescription
                      ? shortenText(
                          deal.vendor.shortDescription,
                          DESCRIPTION_CHAR_COUNT,
                        )
                      : '-'}
                  </TableCell>
                  <TableCell scope="row" width={'15%'}>
                    {prepareCategories(deal.vendor.categories)}
                  </TableCell>
                  <TableCell scope="row" width={'20%'}>
                    {prepareLocations(
                      Boolean(deal.vendor.countryWide),
                      deal.locationsFullNames,
                    )}
                  </TableCell>
                  <TableCell scope="row" width={'auto'}>
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      direction="row"
                    >
                      {deal.ratings.average}
                      <StarIcon color={'primary'} />
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isLoading ? (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      ) : (
        (!deals || deals?.length === 0) && (
          <Stack sx={{ py: 3, px: 1 }} alignItems="center">
            <Stack
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ maxWidth: { xs: '100%', md: '60%' } }}
            >
              <Typography color={theme.palette.common.black} fontSize={20}>
                No results
              </Typography>
              <Typography align={'center'}>
                We are working on getting more vendors for you. Do You have a
                trusted service provider as your partner? Share with us his
                information and we will add him to the Realthy platform.
              </Typography>
              <Box>
                <Button
                  onClick={toggleVendorDrawer}
                  variant={'outlined'}
                  size={'large'}
                >
                  Suggest your vendor
                </Button>
              </Box>
            </Stack>
          </Stack>
        )
      )}
      {/*TODO: CREATE a component for typeform drawer */}
      <Drawer
        anchor={'right'}
        open={isDrawerVisible}
        onClose={toggleVendorDrawer}
        PaperProps={{
          sx: {
            width: 360,
          },
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            right: '20px',
            top: '10px',
            zIndex: '99',
          }}
          onClick={toggleVendorDrawer}
          edge={'end'}
        >
          <CloseIcon />
        </IconButton>
        {userData && (
          <Widget
            id={TYPEFORM_ID}
            style={{ width: '100%', height: '100%' }}
            tracking={{
              email: userData.email,
              firstname: userData.name,
              lastname: userData.lastName,
            }}
          />
        )}
      </Drawer>
    </Paper>
  );
};

export default VendorsList;
