import { IBoostObject, ICategory } from 'app/containers/Boosts/slice/types';
import { IDealObject, IGeo } from 'app/containers/Deals/slice/types';
import { fetchStatus } from '../../Global/slice/types';
import { ITeam } from '../../../queries/admin/types';

export type UserInnerPage = {
  isPublic: boolean;
  email?: string;
  phoneNumber?: string;
  profileUrl?: string;
  websiteLink?: string;
  linkedinLink?: string;
  instagramLink?: string;
  facebookLink?: string;
  twitterLink?: string;
  tiktokLink?: string;
  about?: string;
  profilePicture?: string;
  coverImage: string;
};

export type IUserInnerPageImageUrl = {
  type: 'cover' | 'profile';
  image: string | null;
};

export interface IUpdateUserPublicData {
  userId: string;
  data: UserInnerPage;
}

export interface IUsersObject {
  id: number;
  email: string;
  name: string;
  lastName: string;
  licenseState: string;
  licenseNumber: string;
  gDriveLink: string;
  test: boolean;
  uuid: string;
  teamLead: boolean;
  active: boolean;
}

export interface ITeamObject {
  id?: number;
  uuid: string;
  plan: string;
  name: string;
  isPublic: boolean;
  website: string;
  logo: string;
  coupon?: {
    id?: number | null;
    name?: string;
  };
  users?: Array<IUsersObject>;
}

export interface IUserObject {
  id: number;
  email: string;
  name: string;
  lastName: string;
  licenseState: string;
  licenseNumber: string;
  gDriveLink: string;
  test: boolean;
  active: boolean;
  uuid: string;
  stripeId?: string;
  dateFirstLicensed?: string;
  licenseExpirationDate?: string;
  phoneNumber: string;
  planCode?: string;
  localAssociation?: string;
  teamLead: boolean;
  team?: {
    id?: number | null;
    name?: string;
    isPublic?: boolean;
    logo?: string;
    website?: string;
  };
  innerPage?: UserInnerPage;
}

export interface IUserWithAddressObject {
  user: IUserObject;
  address?: {
    id?: number;
    streetAddress?: string;
    state?: string;
    zipCode?: string;
    city?: string;
  };
}

export interface IMyTeam {
  teamInfo: ITeam;
  inviteUrl: string;
  members: Array<IUserObject>;
}

export interface IAdminState {
  isLoading: boolean;
  isCategoriesLoading: boolean;
  isLocationsLoading: boolean;
  isSaving: boolean;
  boosts: Array<IBoostObject> | null;
  boost: IBoostObject | null;
  deals: Array<IDealObject> | null;
  deal: IDealObject | null;
  dealsCategories: Array<ICategory> | null;
  boostsCategories: Array<ICategory> | null;
  locations: Array<IGeo> | null;
  users: Array<IUsersObject> | null;
  teams: Array<ITeamObject> | null;
  selectedUser: IUserWithAddressObject | null;
  myTeam: IMyTeam | null;
  selectedTeam: ITeamObject | null;
  userStripeUrl?: string;
  userStripeUrlFetchStatus: fetchStatus;
  userDocusignGenerationStatus: fetchStatus;
  userDataFetchStatus: fetchStatus;
  teamDataFetchStatus: fetchStatus;
}

export interface IBoostData {
  companyName: string;
  title: string;
  shortDescription: string;
  description?: string;
  content?: string;
  logoUrl: string;
  coverUrl?: string;
  featuredImageDesktopUrl?: string | null;
  featuredImageMobileUrl?: string | null;
  partnerDescription?: string;
  category?: number;
  rank?: number;
  top?: boolean;
  residential?: boolean;
  commercial?: boolean;
  active?: boolean;
}

export interface IUserData {
  name: string;
  lastName?: string;
  email: string;
  licenseState?: string;
  test?: boolean;
  uuid?: string;
  gDriveLink?: string;
  licenseNumber?: string;
  localAssociation?: string;
  dateFirstLicensed?: string;
  licenseExpirationDate?: string;
  streetAddress?: string;
  addressState?: string;
  zipCode?: string;
  city?: string;
  phoneNumber?: string;
  planCode?: string;
  teamId?: number;
}

export interface ITeamData {
  uuid: string;
  name: string;
  isPublic: boolean;
  website: string;
  logo: string;
  couponId?: string;
  users?: Array<IUsersObject>;
}

export interface IUserEditParams {
  userId: string;
}
export interface ITeamEditParams {
  teamId: string;
}

export interface IUploadUserPayload {
  userId: string;
  data: IUserData;
}
export interface ICreateUserPayload {
  data: IUserData;
}

export interface IUpdateTeamPayload {
  teamId: string;
  data: ITeamData;
}

export interface ICreateTeamPayload {
  data: ITeamData;
}

export interface IDealData {
  companyName: string;
  title: string;
  shortDescription: string;
  clientValue?: string;
  description?: string;
  logoUrl: string;
  partnerDescription?: string;
  categories: Array<number>;
  affiliateUrl?: string;
  phoneNumber?: string;
  email?: string;
  facebook?: string;
  geo: Array<string>;
  countryWide: boolean;
  active?: boolean;
  top?: boolean;
  rank?: number;
}
export interface ISaveBoostPayload {
  boostId: number;
  data: IBoostData;
  logoFile: File;
  coverFile?: File;
  featuredImageDesktopFile?: File;
  featuredImageMobileFile?: File;
}

export interface IUploadDealPayload {
  dealId: number;
  data: IDealData;
  logoFile: File;
}

export interface ICreateDealPayload {
  data: IDealData;
  logoFile: File;
}

export interface ICreateBoostPayload {
  data: IBoostData;
  logoFile: File;
}

export interface IBoostEditParams {
  boostId: string;
}

export interface IDealEditParams {
  dealId: string;
}

export interface IUpdateUserInnerPageImageUrl {
  userId: string;
  data: IUserInnerPageImageUrl;
}

export interface IUpdateUserProfilePicture {
  userId: string;
  profilePicture: File | null;
}

export enum BoostForm {
  IsActive = 'isActive',
  Category = 'category',
  CompanyName = 'companyName',
  Title = 'title',
  ShortDescription = 'shortDescription',
  Content = 'shortDescription',
  Description = 'description',
  LogoObj = 'logoObj',
  CoverObj = 'coverObj',
  FeaturedImageDesktop = 'featuredImageDesktopObj',
  FeaturedImageMobile = 'featuredImageMobileObj',
  Terms = 'terms',
  PromoCode = 'promoCode',
  CtaText = 'ctaText',
  AffiliateUrl = 'affiliateUrl',
  Rank = 'rank',
  Top = 'top',
  Active = 'active',
  Commercial = 'commercial',
  Residential = 'residential',
}

export enum DealForm {
  Categories = 'categories',
  Geo = 'geo',
  CountryWide = 'countryWide',
  CompanyName = 'companyName',
  Title = 'title',
  ShortDescription = 'shortDescription',
  ClientValue = 'clientValue',
  Description = 'description',
  LogoObj = 'logoObj',
  AffiliateUrl = 'affiliateUrl',
  PhoneNumber = 'phoneNumber',
  Email = 'email',
  Facebook = 'facebook',
  Active = 'active',
  Top = 'top',
  Rank = 'rank',
}

export enum QuillEditor {
  EmptyHtml = '<p><br></p>',
}
