import { selectUsers, selectIsLoading } from '../../slice/selectors';
import React, { useEffect, useMemo } from 'react';
import { Header, Table, UserListRow, UserTableHeader } from '../../styles';
import { generatePath } from 'react-router-dom';
import Typography from 'app/components/Typography';
import RouteConstants from 'app/routes';
import { useDispatch, useSelector } from 'react-redux';
import { Empty, Skeleton } from 'antd';
import { useQuery } from 'app/hooks/useQuery';
import { Link } from 'app/components/Clicks';
import ScrollToTopOnMount from 'app/components/ScrollToTopOnMount';
import { useAdminSlice } from '../../slice';

const Users = () => {
  const users = useSelector(selectUsers);
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch();
  const { actions } = useAdminSlice();
  const query = useQuery();

  useEffect(() => {
    dispatch(actions.getUsers());
  }, [actions, dispatch, query]);

  const tableRows = useMemo(() => {
    return users?.map(user => (
      <UserListRow
        key={`user-${user.id}`}
        to={generatePath(RouteConstants.admin.editUser, {
          userId: user.id,
        })}
        loading={isLoading ? 'true' : 'false'}
        active={user.active ? 'true' : 'false'}
      >
        <Typography type="div">
          {user.name} {user.lastName}
        </Typography>
        <Typography type="div">{user.email}</Typography>
        <Typography type="div">{user.uuid}</Typography>
      </UserListRow>
    ));
  }, [users, isLoading]);

  return (
    <div>
      <ScrollToTopOnMount />
      <Header>
        <Typography type="h" level={4}>
          All users
        </Typography>
        <Link to={RouteConstants.admin.addUser} common={{ small: 'true' }}>
          Add new
        </Link>
      </Header>
      <Table>
        <UserTableHeader>
          <Typography type="div" bodyVariation="bodySmall">
            Name
          </Typography>
          <Typography type="div" bodyVariation="bodySmall">
            Email
          </Typography>
          <Typography type="div" bodyVariation="bodySmall">
            UUID
          </Typography>
        </UserTableHeader>
        {isLoading && (!users || users?.length === 0) ? (
          <Skeleton active style={{ padding: '1.6rem 2.4rem' }} />
        ) : users && users.length > 0 ? (
          tableRows
        ) : (
          <Empty
            description="No vendors found"
            style={{ padding: '1.6rem 2.4rem' }}
          />
        )}
      </Table>
    </div>
  );
};

export default Users;
