import axios, { AxiosInstance } from 'axios';
import { API_URL } from '../../utils/constants';

export const instance = axios.create({
  withCredentials: true,
  baseURL: API_URL,
  headers: { 'Content-Type': 'application/json' },
});

export const api = {
  get: function apiGet(url: string, options: {} = {}) {
    return instance.get(url, options);
  } as AxiosInstance['get'],
  post: function apiPost(url: string, data: {}, options: {} = {}) {
    return instance.post(url, data, options);
  } as AxiosInstance['post'],
  put: function apiPut(url: string, data?: {}, options: {} = {}) {
    return instance.put(url, data, options);
  } as AxiosInstance['put'],
  patch: function apiPatch(url: string, data: {}, options: {} = {}) {
    return instance.patch(url, data, options);
  } as AxiosInstance['patch'],
  delete: function apiDelete(url: string, options: {} = {}) {
    return instance.delete(url, options);
  } as AxiosInstance['delete'],
};

export default api;
