import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount';
import { Header } from '../../Admin/styles';
import { InputField } from '../../../components/FormInputs';
import { FormProvider } from 'antd/es/form/context';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useUserProfileSlice } from '../slice';
import { selectMyTeam, selectUserInfo } from '../slice/selectors';
import useResponsive from '../../../hooks/useResponsive';
import { LoadingButton } from '@mui/lab';
import {
  MembersTableHeader,
  MemberTableRow,
  Logo,
  MemberTableCell,
} from './styles';
import { selectIsLoading } from '../../Admin/slice/selectors';
import { CheckCircleOutlined } from '@mui/icons-material';
import palette from '../../../../theme/pallete';
import Avatar from '../../../../assets/images/Avatar.png';
import { Drawer, Empty } from 'antd';
import {
  useGetMyTeam,
  useInviteTeamMember,
} from '../../../queries/team/useTeamQueries';
import { useSnackBar } from '../../../components/CustomSnackbar';
import { Widget } from '@typeform/embed-react';
import PublicTeamInfo from '../../../components/PublicTeamInfo';

type Inputs = {
  inviteUrl: string;
  email: string;
  name: string;
};

const YourTeam: React.FC = () => {
  const isMobile = useResponsive('down', 565);
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { actions } = useUserProfileSlice();
  const { data: teamData } = useGetMyTeam();
  const isLoading = useSelector(selectIsLoading);
  const userData = useSelector(selectUserInfo);
  const form = useForm<Inputs>({
    defaultValues: {
      email: '',
      name: '',
      inviteUrl: '',
    },
  });
  const snackbar = useSnackBar();
  const { setError, handleSubmit, control, reset } = form;

  useEffect(() => {
    if (!teamData) {
      dispatch(actions.getMyTeam());
    }
    reset({
      inviteUrl: teamData?.inviteUrl,
    });
  }, [actions, dispatch, teamData]);

  const { mutate: sendInvite } = useInviteTeamMember({
    options: {
      onSuccess: () => {
        snackbar.showSnackBar(
          <Typography fontSize={'inherit'} color={'inherit'}>
            Invitation sent successfully!
          </Typography>,
          'success',
        );
      },
      onError: error => {
        if (error && error.response?.data.code === 1) {
          setError('email', {
            type: 'manual',
          });
          snackbar.showSnackBar(
            <Typography fontSize={'inherit'} color={'inherit'}>
              This email address is already registered with PRA.
            </Typography>,
            'error',
          );
        }
      },
    },
  });

  if (!userData?.teamLead) {
    return (
      <Box width={'100%'} sx={{ paddingBottom: '2rem' }}>
        <ScrollToTopOnMount />
        <Card elevation={2} sx={{ position: 'relative' }}>
          <CardContent>
            <Stack
              direction={'row'}
              gap={2}
              width={'100%'}
              pb={2}
              flexWrap={isMobile ? 'wrap' : 'nowrap'}
              justifyContent={'center'}
            >
              <Empty
                description={
                  <Typography sx={{ pb: 1 }} variant="body1">
                    If you would like a Team Name please fill out this{' '}
                    <Link
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        setIsDrawerVisible(true);
                      }}
                    >
                      form
                    </Link>
                    .
                  </Typography>
                }
                style={{ padding: '1.6rem 2.4rem' }}
              />
            </Stack>
          </CardContent>
        </Card>

        <Drawer
          placement="right"
          visible={isDrawerVisible}
          onClose={() => setIsDrawerVisible(false)}
          width={360}
        >
          {isDrawerVisible && (
            <Widget
              id={'xyvJA3uW'}
              style={{ width: '100%', height: '100%' }}
              tracking={{
                email: userData?.email || '',
                firstname: userData?.name || '',
                lastname: userData?.lastName || '',
              }}
            />
          )}
        </Drawer>
      </Box>
    );
  }

  return (
    <>
      <Container maxWidth="xl">
        <Box width={'100%'} sx={{ paddingBottom: '2rem' }}>
          <ScrollToTopOnMount />
          {teamData?.teamInfo && <PublicTeamInfo data={teamData.teamInfo} />}
          <FormProvider {...form}>
            <form onSubmit={handleSubmit(data => sendInvite(data))}>
              <Card elevation={2} sx={{ position: 'relative' }}>
                <CardContent>
                  <Header>
                    <Typography sx={{ pb: 1 }} variant="h6">
                      Invite Team Member
                    </Typography>
                  </Header>

                  <Stack
                    direction={'row'}
                    gap={2}
                    width={'100%'}
                    pb={2}
                    flexWrap={isMobile ? 'wrap' : 'nowrap'}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <InputField
                          control={control}
                          name="name"
                          label="Name *"
                          rules={{
                            required: 'Please input name',
                          }}
                        />
                        <InputField
                          control={control}
                          name="email"
                          label="Email *"
                          textFieldProps={{ sx: { marginTop: '16px' } }}
                          rules={{
                            required: 'Please input email',
                            pattern: {
                              value:
                                /^[\w-]+(\+[\w-]+)*(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
                              message: 'Not valid email address',
                            },
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Button
                          variant={'outlined'}
                          onClick={() => setIsDrawerVisible(true)}
                        >
                          Request new team/group name
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <LoadingButton
                          variant={'contained'}
                          loading={false}
                          type={'submit'}
                        >
                          Send Invite
                        </LoadingButton>
                      </Grid>
                      <Grid item xs={12}>
                        <InputField
                          control={control}
                          name={'inviteUrl'}
                          label={'Link to share'}
                          textFieldProps={{
                            sx: { mt: 2 },
                            disabled: true,
                          }}
                          withCopyButton
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                </CardContent>
              </Card>
            </form>
          </FormProvider>
        </Box>
      </Container>
      <Container maxWidth="xl" sx={{ pb: 6 }}>
        <Table component={Paper}>
          <MembersTableHeader>
            <tr>
              <MemberTableCell width={'8%'} />
              <MemberTableCell width={'33%'}>
                <Typography variant="body2" sx={{ fontWeight: 'bolder' }}>
                  NAME
                </Typography>
              </MemberTableCell>
              <MemberTableCell width={'44%'}>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  EMAIL
                </Typography>
              </MemberTableCell>
              <MemberTableCell width={'15%'} align={'center'}>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  Team Leader
                </Typography>
              </MemberTableCell>
            </tr>
          </MembersTableHeader>
          <TableBody>
            {teamData?.members?.map(member => (
              <MemberTableRow
                key={`team-member-${member.id}`}
                loading={isLoading ? 'true' : 'false'}
              >
                <MemberTableCell>
                  <Logo src={member.innerPage?.profilePicture ?? Avatar} />
                </MemberTableCell>
                <MemberTableCell>
                  <Typography variant="body2">
                    {member.name} {member.lastName}
                  </Typography>
                </MemberTableCell>
                <MemberTableCell>
                  <Typography variant="body2">{member.email}</Typography>
                </MemberTableCell>
                <MemberTableCell align={'center'}>
                  {member.teamLead && (
                    <CheckCircleOutlined sx={{ color: palette.primary.main }} />
                  )}
                </MemberTableCell>
              </MemberTableRow>
            ))}
          </TableBody>
        </Table>
      </Container>
      <Drawer
        placement="right"
        visible={isDrawerVisible}
        onClose={() => setIsDrawerVisible(false)}
        width={360}
      >
        {isDrawerVisible && (
          <Widget
            id={'xyvJA3uW'}
            style={{ width: '100%', height: '100%' }}
            tracking={{
              email: userData.email || '',
              firstname: userData.name || '',
              lastname: userData.lastName || '',
            }}
          />
        )}
      </Drawer>
    </>
  );
};

export default YourTeam;
