import { Theme } from '@mui/material';

export default function Step(theme: Theme) {
  return {
    MuiStep: {
      styleOverrides: {
        root: {
          '& .Mui-active': {
            '& .MuiStepIcon-root': {
              color: 'transparent',
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: '50px',
            },
            '& .MuiStepIcon-text': {
              fill: theme.palette.primary.main,
            },
          },
        },
      },
    },
  };
}
