const lightTheme = {
  green: {
    primary: '#5CE0D8',
    secondary: '#409D97',
    light: '#AEF0EC',
    support: '#4AB3AD',
  },
  grey: {
    primary: '#323F4B',
    secondary: '#262626',
    dark: '#595959',
    light: '#7B8794',
    disabled: '#8C8C8C',
    background: '#FAFAF8',
  },
  yellow: { primary: '#ffcf42', secondary: '#FFDD7B', dark: '#EDB100' },
  red: { primary: '#F5222D', secondary: '#CF1322' },
  blue: { primary: '#056BA4', light: '#5298D5', dark: '#004074' },
  white: '#FFFFFF',
};

export type Theme = typeof lightTheme;

export const themes = {
  light: lightTheme,
};
