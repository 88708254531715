import * as React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  generatePath,
} from 'react-router-dom';
import { withSidebar } from '../../mainRouter';
import RouteConstants from '../../routes';
import Login from './pages/Login/Login';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import NewPassword from './pages/NewPassword/NewPassword';
import { SharingQueryParams } from 'app/containers/Boosts/slice/types';
import ScrollToTop from 'app/components/ScrollToTop';
import { AuthQueryParams } from '../../queries/auth/types';
import { useEffect } from 'react';
import { useStore } from '../../hooks/useStore';
import Registration from '../Registration';

export function AuthorizationRouter() {
  const location = useLocation();
  const history = useHistory();
  const isAuthorized = useStore(s => !!s.user?.uuid);

  // Redirect to link from query parameters when user is authorized
  useEffect(() => {
    if (isAuthorized) {
      const query = new URLSearchParams(location.search);
      const boostId = query.get(SharingQueryParams.BoostId);
      const redirect = query.get(AuthQueryParams.redirect);

      if (boostId) {
        history.push(
          generatePath(RouteConstants.boosts.boostInner, {
            boostId: boostId,
          }),
        );
      }
      // if redirect is not empty, redirect to it otherwise redirect to dashboard (except registration page)
      if (redirect) {
        history.push(redirect);
      } else if (
        !location.pathname.includes(RouteConstants.auth.registration)
      ) {
        history.push(RouteConstants.myResources.index);
      }
    }
  }, [isAuthorized, history, location.search, location.pathname]);

  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route
          path={RouteConstants.auth.login}
          component={withSidebar(Login)}
        />
        <Route
          path={RouteConstants.auth.resetPassword}
          component={withSidebar(ResetPassword)}
        />
        <Route
          path={RouteConstants.auth.newPassword}
          component={withSidebar(NewPassword)}
        />
        <Route
          path={RouteConstants.auth.registration + '/:plan'}
          component={Registration}
        />
        <Route path="*">
          <Redirect to={RouteConstants.auth.login} />
        </Route>
      </Switch>
    </>
  );
}
