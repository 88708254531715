import React from 'react';
import { IServiceInnerPricing } from '../../queries/services/types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import {
  AdditionalPriceText,
  Price,
  PriceTypeWrapper,
  TextWrapper,
} from './styles';
import parse from 'html-react-parser';
import { alpha, useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { useTrackAcEvent } from '../../queries/services/useServicesQueries';
import { useSnackBar } from '../CustomSnackbar';
import { styled } from '@mui/system';
import CopyText from '../CopyText';

interface IProps {
  price: IServiceInnerPricing;
}

const WrapperCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 100%;

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const OnlineToolPriceCard = ({ price }: IProps) => {
  const {
    id,
    name,
    description,
    type,
    amount,
    additionalPricing,
    additionalText,
    ctaText,
    ctaUrl,
  } = price;
  const theme = useTheme();
  const snackbar = useSnackBar();
  const { mutate, isLoading } = useTrackAcEvent({
    options: {
      onSuccess: () => {
        if (!ctaUrl) {
          snackbar.showSnackBar(
            `Thank you! We will get in touch with you regarding this service`,
            'success',
          );
        }
      },
    },
  });

  return (
    <WrapperCard
      sx={{
        backgroundColor: '#E3F2FD',
      }}
    >
      <CardContent sx={{ height: '100%' }}>
        <Stack sx={{ height: '100%' }}>
          <Stack flexGrow={1} justifyContent="space-between">
            <Box>
              <Typography variant="h5" mb={1}>
                {name}
              </Typography>
              {description && <TextWrapper>{parse(description)}</TextWrapper>}
            </Box>
            <Stack direction="row" alignItems="flex-end" justifySelf="end">
              {type === 'amount' ? (
                <Price>
                  <PriceTypeWrapper>$</PriceTypeWrapper>
                  {amount}
                </Price>
              ) : type === 'percentage' ? (
                <Price>
                  {amount}
                  <PriceTypeWrapper>%</PriceTypeWrapper>
                </Price>
              ) : (
                <Price>{amount}</Price>
              )}
              <AdditionalPriceText>{additionalText}</AdditionalPriceText>
            </Stack>
          </Stack>
          <Stack justifyContent={'space-between'} flexGrow={1}>
            {additionalPricing && (
              <Stack gap={1}>
                {additionalPricing.map((item, index) => (
                  <Box
                    key={index}
                    borderRadius={'10px'}
                    bgcolor={alpha('#1976D2', 0.08)}
                    p={0.5}
                  >
                    <Typography variant={'body2'}>{item}</Typography>
                  </Box>
                ))}
              </Stack>
            )}
            {price.promoCode && (
              <Stack mt={1}>
                <CopyText copyText={price.promoCode} />
              </Stack>
            )}
            <Stack height={'100%'} justifyContent={'flex-end'}>
              {ctaUrl ? (
                <Button
                  sx={{
                    mt: 3,
                    mb: 2,
                    '&:hover': {
                      color: theme.palette.common.white,
                    },
                  }}
                  variant={'contained'}
                  type={'a'}
                  target={'_blank'}
                  href={ctaUrl}
                  onClick={() => price.acEventName && mutate({ id: id })}
                >
                  {ctaText}
                </Button>
              ) : (
                <LoadingButton
                  sx={{ mt: 3, mb: 2 }}
                  variant={'contained'}
                  onClick={() => price.acEventName && mutate({ id: id })}
                  loading={isLoading}
                >
                  {ctaText}
                </LoadingButton>
              )}
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </WrapperCard>
  );
};

export default OnlineToolPriceCard;
