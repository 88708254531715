import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from 'utils/PrivateRoute';
import { AuthorizationRouter } from './containers/Authorization';
import Boosts from './containers/Boosts';
import Deals from './containers/Deals';
import Admin from './containers/Admin';
import Wiki from './containers/Wiki';
import Onboarding from './containers/Onboarding';
import Sidebar from './components/Sidebar';
import RouteConstants from './routes';
import UserSettings from './containers/UserSettings';
import EducationTools from './containers/OnlineTools/Education';
import GeneralTools from './containers/OnlineTools/GeneralTools';
import BoostInner from './containers/Boosts/pages/BoostInner/BoostInner';
import MyResources from './containers/OnlineTools/MyResources';
import GettingPaid from './containers/GettingPaid';

export const withSidebar =
  <P extends { filter?: object }>(Component: React.FC<P>, initialProps?: P) =>
  (props: P) => {
    const filter = initialProps?.filter || props.filter;

    return (
      <Sidebar>
        <Component {...initialProps} {...props} filter={filter} />
      </Sidebar>
    );
  };

export function MainRouter() {
  return (
    <Switch>
      <Route path={RouteConstants.auth.index} component={AuthorizationRouter} />
      <PrivateRoute
        path={RouteConstants.boosts.index}
        component={withSidebar(Boosts)}
      />
      <PrivateRoute
        path={RouteConstants.boosts.commercialInner}
        component={withSidebar(BoostInner)}
      />
      <PrivateRoute
        path={RouteConstants.boosts.residentialInner}
        component={withSidebar(BoostInner)}
      />
      <PrivateRoute
        path={RouteConstants.boosts.commercial}
        component={withSidebar(GeneralTools, {
          filter: {
            residential: '0',
            commercial: '1',
          },
          innerPageRoute: RouteConstants.boosts.commercialInner,
          listRoute: RouteConstants.boosts.commercial,
        })}
      />
      <PrivateRoute
        path={RouteConstants.boosts.residential}
        component={withSidebar(GeneralTools, {
          filter: {
            residential: '1',
            commercial: '0',
          },
          innerPageRoute: RouteConstants.boosts.residentialInner,
          listRoute: RouteConstants.boosts.residential,
        })}
      />
      <PrivateRoute
        path={RouteConstants.myResources.inner}
        component={withSidebar(BoostInner)}
      />
      <PrivateRoute
        path={RouteConstants.education.inner}
        component={withSidebar(BoostInner)}
      />
      <PrivateRoute
        path={RouteConstants.myResources.index}
        component={withSidebar(MyResources, {
          filter: {
            category: 'membership-tools',
          },
        })}
      />
      <PrivateRoute
        path={RouteConstants.education.index}
        component={withSidebar(EducationTools, {
          filter: {
            category: 'learning',
          },
        })}
      />
      <PrivateRoute
        path={RouteConstants.boosts.education.continuing.index}
        component={withSidebar(EducationTools, {
          filter: {
            category: 'continuing-education',
          },
        })}
      />
      <PrivateRoute
        path={RouteConstants.boosts.education.coaching.index}
        component={withSidebar(EducationTools, {
          filter: {
            category: 'coaching',
          },
        })}
      />
      <PrivateRoute
        path={RouteConstants.deals.index}
        component={withSidebar(Deals)}
      />
      <PrivateRoute
        path={RouteConstants.admin.index}
        component={withSidebar(Admin)}
      />
      <PrivateRoute
        path={RouteConstants.wiki.index}
        component={withSidebar(Wiki)}
      />
      <PrivateRoute
        path={RouteConstants.onboarding.index}
        component={withSidebar(Onboarding)}
      />
      <PrivateRoute
        path={RouteConstants.getPaid.index}
        component={withSidebar(GettingPaid)}
      />
      <PrivateRoute
        exact
        path={RouteConstants.profile.documents}
        component={withSidebar(UserSettings, {
          tab: RouteConstants.profile.documents,
        })}
      />
      <PrivateRoute
        exact
        path={RouteConstants.profile.index}
        component={withSidebar(UserSettings, {
          tab: RouteConstants.profile.index,
        })}
      />
      <PrivateRoute
        exact
        path={RouteConstants.profile.public}
        component={withSidebar(UserSettings, {
          tab: RouteConstants.profile.public,
        })}
      />
      <PrivateRoute
        exact
        path={RouteConstants.profile.team}
        component={withSidebar(UserSettings, {
          tab: RouteConstants.profile.team,
        })}
      />
      <Redirect from="/" to={RouteConstants.myResources.index} />
      <Route path="*">
        <Redirect to={RouteConstants.myResources.index} />
      </Route>
    </Switch>
  );
}
