import { request } from 'utils/request';
import { IDealRateData, IGetDealsData } from './slice/types';

const dealsApi = {
  getDeals: ({ page, category, city, specialOffers }: IGetDealsData) => {
    const query = new URLSearchParams();
    if (specialOffers) query.append('specialOffers', specialOffers.toString());
    if (page) query.append('page', page);
    if (category) query.append('category', category);
    if (city) query.append('city', city);

    return request({
      url: `vendors${query.toString() ? `?${query.toString()}` : ''}`,
    });
  },
  getDeal: (id: number) =>
    request({
      url: `vendors/${id}`,
    }),
  setDealRate: (dealId: number, data: IDealRateData) =>
    request({
      method: 'POST',
      url: `vendors/${dealId}/rate`,
      data,
    }),
};

export default dealsApi;
