import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  CardActions,
  CardContent,
  FormControlLabel,
  Link,
  Stack,
  Switch,
  Typography,
  Alert,
} from '@mui/material';
import { inputLinkValidation, shortenText } from '../../../utils/helpers';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { LoadingButton } from '@mui/lab';
import { InputField } from '../FormInputs';
import { UserInnerPage } from '../../containers/Admin/slice/types';
import useResponsive from '../../hooks/useResponsive';

const MAX_LINK_CHAR_COUNT = 100;
const MAX_CHAR_COUNT_ABOUT_INPUT = 2200;

type Inputs = {
  isPublic: boolean;
  email: string;
  phoneNumber: string;
  websiteLink: string;
  linkedinLink: string;
  instagramLink: string;
  facebookLink: string;
  twitterLink: string;
  tiktokLink: string;
  about: string;
};

interface Props {
  data?: UserInnerPage;
  handleFormSubmit: (formData: Inputs) => void;
  isLoading: boolean;
  type: 'USER' | 'ADMIN';
}

const PublicProfileForm: React.FC<Props> = ({
  data,
  handleFormSubmit,
  isLoading,
  type,
}) => {
  const isMobile = useResponsive('down', 565);

  const form = useForm<Inputs>({
    defaultValues: {
      isPublic: false,
      email: '',
      phoneNumber: '',
      websiteLink: '',
      linkedinLink: '',
      instagramLink: '',
      facebookLink: '',
      twitterLink: '',
      tiktokLink: '',
      about: '',
    },
  });

  const aboutCharCount = form.watch('about')?.length || 0;
  const isPublic = form.watch('isPublic');

  const { handleSubmit, control, reset } = form;

  useEffect(() => {
    if (data) {
      reset({
        isPublic: data.isPublic || false,
        email: data.email || '',
        phoneNumber: data.phoneNumber || '',
        websiteLink: data.websiteLink || '',
        linkedinLink: data.linkedinLink || '',
        instagramLink: data.instagramLink || '',
        facebookLink: data.facebookLink || '',
        twitterLink: data.twitterLink || '',
        tiktokLink: data.tiktokLink || '',
        about: data.about || '',
      });
    }
  }, [data, reset]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <CardContent>
          {type === 'USER' && (
            <>
              <Typography variant={'h6'} pb={1}>
                Profile URL
              </Typography>
              <Stack spacing={2}>
                {isPublic && data?.profileUrl ? (
                  <Link
                    href={data.profileUrl}
                    variant="subtitle1"
                    underline="none"
                    target={'_blank'}
                  >
                    {shortenText(data.profileUrl, MAX_LINK_CHAR_COUNT)}
                    <OpenInNewIcon sx={{ ml: 0.5, verticalAlign: 'top' }} />
                  </Link>
                ) : (
                  <Alert severity="warning" variant="outlined">
                    Your profile is not published. If you want to be published,
                    contact us at{' '}
                    <Link href={'mailto:success@pinnaclera.com'}>
                      success@pinnaclera.com
                    </Link>
                  </Alert>
                )}
              </Stack>
            </>
          )}
          {type === 'ADMIN' && (
            <>
              <Typography variant={'h6'} pb={1}>
                Make user info public
              </Typography>
              <Stack spacing={2}>
                <Controller
                  name={'isPublic'}
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Switch checked={field.value} {...field} />}
                      label="Show this user on website."
                    />
                  )}
                />
                {isPublic && data?.profileUrl && (
                  <Link
                    target="_blank"
                    href={data.profileUrl}
                    variant="subtitle1"
                    underline="none"
                  >
                    {shortenText(data.profileUrl, MAX_LINK_CHAR_COUNT)}
                    <OpenInNewIcon sx={{ ml: 0.5, verticalAlign: 'top' }} />
                  </Link>
                )}
              </Stack>
            </>
          )}
          <Typography variant={'h6'} pt={1} pb={2}>
            Contact
          </Typography>
          <Stack
            direction={'row'}
            gap={2}
            width={'100%'}
            pb={2}
            flexWrap={isMobile ? 'wrap' : 'nowrap'}
          >
            <Box width={'100%'}>
              <InputField
                control={control}
                name="email"
                label="Public email address"
              />
            </Box>
            <Box width={'100%'}>
              <InputField
                control={control}
                name="phoneNumber"
                label="Public phone number"
              />
            </Box>
          </Stack>
          <Typography variant={'h6'} pt={1} pb={2}>
            Social media
          </Typography>
          <InputField
            control={control}
            name="websiteLink"
            label="Website"
            rules={{
              validate: {
                inputLinkValidation: value =>
                  inputLinkValidation(value) || 'Please enter a valid URL',
              },
            }}
            textFieldProps={{ fullWidth: true, sx: { pb: 2 } }}
          />
          <Stack
            direction={'row'}
            gap={2}
            width={'100%'}
            pb={2}
            flexWrap={isMobile ? 'wrap' : 'nowrap'}
          >
            <InputField
              control={control}
              name="linkedinLink"
              label="LinkedIn"
              rules={{
                validate: {
                  inputLinkValidation: value =>
                    inputLinkValidation(value) || 'Please enter a valid URL',
                },
              }}
            />
            <InputField
              control={control}
              name="instagramLink"
              label="Instagram"
              rules={{
                validate: {
                  inputLinkValidation: value =>
                    inputLinkValidation(value) || 'Please enter a valid URL',
                },
              }}
            />
          </Stack>
          <Stack
            direction={'row'}
            gap={2}
            width={'100%'}
            pb={2}
            flexWrap={isMobile ? 'wrap' : 'nowrap'}
          >
            <InputField
              control={control}
              name="facebookLink"
              label="Facebook"
              rules={{
                validate: {
                  inputLinkValidation: value =>
                    inputLinkValidation(value) || 'Please enter a valid URL',
                },
              }}
            />
            <InputField
              control={control}
              name="twitterLink"
              label="Twitter"
              rules={{
                validate: {
                  inputLinkValidation: value =>
                    inputLinkValidation(value) || 'Please enter a valid URL',
                },
              }}
            />
          </Stack>
          <InputField
            control={control}
            name="tiktokLink"
            label="Tik Tok"
            rules={{
              validate: {
                inputLinkValidation: value =>
                  inputLinkValidation(value) || 'Please enter a valid URL',
              },
            }}
            textFieldProps={{
              sx: !isMobile ? { width: '50%', pr: 1 } : {},
            }}
          />
          <Typography variant={'h6'} pt={1} pb={2}>
            About
          </Typography>
          <InputField
            label="Bio Paragraph"
            control={control}
            name="about"
            textFieldProps={{
              multiline: true,
              rows: 10,
              inputProps: { maxLength: MAX_CHAR_COUNT_ABOUT_INPUT },
            }}
          />
          <Typography variant={'caption'}>
            {`${aboutCharCount} / ${MAX_CHAR_COUNT_ABOUT_INPUT}`}
          </Typography>
        </CardContent>
        <CardActions>
          <Stack direction="row" flexWrap={'wrap'} gap={'1rem'}>
            <LoadingButton
              variant={'contained'}
              type="submit"
              loading={isLoading}
            >
              Save changes
            </LoadingButton>
            <Button variant={'outlined'} onClick={() => reset()}>
              Cancel
            </Button>
          </Stack>
        </CardActions>
      </form>
    </FormProvider>
  );
};

export default PublicProfileForm;
