/**
 *
 * Login
 *
 */
import React, { useState } from 'react';
import RouteConstants from 'app/routes';
import { Link as RouterLink } from 'react-router-dom';
import {
  Container,
  Typography,
  Link,
  Stack,
  InputAdornment,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useForm, FormProvider } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useLogin } from '../../../../queries/auth/useAuthQueries';
import { useSetUserData } from '../../../../hooks/useStore';
import { ILoginData } from '../../../../queries/auth/types';
import { InputField } from '../../../../components/FormInputs';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// import GoogleLogin from 'react-google-login';
// import { useLoginWithSocials } from 'app/hooks/useLoginWithSocials';
// import { APP_ID_FACEBOOK_LOGIN, CLIENT_ID_GOOGLE_LOGIN } from 'utils/constants';
// import { svgIcons } from 'utils/svgIcons';
// import { Input, Form, Divider, Row, Col } from 'antd';
// const LOGIN_FORM_ID = 'loginForm';

type Inputs = {
  email: string;
  password: string;
};

const Login = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const updateUser = useSetUserData();
  // const loginWithSocials = useLoginWithSocials();
  // const [isGoogleDisabled, setIsGoogleDisabled] = useState<boolean>(false);

  const { mutate, isLoading } = useLogin({
    options: {
      onSuccess: data => {
        window.dataLayer.push({
          event: 'Login',
          userID: data[0].uuid,
        });
        updateUser(data[0]);
      },
      onError: error => {
        if (error && error.response?.status === 401) {
          setError('password', {
            type: 'manual',
            message: 'Invalid email address or password',
          });
        }
      },
    },
  });

  const form = useForm<Inputs>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { setError, handleSubmit, control } = form;

  const handleLoginSubmit = ({ email, password }: ILoginData) => {
    mutate({
      email,
      password,
    });
  };

  // const getAuthWithFacebook = response => {
  //   loginWithSocials(LoginSocials.facebook, response, boostId, redirect);
  // };
  //
  // const getAuthWithGoogle = response => {
  //   loginWithSocials(
  //     LoginSocials.google,
  //     response,
  //     boostId,
  //     redirect,
  //     setIsGoogleDisabled,
  //   );
  // };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container maxWidth="sm" sx={{ height: '100%', py: 3 }}>
      <Stack height={'100%'} justifyContent={'center'}>
        <Typography variant={'h5'} pb={{ xs: 3, sm: 5, md: 8 }}>
          Log in to PinnacleHQ
        </Typography>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(handleLoginSubmit)}>
            <Stack spacing={2} alignItems={'flex-end'} width={'100%'}>
              <InputField
                control={control}
                name="email"
                label="Email address"
                rules={{ required: 'Please input your email address' }}
              />
              <InputField
                control={control}
                name="password"
                label="Password"
                rules={{ required: 'Please input your password' }}
                textFieldProps={{
                  type: showPassword ? 'text' : 'password',
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </Stack>
            <Typography align={'right'}>
              <Link
                to={RouteConstants.auth.resetPassword}
                component={RouterLink}
              >
                Forgot password?
              </Link>
            </Typography>
            <LoadingButton
              variant={'contained'}
              type="submit"
              sx={{ mt: 3 }}
              fullWidth
              loading={isLoading}
            >
              Log in
            </LoadingButton>
          </form>
          <Typography pt={{ xs: 2, sm: 4, md: 6 }}>
            Don’t have an account? Contact us at{' '}
            <Link href={'mailto:success@pinnaclera.com'}>
              success@pinnaclera.com
            </Link>
          </Typography>
        </FormProvider>
        {/*  <FormCard>*/}
        {/*    <Form*/}
        {/*      name="login"*/}
        {/*      form={form}*/}
        {/*      id={LOGIN_FORM_ID}*/}
        {/*      onFinish={handleLoginSubmit}*/}
        {/*      initialValues={{*/}
        {/*        rememberMe: false,*/}
        {/*      }}*/}
        {/*      autoComplete="off"*/}
        {/*      layout="vertical"*/}
        {/*    >*/}
        {/*      <Form.Item*/}
        {/*        name={LoginFormEnum.email}*/}
        {/*        rules={[*/}
        {/*          {*/}
        {/*            required: true,*/}
        {/*            message: 'Please input your email',*/}
        {/*          },*/}
        {/*        ]}*/}
        {/*        label="Email"*/}
        {/*      >*/}
        {/*        <Input placeholder="Email" />*/}
        {/*      </Form.Item>*/}

        {/*      <Form.Item*/}
        {/*        name={LoginFormEnum.password}*/}
        {/*        rules={[*/}
        {/*          {*/}
        {/*            required: true,*/}
        {/*            message: 'Please input your password',*/}
        {/*          },*/}
        {/*        ]}*/}
        {/*        label="Password"*/}
        {/*      >*/}
        {/*        <Input.Password placeholder="Password" />*/}
        {/*      </Form.Item>*/}
        {/*      <Typography>*/}
        {/*        <Link href={RouteConstants.auth.resetPassword}>*/}
        {/*          Forgot password*/}
        {/*        </Link>*/}
        {/*      </Typography>*/}

        {/*      <Form.Item>*/}
        {/*        <Button*/}
        {/*          type="submit"*/}
        {/*          loading={isLoading ? 'true' : 'false'}*/}
        {/*          common={{ fullwidth: 'true', small: 'true' }}*/}
        {/*        >*/}
        {/*          Log in*/}
        {/*        </Button>*/}
        {/*      </Form.Item>*/}
        {/*    </Form>*/}
        {/*    /!* <Divider style={{ color: theme.grey.disabled }} plain>*/}
        {/*  or*/}
        {/*</Divider>*/}
        {/*<Row gutter={[16, 32]}>*/}
        {/*  <Col span={24}>*/}
        {/*    <GoogleLogin*/}
        {/*      clientId={CLIENT_ID_GOOGLE_LOGIN}*/}
        {/*      onSuccess={getAuthWithGoogle}*/}
        {/*      onFailure={getAuthWithGoogle}*/}
        {/*      render={renderProps => (*/}
        {/*        <Button*/}
        {/*          type="button"*/}
        {/*          onClick={renderProps.onClick}*/}
        {/*          disabled={isGoogleDisabled}*/}
        {/*          loading={isLoading ? 'true' : 'false'}*/}
        {/*          common={{*/}
        {/*            fullwidth: 'true',*/}
        {/*            small: 'true',*/}
        {/*            variation: 'secondary',*/}
        {/*            iconside: 'left',*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          {svgIcons.google()}*/}
        {/*          Log in with Google*/}
        {/*        </Button>*/}
        {/*      )}*/}
        {/*    />*/}
        {/*  </Col>*/}
        {/*  <Col span={24}>*/}
        {/*    <FacebookLogin*/}
        {/*      appId={APP_ID_FACEBOOK_LOGIN}*/}
        {/*      callback={getAuthWithFacebook}*/}
        {/*      isMobile={false}*/}
        {/*      render={renderProps => (*/}
        {/*        <Button*/}
        {/*          type="button"*/}
        {/*          onClick={renderProps.onClick}*/}
        {/*          loading={isLoading ? 'true' : 'false'}*/}
        {/*          common={{*/}
        {/*            fullwidth: 'true',*/}
        {/*            small: 'true',*/}
        {/*            variation: 'secondary',*/}
        {/*            iconside: 'left',*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          {svgIcons.loginFacebook()}*/}
        {/*          Log in with Facebook*/}
        {/*        </Button>*/}
        {/*      )}*/}
        {/*    />*/}
        {/*  </Col>*/}
        {/*</Row> *!/*/}
        {/*  </FormCard>*/}
      </Stack>
    </Container>
  );
};

export default Login;
