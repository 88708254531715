import React from 'react';
import { useCallback } from 'react';
import { Chip } from '@mui/material';

export const usePrepareLocations = () => {
  return useCallback(
    (countryWide: boolean, locations: Array<string> | undefined) => {
      if (countryWide) return <Chip label={'Nationwide'} />;
      return locations?.map((loc, index) => (
        <Chip key={`${loc}-${index}`} label={loc} sx={{ mt: 0.5, mr: 0.5 }} />
      ));
    },
    [],
  );
};
