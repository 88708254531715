import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state?.admin || initialState;

export const selectBoosts = createSelector(
  [selectSlice],
  state => state.boosts,
);

export const selectBoost = createSelector([selectSlice], state => state.boost);

export const selectDeals = createSelector([selectSlice], state => state.deals);

export const selectUsers = createSelector([selectSlice], state => state.users);

export const selectTeam = createSelector(
  [selectSlice],
  state => state.selectedTeam,
);
export const selectTeams = createSelector([selectSlice], state => state.teams);

export const selectUser = createSelector(
  [selectSlice],
  state => state.selectedUser,
);

export const selectUserInnerPageData = createSelector(
  [selectSlice],
  state => state.selectedUser?.user?.innerPage,
);

export const selectDeal = createSelector([selectSlice], state => state.deal);

export const selectDealsCategories = createSelector(
  [selectSlice],
  state => state.dealsCategories,
);

export const selectBoostsCategories = createSelector(
  [selectSlice],
  state => state.boostsCategories,
);

export const selectLocations = createSelector(
  [selectSlice],
  state => state.locations,
);

export const selectIsLoading = createSelector(
  [selectSlice],
  state => state.isLoading,
);

export const selectIsCategoriesLoading = createSelector(
  [selectSlice],
  state => state.isCategoriesLoading,
);

export const selectIsLocationsLoading = createSelector(
  [selectSlice],
  state => state.isLocationsLoading,
);

export const selectIsSaving = createSelector(
  [selectSlice],
  state => state.isSaving,
);

export const selectUserStripeUrlIsLoading = createSelector(
  [selectSlice],
  state => state.userStripeUrlFetchStatus,
);

export const selectUserStripeUrl = createSelector(
  [selectSlice],
  state => state.userStripeUrl,
);

export const selectUserDocusignIsGenerating = createSelector(
  [selectSlice],
  state => state.userDocusignGenerationStatus,
);

export const selectUserDataFetchStatus = createSelector(
  [selectSlice],
  state => state.userDataFetchStatus,
);

export const selectTeamDataFetchStatus = createSelector(
  [selectSlice],
  state => state.teamDataFetchStatus,
);
