type BoostFiltersType = {
  pagination: {
    total_pages: number;
    current_page: number;
    page_size: number;
    total: number;
  };
  category: string;
};

export interface IBoostsState {
  isLoading: boolean;
  boosts: IBoosts;
  tools: ISelectedBoosts;
  boost: IBoost | null;
}

export interface IBoosts {
  online_tools: Array<IBoost> | null;
  categories: Array<ICategory> | null;
  filters: BoostFiltersType;
}

export interface IAsset {
  id: number;
  thumbnailUrl?: string;
  imageUrl: string;
  type: string;
}

export interface IBoostObject {
  id: number;
  companyName: string;
  title: string;
  shortDescription?: string;
  description?: string;
  logoUrl: string;
  coverUrl?: string;
  featuredImageDesktopUrl?: string;
  featuredImageMobileUrl?: string;
  partnerDescription?: string;
  category?: ICategory;
  active?: boolean;
  rank?: number;
  top?: boolean;
  commercial?: boolean;
  residential?: boolean;
  assetsGallery: {
    images: IAsset[];
  };
  pricing: IServiceInnerPricing[];
}

export interface IServiceInnerPricing {
  id: number;
  name: string;
  description: string | null;
  type: 'amount' | 'percentage';
  amount: number;
  additionalPricing: string[];
  additionalText: string | null;
  ctaUrl: string | null;
  ctaText: string;
  featured: boolean;
}

export interface IBoost {
  onlineTool: IBoostObject;
  ratings: {
    average: number;
    count: number;
    my_rating: number;
  };
  sharingLink: string;
  relatedBoosts?: Array<IBoost> | null;
  clicks?: number;
}

export interface ICategory {
  id: number;
  slug?: string;
  icon?: string;
  name?: string;
  count?: number;
  parentId?: number | null;
  children?: Array<ICategory> | null;
}

export interface ISelectedBoosts {
  isLoading: boolean;
  online_tools: Array<IBoost> | null;
}

export interface IGetBoostsData {
  page?: string | null;
  category: string | null;
  specificCategory?: boolean;
  numberOfBoosts?: number;
}

export interface IBoostQueryParams {
  boostId: string;
}

export interface IBoostRateData {
  stars: number;
}

export interface IBoostRatePayload {
  boostId: number;
  data: IBoostRateData;
}

export enum BoostsQueryParams {
  Page = 'page',
  Category = 'category',
}

export enum SharingQueryParams {
  Referer = 'referer',
  BoostId = 'online-tool',
}

export interface ISetIsBoostsLoading {
  boostCategory?: string;
  isLoading: boolean;
}
