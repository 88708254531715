import React, { ReactNode } from 'react';
import { Typography, Box, Divider, Grid } from '@mui/material';

interface IHeaderText {
  title: string;
  subtitle?: ReactNode;
}

interface IPageHeader extends IHeaderText {
  withDivider?: boolean;
  children?: ReactNode;
  rightColumn?: any;
}

const GridContainer = props => (
  <Grid
    container
    justifyContent="space-between"
    alignItems="center"
    rowSpacing={3}
    columnSpacing={{ xs: 2, md: 3, lg: 4 }}
    {...props}
  />
);

const HeaderText = ({ title, subtitle }: IHeaderText) => (
  <Box sx={{ pt: 4 }}>
    <Typography sx={{ pb: 1, textTransform: 'capitalize' }} variant="h4">
      {title}
    </Typography>
    {subtitle}
  </Box>
);

const PageHeader = ({
  title,
  subtitle,
  children,
  rightColumn,
  withDivider,
}: IPageHeader) => {
  return (
    <>
      {rightColumn ? (
        <GridContainer>
          <Grid item xs={12} md={5} xl={7}>
            <HeaderText title={title} subtitle={subtitle} />
          </Grid>
          <Grid item>{rightColumn}</Grid>
        </GridContainer>
      ) : (
        <HeaderText title={title} subtitle={subtitle} />
      )}
      <Box sx={{ my: 2 }}>
        {withDivider && <Divider sx={{ mb: 2 }} />}
        {children}
      </Box>
    </>
  );
};

export default PageHeader;
