/* --- STATE --- */
import { UserInnerPage } from '../../containers/Admin/slice/types';

export interface AuthorizationState {
  user: IUser | null;
  initLoaded: boolean;
  isLoading: boolean;
  error: string | null;
  isNameUpdating: boolean;
}

export type AgentType = 'newAgent' | 'transferAgent' | 'inactiveAgent';

export interface IUser {
  id: number;
  uuid: string;
  email: string;
  name: string;
  lastName: string;
  admin: boolean;
  teamLead: boolean;
  features?: {
    vendors: boolean;
    vendors_automation: boolean;
    commissions: boolean;
  };
  agentType?: AgentType;
  source?: null;
  licenseState: string;
  gDriveLink?: string;
  phoneNumber?: string;
  dateFirstLicensed?: string;
  licenseExpirationDate?: string;
  localAssociation?: string;
  licenseNumber: string;
  stripeId?: string;
  planCode: string;
  createdAt?: Date;
  team?: {
    id: number;
    name: string;
    isPublic: boolean;
    logo: string;
    website: string;
  };
  innerPage?: UserInnerPage;
}

export interface IRegisterUserData {
  email: string;
  name: string;
  password: string;
  user_type: string;
  other_details?: string;
  referer?: number;
  aff_ebook?: boolean;
  state?: string;
  license_number: string;
}

export interface ILoginData {
  email: string;
  password: string;
}

export interface IInitiatePassResetData {
  email: string;
}

export interface IResetPassData {
  code: string;
  password: string;
}

export interface IChangePassData {
  code: string;
  password: string;
}

export enum AuthQueryParams {
  redirect = 'redirect',
}
