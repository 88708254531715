import { useCallback, useEffect } from 'react';

const useUtmParameters = () => {
  const captureUtmParameter = useCallback((parameterName: string) => {
    const params = new URLSearchParams(window.location.search);
    const cookieValue = params.get(parameterName);
    const domainPattern = /\.([^.]+?)\.([^.]+?)$/.exec(
      window.location.hostname,
    );
    const domain = domainPattern ? domainPattern[0] : 'localhost';

    if (cookieValue !== null) {
      const expirationTime = 2592000; // One month in seconds
      const date = new Date();
      const dateTimeNow = date.getTime();

      date.setTime(dateTimeNow + expirationTime * 1000); // Sets expiration time (Time now + one month)
      const utcString = date.toUTCString(); // Converts milliseconds to UTC time string
      // eslint-disable-next-line no-restricted-globals
      document.cookie = `${parameterName}=${cookieValue}; SameSite=None; Secure; expires=${utcString}; path=/; domain=${domain}`; // Sets cookie for all subdomains
    }
  }, []);

  useEffect(() => {
    captureUtmParameter('utm_source');
    captureUtmParameter('utm_campaign');
    captureUtmParameter('utm_medium');
  }, [captureUtmParameter]);
};

export default useUtmParameters;
