import React, { useEffect } from 'react';
import { Link, Stack, Typography } from '@mui/material';
import { InputDropdown, InputField } from '../../FormInputs';
import { useForm, FormProvider } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import {
  useGetSignUpData,
  useRegistration,
} from '../../../queries/registration/useRegistrationQueries';
import { useQueryClient } from '@tanstack/react-query';
import useSignUpDataError from '../../../hooks/useSignUpDataError';
import { useSnackBar } from '../../CustomSnackbar';
import { QueryParams } from '../../../containers/Registration';
import { useQuery } from '../../../hooks/useQuery';

interface FormProps {
  name: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  heardAboutUs: string;
  referrerName: string | null;
}

interface Props {
  planCode: string;
  changeStep: (direction: 'forward' | 'backward', uuid?: string) => void;
  uuid: string;
}

const PersonalInformation: React.FC<Props> = ({
  planCode,
  changeStep,
  uuid,
}) => {
  const form = useForm<FormProps>({
    defaultValues: {
      name: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      heardAboutUs: '',
      referrerName: '',
    },
  });

  const { setError, control, handleSubmit, reset } = form;
  const queryClient = useQueryClient();
  const snackbar = useSnackBar();
  const query = useQuery();

  const { mutate: signUp, isLoading } = useRegistration({
    options: {
      onSuccess: data => {
        queryClient.setQueryData(['signUp', data.uuid], data);
        changeStep('forward', data.uuid);
      },
      onError: error => {
        if (error && error.response?.data.code === 1) {
          setError('email', {
            type: 'manual',
          });
          snackbar.showSnackBar(
            <Typography fontSize={'inherit'} color={'inherit'}>
              A user with this email already exists. Please try a different
              email address to signup, or{' '}
              <Link href={'/auth/login'} color={'inherit'}>
                login
              </Link>{' '}
              to yor existing account.
            </Typography>,
            'error',
          );
        }
      },
    },
  });

  const { data: signUpData, error: getSignUpDataError } = useGetSignUpData(
    uuid!,
    {
      options: { enabled: !!uuid },
    },
  );

  useSignUpDataError(getSignUpDataError, uuid);

  const heardAboutUsOptions = [
    'Recruiter from Pinnacle',
    'Referred by other agent',
    'Search Engine',
    'Google Ads',
    'Social media channel',
    'Email',
    'Podcast/Radio',
    'Third party review',
    'AgentHub',
    'Other',
  ];

  useEffect(() => {
    if (signUpData) {
      reset({
        name: signUpData.name,
        lastName: signUpData.lastName,
        email: signUpData.email,
        phoneNumber: signUpData.phoneNumber,
        heardAboutUs: signUpData.heardAboutUs,
        referrerName: signUpData.referrerName ? signUpData.referrerName : null,
      });
    }
  }, [signUpData, reset]);

  const isRefererNameVisible =
    form.watch('heardAboutUs') === 'Referred by other agent';

  return (
    <>
      <Typography variant={'h6'} mb={4}>
        Your Personal Information:
      </Typography>
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(data =>
            signUp({
              stepId: 1,
              uuid,
              planCode,
              ...data,
              team: query.get('team'),
            }),
          )}
        >
          <Stack gap={2}>
            <InputField
              control={control}
              name={'name'}
              label={'First Name *'}
              rules={{ required: 'Please input your first name' }}
            />
            <InputField
              control={control}
              name={'lastName'}
              label={'Last Name *'}
              rules={{ required: 'Please input your last name' }}
            />
            <InputField
              control={control}
              name={'email'}
              label={'Email *'}
              rules={{
                required: 'Please input your email',
                pattern: {
                  value:
                    /^[\w-]+(\+[\w-]+)*(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
                  message: 'Not valid email address',
                },
              }}
            />
            <InputField
              control={control}
              name={'phoneNumber'}
              label={'Phone Number *'}
              placeholder={'(XXX) XXX-XXXX'}
              rules={{
                required: 'Please input your phone number',
                pattern: {
                  value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                  message:
                    'Not valid phone number. Expected format: (XXX) XXX-XXXX',
                },
              }}
            />
            <InputDropdown
              control={control}
              name={'heardAboutUs'}
              label={'How did you hear about us?'}
              options={heardAboutUsOptions.map(option => ({
                value: option,
                name: option,
              }))}
            />
            {isRefererNameVisible && (
              <InputField
                control={control}
                name={'referrerName'}
                label={'Which Pinnacle agent referred you? *'}
                rules={{
                  required: isRefererNameVisible && 'Please input agent name',
                }}
              />
            )}
          </Stack>
          <LoadingButton
            variant={'contained'}
            type={'submit'}
            sx={{ mt: 4 }}
            loading={isLoading}
          >
            Continue
          </LoadingButton>
        </form>
      </FormProvider>
    </>
  );
};

export default PersonalInformation;
