import React, { useEffect, useState } from 'react';
import { ITeamData } from '../../slice/types';
import { Header } from '../../styles';
import { useDispatch } from 'react-redux';
import 'react-quill/dist/quill.snow.css';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Switch,
  Typography,
} from '@mui/material';
import ScrollToTopOnMount from 'app/components/ScrollToTopOnMount';
import RouteConstants from 'app/routes';
import { useAdminSlice } from '../../slice';
import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { InputDropdown, InputField } from '../../../../components/FormInputs';
import ClearIcon from '@mui/icons-material/Clear';
import { DropdownOptions } from '../../../../components/FormInputs/InputDropdown';
import {
  useCreateTeam,
  useGetCoupons,
  useGetTeam,
  useUpdateTeam,
} from '../../../../queries/admin/team';
import useResponsive from '../../../../hooks/useResponsive';
import { styled } from '@mui/system';
import nologo from '../../../../../assets/images/nologo.jpg';
import { useUploadImage } from '../../../../queries/uploadFile';
import { ITeam } from '../../../../queries/admin/types';
import { useSnackBar } from '../../../../components/CustomSnackbar';

const ButtonWrapper = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 2,
});

const ButtonsContainer = styled('div')({
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'none',
  flexDirection: 'row',
  gap: '10px',
  zIndex: 1,
});

const LoadingContainer = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  zIndex: 1,
});

const ImageContainer = styled(Paper)(({ theme }) => ({
  position: 'relative',
  height: '150px',
  boxShadow: 'none',
  width: '150px',
  '&:hover img': {
    filter: 'blur(2px)',
  },
  '&:hover div': {
    display: 'flex',
  },
}));

interface Props {
  isEdit?: boolean;
  teamId?: number;
}

type Inputs = {
  name: string;
  uuid: string;
  isPublic: boolean;
  logo: string;
  website: string;
  couponId?: number | string;
  registrationUrl?: string;
};

const TeamAddEdit: React.FC<Props> = ({ isEdit, teamId }) => {
  const teamData = useGetTeam(teamId);
  const { team: data, registrationUrl } = teamData ?? {};
  const isMobile = useResponsive('down', 565);
  const [isLoading, setLoading] = useState(false);
  const [logo, setLogo] = useState<string | null>(null);
  const snackbar = useSnackBar();
  const uploadImage = useUploadImage();
  const createTeam = useCreateTeam();
  const updateTeam = useUpdateTeam();

  const defaultValues = {
    name: '',
    uuid: '',
    isPublic: false,
    logo: '',
    website: '',
    couponId: '',
    registrationUrl: '',
  };

  const form = useForm<Inputs>({ defaultValues: defaultValues });

  const { handleSubmit, control, setValue } = form;

  const couponId = useWatch({
    control,
    name: 'couponId',
    defaultValue: '',
  });

  const { data: Coupons } = useGetCoupons({
    options: {
      // reformat date to name/value pair
      select: data =>
        data.map(option => ({
          value: option.id,
          name: option.name,
        })),
    },
  });

  const handleFormSubmit = data => {
    const teamData: ITeam = {
      id: teamId,
      name: data.name,
      uuid: data.uuid,
      isPublic: data.isPublic,
      logo: data.logo,
      website: data.website,
      coupon: {
        id: data.couponId,
      },
    };

    setLoading(true);

    if (isEdit && teamId) {
      updateTeam.mutate(teamData, {
        onSuccess: url => {
          setLoading(false);
          snackbar.showSnackBar(
            <Typography fontSize={'inherit'} color={'inherit'}>
              Team updated successfully.
            </Typography>,
            'success',
          );
        },
      });
    } else {
      createTeam.mutate(teamData, {
        onSuccess: url => {
          setLoading(false);

          snackbar.showSnackBar(
            <Typography fontSize={'inherit'} color={'inherit'}>
              Team created successfully.
            </Typography>,
            'success',
          );
        },
      });
    }
  };

  const handleChangeImage = async () => {
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleFileInput = e => {
    setLoading(true);

    uploadImage.mutate(e.target.files[0], {
      onSuccess: url => {
        setValue('logo', url);
        setLogo(url);
        setLoading(false);
      },
    });

    if (e.target) {
      e.target.value = '';
    }
  };

  const handleClearCoupon = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setValue('couponId', '');
  };

  useEffect(() => {
    if (!teamId) {
      form.reset(defaultValues);
      setLogo(null);
    }
  }, [teamId]);

  useEffect(() => {
    form.reset({
      ...data,
      couponId: data?.coupon?.id ?? '',
      registrationUrl: registrationUrl,
    });
    setLogo(data?.logo ?? null);
  }, [data]);

  return (
    <Box width={'100%'} sx={{ paddingBottom: '2rem' }}>
      <ScrollToTopOnMount />
      {!isEdit && (
        <Header>
          <Typography sx={{ pb: 1, textTransform: 'capitalize' }} variant="h4">
            Add new team
          </Typography>
        </Header>
      )}
      <Card elevation={2} sx={{ position: 'relative' }}>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <CardContent>
              {isEdit && (
                <InputField
                  control={control}
                  name={'uuid'}
                  label={'UUID'}
                  textFieldProps={{
                    sx: { mt: 2 },
                    disabled: true,
                  }}
                  withCopyButton
                />
              )}
              <Grid container>
                <Grid item xs={8}>
                  <Box width={'100%'}>
                    <InputField
                      control={control}
                      name="name"
                      label="Name *"
                      rules={{ required: 'Please input team name' }}
                      textFieldProps={{
                        sx: { mt: 2 },
                      }}
                    />
                  </Box>
                  <InputField
                    control={control}
                    name={'website'}
                    label={'Website'}
                    textFieldProps={{
                      sx: { mt: 2 },
                    }}
                  />

                  <Box width={'100%'} pt={2}>
                    <Stack
                      direction={'row'}
                      flexWrap={'wrap'}
                      gap={2}
                      width={'100%'}
                    >
                      <Stack
                        gap={2}
                        flexGrow={1}
                        flexWrap={isMobile ? 'wrap' : 'nowrap'}
                      >
                        <InputDropdown
                          name={'couponId'}
                          label="Discount coupon"
                          control={control}
                          selectProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                sx={{
                                  visibility:
                                    couponId !== '' ? 'visible' : 'hidden',
                                }}
                              >
                                <IconButton onClick={handleClearCoupon}>
                                  <ClearIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          options={Coupons as unknown as DropdownOptions}
                        />
                      </Stack>
                    </Stack>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{ mt: 1 }}
                  container
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Grid>
                    <Typography sx={{ mt: 2 }} variant="caption">
                      Team logo
                    </Typography>
                    <Card>
                      <ImageContainer>
                        {isLoading && (
                          <LoadingContainer>
                            <CircularProgress />
                          </LoadingContainer>
                        )}
                        <ButtonWrapper>
                          <ButtonsContainer>
                            <input
                              type="file"
                              id="fileInput"
                              style={{ display: 'none' }}
                              onChange={handleFileInput}
                            />
                            <Button
                              variant="contained"
                              onClick={handleChangeImage}
                            >
                              Change
                            </Button>
                          </ButtonsContainer>
                        </ButtonWrapper>
                        <img
                          src={logo ? logo : nologo}
                          alt={'Team logo'}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                          }}
                        />
                      </ImageContainer>
                    </Card>

                    <Typography sx={{ mt: 2, mr: 4 }} variant="caption">
                      Show this team on website
                    </Typography>
                    <Controller
                      name={'isPublic'}
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={<Switch checked={field.value} {...field} />}
                          label={''}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                {isEdit && (
                  <InputField
                    control={control}
                    label={'Registration URL'}
                    name={'registrationUrl'}
                    textFieldProps={{
                      sx: { mt: 2 },
                      disabled: true,
                    }}
                    withCopyButton
                  />
                )}
              </Grid>
            </CardContent>
            <CardActions>
              <Stack direction="row" flexWrap={'wrap'} gap={'1rem'} mt={1}>
                <Stack direction="row" flexWrap={'wrap'} gap={'1rem'}>
                  <LoadingButton
                    type="submit"
                    variant={'contained'}
                    loading={isLoading}
                  >
                    {isEdit ? 'Save changes' : 'Add team'}
                  </LoadingButton>

                  <Button
                    component={RouterLink}
                    to={RouteConstants.admin.teams}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </Box>
  );
};

export default TeamAddEdit;
