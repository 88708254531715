import React from 'react';
import { ICategory } from 'app/containers/Boosts/slice/types';
import { useQuery } from 'app/hooks/useQuery';
import { Chip, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

export enum QueryParams {
  Category = 'category',
}
interface IProps {
  rootUrl: string;
  categories: Array<ICategory> | null;
}

const Categories = ({ rootUrl, categories }: IProps) => {
  const query = useQuery();
  const history = useHistory();
  const theme = useTheme();

  // TODO: Fix styling of scrollbar: width, padding

  return (
    <Stack
      py={1}
      direction="row"
      spacing={1}
      sx={{
        'overflow-x': 'scroll',
        '::-webkit-scrollbar': {
          '-webkit-appearance': 'none',
          width: '7px',
        },
        '::-webkit-scrollbar:horizontal': {
          height: '3px',
        },
        '::-webkit-scrollbar-thumb': {
          borderRadius: 4,
          backgroundColor: theme.palette.primary.dark,
        },
      }}
    >
      {categories?.map(cat => (
        <Chip
          key={cat.id}
          label={cat.name}
          onClick={() => {
            history.push(`${rootUrl}?${QueryParams.Category}=${cat.slug}`);
          }}
          variant={
            cat.slug === query.get(QueryParams.Category) ? 'filled' : 'outlined'
          }
          onDelete={
            cat.slug === query.get(QueryParams.Category)
              ? () => {
                  query.delete(QueryParams.Category);
                  history.push(`${rootUrl}?${query.toString()}`);
                }
              : undefined
          }
          clickable
          color={'primary'}
        />
      ))}
    </Stack>
  );
};

export default Categories;
