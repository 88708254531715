import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { QueryParams } from '../containers/Registration';
import { SignUpResponseError } from '../queries/registration/useRegistrationQueries';
import { useQuery } from './useQuery';

const useSignUpDataError = (
  getSignUpDataError: SignUpResponseError | null,
  uuid: string,
  isLoading?: boolean,
  clientSecret?: boolean,
  unique_id?: boolean,
) => {
  const history = useHistory();
  const query = useQuery();

  const redirectToStep = useCallback(
    (step: string, deleteUuid?: boolean) => {
      query.set(QueryParams.Step, step);
      if (deleteUuid) {
        query.delete(QueryParams.Uuid);
      }
      history.push(`?${query.toString()}`);
    },
    [history, query],
  );

  useEffect(() => {
    if (!getSignUpDataError) {
      if (isLoading === false && unique_id === false) {
        redirectToStep('3');
      }
    }
    if (getSignUpDataError) {
      const { response } = getSignUpDataError;
      // Redirect to step 4 if payment was completed
      if (response?.data?.code === 1) {
        redirectToStep('4');
      }
      if (response?.status === 404) {
        redirectToStep('1', true);
      }
    }
  }, [
    getSignUpDataError,
    history,
    isLoading,
    query,
    redirectToStep,
    unique_id,
    uuid,
  ]);
};

export default useSignUpDataError;
