import React, { useEffect } from 'react';
import { Card, Grid, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUserDataFetchStatus,
  selectUserInnerPageData,
} from '../../slice/selectors';
import { useAdminSlice } from '../../slice';
import { useGlobalSlice } from '../../../Global/slice';
import { selectCoverImages } from '../../../Global/slice/selectors';
import {
  PublicProfileForm,
  ProfileCoverImageSelect,
  ProfilePictureUpload,
} from '../../../../components/PublicProfile';
import { IUserWithAddressObject, UserInnerPage } from '../../slice/types';

const GridContainer = props => (
  <Grid
    container
    direction="row"
    justifyContent="space-between"
    alignItems="flex-start"
    rowSpacing={3}
    columnSpacing={{ xs: 2, md: 3 }}
    {...props}
  />
);

interface Props {
  userId: string;
  data: UserInnerPage | undefined;
}

const EditPublicProfile: React.FC<Props> = ({ userId, data }) => {
  const { actions } = useAdminSlice();
  const dispatch = useDispatch();
  const { actions: globalActions } = useGlobalSlice();
  const coverImages = useSelector(selectCoverImages);
  const userDataFetchStatus = useSelector(selectUserDataFetchStatus);

  const changeUserCoverImage = (image: string) => {
    dispatch(
      actions.updateUserInnerPageImageUrl({
        data: {
          type: 'cover',
          image,
        },
        userId,
      }),
    );
  };

  const changeUserProfilePicture = (profilePicture: File | null) => {
    dispatch(
      actions.updateUserProfilePicture({
        userId,
        profilePicture,
      }),
    );
  };

  useEffect(() => {
    dispatch(globalActions.getCoverImageList());
  }, [globalActions, dispatch, userId]);

  const handleFormSubmit = formData => {
    dispatch(
      actions.updateUserPublicInfo({
        userId,
        data: {
          ...formData,
        },
      }),
    );
  };

  return (
    <GridContainer>
      <Grid item xs={12} md={8}>
        <Card elevation={2} sx={{ position: 'relative' }}>
          <PublicProfileForm
            data={data}
            handleFormSubmit={handleFormSubmit}
            isLoading={userDataFetchStatus === 'loading'}
            type={'ADMIN'}
          />
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack
          spacing={4}
          direction={{ xs: 'column', sm: 'row', md: 'column' }}
        >
          <ProfilePictureUpload
            isProfilePictureLoading={userDataFetchStatus === 'loading'}
            currentProfilePicture={data?.profilePicture}
            changeProfilePicture={profilePicture =>
              changeUserProfilePicture(profilePicture)
            }
          />
          {data?.coverImage && (
            <ProfileCoverImageSelect
              isCoverImageLoading={userDataFetchStatus === 'loading'}
              coverImages={coverImages}
              changeCoverImage={imageUrl => changeUserCoverImage(imageUrl)}
              userCoverImage={data.coverImage}
            />
          )}
        </Stack>
      </Grid>
    </GridContainer>
  );
};

export default EditPublicProfile;
