import { takeLatest, put } from 'redux-saga/effects';
import { dealsActions as actions } from '.';
import dealsApi from '../dealsApi';
import { globalActions } from 'app/containers/Global/slice';
import { IDeal, IDealRatePayload, IDeals, IGetDealsData } from './types';
import { PayloadAction } from '@reduxjs/toolkit';

function* getDealsSaga(action: PayloadAction<IGetDealsData>) {
  try {
    const deals: IDeals = yield dealsApi.getDeals(action.payload);
    yield put(actions.setDeals(deals));
  } catch (err) {
    yield put(globalActions.handleError(err));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* getDealSaga(action: PayloadAction<number>) {
  try {
    const deal: IDeal = yield dealsApi.getDeal(action.payload);
    yield put(actions.setDeal(deal));
  } catch (err) {
    yield put(globalActions.handleError(err));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* setRatingSaga(action: PayloadAction<IDealRatePayload>) {
  try {
    const { dealId, data } = action.payload;
    const deal: IDeal = yield dealsApi.setDealRate(dealId, data);
    yield put(actions.setDeal(deal));
  } catch (err) {
    yield put(globalActions.handleError(err));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

export function* dealsSaga() {
  yield takeLatest(actions.getDeals.type, getDealsSaga);
  yield takeLatest(actions.getDeal.type, getDealSaga);
  yield takeLatest(actions.setRating.type, setRatingSaga);
}
