import styled from 'styled-components';
import { media } from 'styles/media';

export const Wrapper = styled.div`
  max-width: 180rem;
  margin-left: auto;
  margin-right: auto;
`;

export const SmallInner = styled.div`
  width: 66%;

  ${media.tabLand`
    width: 100%;
  `}
`;
