import React from 'react';
import { Controller, Control } from 'react-hook-form';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { FormControl, FormHelperText } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';

interface Props {
  control: Control<any>;
  name: string;
  label: string;
  rules?: any;
  required?: boolean;
  textFieldProps?: TextFieldProps;
}

const DatePickerInput: React.FC<Props> = ({
  control,
  name,
  label,
  rules,
  textFieldProps,
}) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field, fieldState: { error } }) => (
      <FormControl fullWidth>
        <DesktopDatePicker
          label={label}
          inputFormat="MM/DD/YYYY"
          renderInput={params => (
            <TextField {...params} error={!!error} {...textFieldProps} />
          )}
          {...field}
        />
        {error?.message && (
          <FormHelperText error>{error.message}</FormHelperText>
        )}
      </FormControl>
    )}
  />
);

export default DatePickerInput;
