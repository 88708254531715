import React from 'react';
import { Stack, Typography } from '@mui/material';
import { IWikiItems } from '../../containers/Wiki/wiki';
import WikiCard from './WikiCard';

interface IProps {
  wikiItems: Array<IWikiItems>;
}

const WikiList = ({ wikiItems }: IProps) => {
  return (
    <Stack pt={2} pb={4}>
      {wikiItems.map((wikiItem, index) => (
        <Stack spacing={4} pb={6} key={index}>
          <Typography variant={'h5'}>{wikiItem.title}</Typography>
          {wikiItem.items.map((item, index) => (
            <WikiCard cardData={item} key={index} />
          ))}
        </Stack>
      ))}
    </Stack>
  );
};

export default WikiList;
