import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OffersList from 'app/components/OffersList';
import { selectBoosts, selectIsLoading } from '../../slice/selectors';
import { useBoostsSlice } from '../../slice';
import RouteConstants from 'app/routes';
import Categories from 'app/components/Categories';
import { useQuery } from 'app/hooks/useQuery';
import { useScrollAfterChange } from 'app/hooks/useScrollAfterChange';
import { BoostsQueryParams } from '../../slice/types';
import { Box, Container, Link, Typography } from '@mui/material';
import PageHeader from '../../../../components/PageHeader';
import ScrollToTopOnMount from '../../../../components/ScrollToTopOnMount';

const SCROLL_POINT_ID = 'scroll-point';

const AgentsOffers = () => {
  const boosts = useSelector(selectBoosts);
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch();
  const { actions } = useBoostsSlice();
  const query = useQuery();

  useEffect(() => {
    dispatch(
      actions.getBoosts({
        page: query.get(BoostsQueryParams.Page),
        category: query.get(BoostsQueryParams.Category),
      }),
    );
  }, [actions, dispatch, query]);

  useScrollAfterChange(boosts, SCROLL_POINT_ID);

  return (
    <Container maxWidth="xl">
      <ScrollToTopOnMount />
      <PageHeader
        withDivider
        title="Add-On Services"
        subtitle={
          <Box>
            <Typography>
              Your membership includes everything you need in order to transact
              real estate effectively. Nonetheless, our Partnerships Team has
              spent countless hours securing additional top-notch products &
              services to assist in taking your business to the next level.
              Browse our optional tools and services with Pinnacle Agent Pricing
              just for you.
            </Typography>
          </Box>
        }
      >
        <Categories
          rootUrl={RouteConstants.boosts.all}
          categories={boosts.categories}
        />
      </PageHeader>
      <OffersList isLoading={isLoading} boosts={boosts.online_tools} />
    </Container>
  );
};

export default AgentsOffers;
