import { takeLatest, put, delay } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { adminActions as actions } from '.';
import adminApi from '../adminApi';
import { globalActions } from 'app/containers/Global/slice';
import {
  ISaveBoostPayload,
  IUploadDealPayload,
  ICreateDealPayload,
  ICreateBoostPayload,
  IUploadUserPayload,
  ICreateUserPayload,
  IUpdateUserPublicData,
  IUserObject,
  IUpdateUserInnerPageImageUrl,
  IUpdateUserProfilePicture,
  IUserInnerPageImageUrl,
  ITeamObject,
  ICreateTeamPayload,
  IUpdateTeamPayload,
} from './types';
import RouteConstants from 'app/routes';
import { notification } from 'antd';
import { IBoostObject, ICategory } from 'app/containers/Boosts/slice/types';
import { IDealObject, IGeo } from 'app/containers/Deals/slice/types';
import {
  IUserWithAddressObject,
  IUsersObject,
} from 'app/containers/Admin/slice/types';
import { takeEvery } from '@redux-saga/core/effects';

function* uploadImage(file) {
  const formData = new FormData();
  formData.append('images', file, '[PROXY]');
  const images: Array<{ imageUrl: string }> = yield adminApi.uploadImage(
    formData,
  );
  return images[0].imageUrl;
}

function* getBoostsSaga() {
  try {
    const boosts: Array<IBoostObject> | null = yield adminApi.getBoosts();
    yield put(actions.setBoosts(boosts));
  } catch (err) {
    yield put(globalActions.handleError(err));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* getUsersSaga() {
  try {
    const users: Array<IUsersObject> | null = yield adminApi.getUsers();
    yield put(actions.setUsers(users));
  } catch (err) {
    yield put(globalActions.handleError(err));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* getTeamsSaga() {
  try {
    const teams: Array<ITeamObject> | null = yield adminApi.getTeams();
    yield put(actions.setTeams(teams));
  } catch (err) {
    yield put(globalActions.handleError(err));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* getTeamSaga(action: PayloadAction<string>) {
  try {
    const team: ITeamObject | null = yield adminApi.getTeam(action.payload);
    yield put(actions.setTeam(team));
  } catch (err) {
    yield put(globalActions.handleError(err));
    yield put(
      actions.setFetchStatusByKey({
        key: 'teamDataFetchStatus',
        status: 'fail',
      }),
    );
  }
}

function* getUserSaga(action: PayloadAction<string>) {
  try {
    const user: IUserWithAddressObject = yield adminApi.getUser(action.payload);
    yield put(actions.setUserWithAddress(user));
  } catch (err) {
    yield put(globalActions.handleError(err));
    yield put(
      actions.setFetchStatusByKey({
        key: 'userDataFetchStatus',
        status: 'fail',
      }),
    );
  }
}

function* updateUserSaga(action: PayloadAction<IUploadUserPayload>) {
  try {
    const { userId, data } = action.payload;
    const user: IUserWithAddressObject = yield adminApi.updateUser(
      userId,
      data,
    );
    yield put(actions.setUserWithAddress(user));
    yield put(
      globalActions.showSnackbar({
        message: 'User account information has been saved.',
        type: 'success',
        anchor: {
          vertical: 'top',
          horizontal: 'center',
        },
      }),
    );
  } catch (err) {
    yield put(globalActions.handleError(err));
    yield put(
      actions.setFetchStatusByKey({
        key: 'userDataFetchStatus',
        status: 'fail',
      }),
    );
  }
}

function* createUserSaga(action: PayloadAction<ICreateUserPayload>) {
  try {
    const { data } = action.payload;
    yield adminApi.createUser(data);
    yield put(
      globalActions.showSnackbar({
        message: 'User was created successfully.',
        type: 'success',
        anchor: {
          vertical: 'top',
          horizontal: 'center',
        },
      }),
    );
    yield put(
      actions.setFetchStatusByKey({
        key: 'userDataFetchStatus',
        status: 'success',
      }),
    );
    yield put(globalActions.setRedirectTo(RouteConstants.admin.users));
    yield delay(500);
  } catch (err) {
    yield put(globalActions.handleError(err));
    yield put(
      actions.setFetchStatusByKey({
        key: 'userDataFetchStatus',
        status: 'fail',
      }),
    );
  }
}
function* createTeamSaga(action: PayloadAction<ICreateTeamPayload>) {
  try {
    const { data } = action.payload;
    yield adminApi.createTeam(data);
    yield put(
      globalActions.showSnackbar({
        message: 'Team was created successfully.',
        type: 'success',
        anchor: {
          vertical: 'top',
          horizontal: 'center',
        },
      }),
    );
    yield put(
      actions.setFetchStatusByKey({
        key: 'teamDataFetchStatus',
        status: 'success',
      }),
    );
    yield put(globalActions.setRedirectTo(RouteConstants.admin.teams));
    yield delay(500);
  } catch (err) {
    yield put(globalActions.handleError(err));
    yield put(
      actions.setFetchStatusByKey({
        key: 'teamDataFetchStatus',
        status: 'fail',
      }),
    );
  }
}
function* updateTeamSaga(action: PayloadAction<IUpdateTeamPayload>) {
  try {
    const { teamId, data } = action.payload;
    const team: ITeamObject = yield adminApi.updateTeam(teamId, data);
    yield put(actions.setTeam(team));
    yield put(
      globalActions.showSnackbar({
        message: 'Team information has been saved.',
        type: 'success',
        anchor: {
          vertical: 'top',
          horizontal: 'center',
        },
      }),
    );
  } catch (err) {
    yield put(globalActions.handleError(err));
    yield put(
      actions.setFetchStatusByKey({
        key: 'teamDataFetchStatus',
        status: 'fail',
      }),
    );
  }
}

function* getBoostSaga(action: PayloadAction<number>) {
  try {
    const boost: IBoostObject = yield adminApi.getBoost(action.payload);
    yield put(actions.setBoost(boost));
  } catch (err) {
    yield put(globalActions.handleError(err));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* saveBoostSaga(action: PayloadAction<ISaveBoostPayload>) {
  try {
    const {
      boostId,
      data,
      logoFile,
      coverFile,
      featuredImageDesktopFile,
      featuredImageMobileFile,
    } = action.payload;
    if (logoFile) data.logoUrl = yield uploadImage(logoFile);
    if (coverFile) data.coverUrl = yield uploadImage(coverFile);
    if (featuredImageDesktopFile)
      data.featuredImageDesktopUrl = yield uploadImage(
        featuredImageDesktopFile,
      );
    if (featuredImageMobileFile)
      data.featuredImageMobileUrl = yield uploadImage(featuredImageMobileFile);

    yield adminApi.saveBoost(boostId, data);
    yield delay(500);
    notification.success({
      message: 'Online Tool saved successfully',
      placement: 'bottomRight',
    });
  } catch (err) {
    yield put(globalActions.handleError(err));
  } finally {
    yield put(actions.setIsSaving(false));
  }
}

function* createBoostSaga(action: PayloadAction<ICreateBoostPayload>) {
  try {
    const { data, logoFile } = action.payload;
    if (logoFile) data.logoUrl = yield uploadImage(logoFile);
    yield adminApi.createBoost(data);
    yield put(globalActions.setRedirectTo(RouteConstants.admin.boosts));
    yield delay(500);
    notification.success({
      message: 'Online Tool created successfully',
      placement: 'bottomRight',
    });
  } catch (err) {
    yield put(globalActions.handleError(err));
  } finally {
    yield put(actions.setIsSaving(false));
  }
}

function* getDealsSaga() {
  try {
    const deals: Array<IDealObject> | null = yield adminApi.getDeals();
    yield put(actions.setDeals(deals));
  } catch (err) {
    yield put(globalActions.handleError(err));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* getDealSaga(action: PayloadAction<number>) {
  try {
    const deal: IDealObject = yield adminApi.getDeal(action.payload);
    yield put(actions.setDeal(deal));
  } catch (err) {
    yield put(globalActions.handleError(err));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* updateDealSaga(action: PayloadAction<IUploadDealPayload>) {
  try {
    const { dealId, data, logoFile } = action.payload;
    if (logoFile) data.logoUrl = yield uploadImage(logoFile);
    yield adminApi.updateDeal(dealId, data);
    yield put(globalActions.setRedirectTo(RouteConstants.admin.deals));
    yield delay(500);
    notification.success({
      message: 'Vendor saved successfully',
      placement: 'bottomRight',
    });
  } catch (err) {
    yield put(globalActions.handleError(err));
  } finally {
    yield put(actions.setIsSaving(false));
  }
}

function* createDealSaga(action: PayloadAction<ICreateDealPayload>) {
  try {
    const { data, logoFile } = action.payload;
    if (logoFile) data.logoUrl = yield uploadImage(logoFile);
    yield adminApi.createDeal(data);
    yield put(globalActions.setRedirectTo(RouteConstants.admin.deals));
    yield delay(500);
    notification.success({
      message: 'Vendor created successfully',
      placement: 'bottomRight',
    });
  } catch (err) {
    yield put(globalActions.handleError(err));
  } finally {
    yield put(actions.setIsSaving(false));
  }
}

function* getDealsCategoriesSaga() {
  try {
    const categories: Array<ICategory> | null =
      yield adminApi.getDealsCategories();
    yield put(actions.setDealsCategories(categories));
  } catch (err) {
    yield put(globalActions.handleError(err));
  } finally {
    yield put(actions.setIsCategoriesLoading(false));
  }
}

function* getBoostsCategoriesSaga() {
  try {
    const categories: Array<ICategory> | null =
      yield adminApi.getBoostsCategories();
    yield put(actions.setBoostsCategories(categories));
  } catch (err) {
    yield put(globalActions.handleError(err));
  } finally {
    yield put(actions.setIsCategoriesLoading(false));
  }
}

function* getLocationsSaga() {
  try {
    const locations: Array<IGeo> | null = yield adminApi.getLocations();
    yield put(actions.setLocations(locations));
  } catch (err) {
    yield put(globalActions.handleError(err));
  } finally {
    yield put(actions.setIsLocationsLoading(false));
  }
}

function* getUserStripeUrl(action: PayloadAction<string>) {
  try {
    const userId = action.payload;
    const stripeUrl: string = yield adminApi.getUserStripeUrl(userId);
    yield put(actions.setUserStripeUrl(stripeUrl));
    yield put(
      actions.setFetchStatusByKey({
        key: 'userStripeUrlFetchStatus',
        status: 'success',
      }),
    );
  } catch (err) {
    yield put(globalActions.handleError(err));
    yield put(
      actions.setFetchStatusByKey({
        key: 'userStripeUrlFetchStatus',
        status: 'fail',
      }),
    );
  }
}

function* generateUserDocusignDocument(action: PayloadAction<string>) {
  try {
    const userId = action.payload;
    yield put(
      globalActions.showSnackbar({
        message:
          "Docusign document is being generated, please don't close this page",
        type: 'info',
        anchor: {
          vertical: 'top',
          horizontal: 'center',
        },
      }),
    );
    yield adminApi.generateUserDocusignDocument(userId);
    yield put(
      actions.setFetchStatusByKey({
        key: 'userDocusignGenerationStatus',
        status: 'success',
      }),
    );
    yield put(
      globalActions.showSnackbar({
        message: 'Docusign document was sent successfully',
        type: 'success',
        anchor: {
          vertical: 'top',
          horizontal: 'center',
        },
      }),
    );
  } catch (err) {
    yield put(globalActions.handleError(err));
    yield put(
      actions.setFetchStatusByKey({
        key: 'userDocusignGenerationStatus',
        status: 'fail',
      }),
    );
  }
}

function* updateUserPublicInfo(action: PayloadAction<IUpdateUserPublicData>) {
  try {
    const { data, userId } = action.payload;
    const user: IUserObject = yield adminApi.updateUserPublicData(data, userId);
    yield put(actions.setUserData(user));
    yield put(
      globalActions.showSnackbar({
        message: 'User website information has been saved.',
        type: 'success',
        anchor: {
          vertical: 'top',
          horizontal: 'center',
        },
      }),
    );
  } catch (err) {
    yield put(globalActions.handleError(err));
  }
}

function* updateUserProfilePicture(
  action: PayloadAction<IUpdateUserProfilePicture>,
) {
  const data: IUserInnerPageImageUrl = {
    type: 'profile',
    image: null,
  };
  const { profilePicture, userId } = action.payload;
  try {
    if (profilePicture) {
      data.image = yield uploadImage(profilePicture);
    }
    yield put(actions.updateUserInnerPageImageUrl({ data, userId }));
  } catch (err) {
    yield put(globalActions.handleError(err));
  }
}

function* updateUserInnerPageImageUrl(
  action: PayloadAction<IUpdateUserInnerPageImageUrl>,
) {
  try {
    const { data, userId } = action.payload;
    const user: IUserObject = yield adminApi.updateUserInnerPageImageUrl(
      data,
      userId,
    );
    yield put(actions.setUserData(user));
    yield put(
      globalActions.showSnackbar({
        message: `User ${data.type} image has been saved.`,
        type: 'success',
        anchor: {
          vertical: 'top',
          horizontal: 'center',
        },
      }),
    );
  } catch (err) {
    yield put(globalActions.handleError(err));
  }
}

export function* adminSaga() {
  yield takeLatest(actions.getUsers.type, getUsersSaga);
  yield takeLatest(actions.getTeam.type, getTeamSaga);
  yield takeLatest(actions.getTeams.type, getTeamsSaga);
  yield takeLatest(actions.createTeam.type, createTeamSaga);
  yield takeLatest(actions.updateTeam.type, updateTeamSaga);
  yield takeLatest(actions.getUser.type, getUserSaga);
  yield takeLatest(actions.updateUser.type, updateUserSaga);
  yield takeLatest(actions.createUser.type, createUserSaga);
  yield takeLatest(actions.getBoosts.type, getBoostsSaga);
  yield takeLatest(actions.getBoost.type, getBoostSaga);
  yield takeLatest(actions.saveBoost.type, saveBoostSaga);
  yield takeLatest(actions.createBoost.type, createBoostSaga);
  yield takeLatest(actions.getDeals.type, getDealsSaga);
  yield takeLatest(actions.getDeal.type, getDealSaga);
  yield takeLatest(actions.updateDeal.type, updateDealSaga);
  yield takeLatest(actions.createDeal.type, createDealSaga);
  yield takeLatest(actions.getDealsCategories.type, getDealsCategoriesSaga);
  yield takeLatest(actions.getBoostsCategories.type, getBoostsCategoriesSaga);
  yield takeLatest(actions.getLocations.type, getLocationsSaga);
  yield takeLatest(actions.getUserStripeUrl.type, getUserStripeUrl);
  yield takeLatest(actions.updateUserPublicInfo.type, updateUserPublicInfo);
  yield takeLatest(
    actions.updateUserProfilePicture.type,
    updateUserProfilePicture,
  );
  yield takeLatest(
    actions.updateUserInnerPageImageUrl.type,
    updateUserInnerPageImageUrl,
  );
  yield takeEvery(
    actions.generateUserDocusignDocument.type,
    generateUserDocusignDocument,
  );
}
