import React from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Typography,
} from '@mui/material';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

interface ITileProps {
  contact: {
    photo: string;
    name: string;
    position: string;
    phones: string[];
    licence?: string;
    emails: string[];
    description: string;
    footer: any;
  };
}

const ContactTile = ({ contact }: ITileProps) => {
  return (
    <Card sx={{ minHeight: 503, display: 'flex', flexDirection: 'column' }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <>
          <Box
            sx={{ display: 'flex', justifyContent: 'center' }}
            key={contact.name}
          >
            <Avatar
              alt={contact.name}
              src={contact.photo}
              sx={{ width: '25%', height: '25%' }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="h5" component="div">
              {contact.name}
            </Typography>
            <Typography variant="subtitle2" component="div">
              {contact.position}
            </Typography>
            {contact.licence && (
              <Typography variant="subtitle2" component="div">
                {contact.licence}
              </Typography>
            )}
          </Box>
          <Divider sx={{ my: 1 }} />
          {contact.phones.map(phone => {
            return (
              <Box
                key={phone}
                sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}
              >
                <PhoneInTalkOutlinedIcon />
                <Typography variant="body2" color="text.secondary">
                  {phone}
                </Typography>
              </Box>
            );
          })}
          {contact.emails.map(email => {
            return (
              <Box
                key={email}
                sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}
              >
                <EmailOutlinedIcon />
                <Typography variant="body2" color="text.secondary">
                  {email}
                </Typography>
              </Box>
            );
          })}
          <Divider sx={{ my: 1 }} />
          <Typography
            variant="body2"
            color="text.secondary"
            dangerouslySetInnerHTML={{ __html: contact.description }}
          ></Typography>
        </>
      </CardContent>
      <Divider sx={{ my: 1 }} />
      <Box
        sx={{
          padding: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {contact.footer}
      </Box>
    </Card>
  );
};

export default ContactTile;
