import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Space, Spin } from 'antd';
import Row from 'app/components/Row';
import { MainContent } from './BoostInner.styles';
import { selectBoost, selectIsLoading } from '../../slice/selectors';
import { IBoost, IBoostQueryParams } from '../../slice/types';
import { useBoostsSlice } from '../../slice';
import Typography from 'app/components/Typography';
import ScrollToTop from 'app/components/ScrollToTop';
import { QuillEditor } from 'app/containers/Admin/slice/types';
import { Box, Card, CardContent, Container } from '@mui/material';
import NoResults from '../../../../components/NoResults';
import AssetsGallery from '../../../../components/AssetsGallery';
import {
  FeaturedImage,
  GridContainer,
  TextWrapper,
  Image,
} from '../../../../components/OnlineToolPriceCard/styles';
import parse from 'html-react-parser';
import useResponsive from '../../../../hooks/useResponsive';
import OnlineToolPriceCard from '../../../../components/OnlineToolPriceCard';
import { styled } from '@mui/system';

const TopContainer = styled(GridContainer)`
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr);
  grid-gap: 24px;
  justify-content: space-between;
  align-items: start;
  overflow: auto;

  > :first-child {
    grid-column: span 2;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;

    > :first-child {
      grid-column: span 1;
    }
  }
`;

const BoostsInner = () => {
  const dispatch = useDispatch();
  const { actions } = useBoostsSlice();
  const { boostId } = useParams<IBoostQueryParams>();
  const offer: IBoost | null = useSelector(selectBoost);
  const isLoading = useSelector(selectIsLoading);
  const isMobile = useResponsive('down', 600);

  useEffect(() => {
    if (boostId) dispatch(actions.getBoost(Number(boostId)));
  }, [boostId, dispatch, actions]);

  if (isLoading)
    return (
      <MainContent>
        <ScrollToTop />
        <Row style={{ textAlign: 'center', paddingTop: '6rem' }}>
          <Spin size="large" />
        </Row>
      </MainContent>
    );

  if (offer)
    return (
      <Container maxWidth="xl" sx={{ mt: 5 }}>
        <ScrollToTop />
        <TopContainer columns={2 + offer.onlineTool.pricing.length}>
          <Card
            sx={{
              p: 2,
              height: '100%',
            }}
            className={'service-card'}
          >
            <CardContent>
              <Box display="flex" justifyContent="space-between">
                <Image src={offer.onlineTool.logoUrl} />
              </Box>
              <Typography type="h" level={3}>
                {offer.onlineTool.title}
              </Typography>
              {offer.onlineTool.shortDescription && (
                <TextWrapper style={{ paddingTop: '22px' }}>
                  {parse(offer.onlineTool.shortDescription)}
                </TextWrapper>
              )}
            </CardContent>
          </Card>
          {offer.onlineTool.pricing.map((pricingData, index) => (
            <OnlineToolPriceCard key={index} price={pricingData} />
          ))}
        </TopContainer>
        <FeaturedImage
          src={
            isMobile
              ? offer.onlineTool.featuredImageMobileUrl
              : offer.onlineTool.featuredImageDesktopUrl
          }
        />

        <Row isSmall style={{ marginBottom: '1rem', marginTop: '1rem' }}>
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            {offer.onlineTool.description &&
              offer.onlineTool.description !== QuillEditor.EmptyHtml && (
                <TextWrapper
                  dangerouslySetInnerHTML={{
                    __html: offer.onlineTool.description,
                  }}
                />
              )}
          </Space>
        </Row>
        <AssetsGallery assets={offer.onlineTool.assetsGallery.images} />
      </Container>
    );

  return (
    <MainContent>
      <ScrollToTop />
      <NoResults title={'No vendor found'} />
    </MainContent>
  );
};

export default BoostsInner;
