import React, { useEffect } from 'react';
import { Card, Grid, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useUserProfileSlice } from '../slice';
import { selectCoverImages } from '../../Global/slice/selectors';
import { useGlobalSlice } from '../../Global/slice';
import {
  selectUserDataFetchStatus,
  selectUserInnerPageData,
} from '../slice/selectors';
import {
  PublicProfileForm,
  ProfilePictureUpload,
  ProfileCoverImageSelect,
} from '../../../components/PublicProfile';

const GridContainer = props => (
  <Grid
    container
    direction="row"
    justifyContent="space-between"
    alignItems="flex-start"
    rowSpacing={3}
    columnSpacing={{ xs: 2, md: 3 }}
    {...props}
  />
);

interface Props {
  userId: number;
}

const PublicProfile: React.FC<Props> = ({ userId }) => {
  const { actions } = useUserProfileSlice();
  const dispatch = useDispatch();
  const { actions: globalActions } = useGlobalSlice();
  const data = useSelector(selectUserInnerPageData);
  const coverImages = useSelector(selectCoverImages);
  const userDataFetchStatus = useSelector(selectUserDataFetchStatus);

  const changeUserCoverImage = (image: string) => {
    dispatch(
      actions.updateUserPublicProfileImageUrl({
        type: 'cover',
        image,
      }),
    );
  };

  const changeUserProfilePicture = (profilePicture: File | null) => {
    dispatch(actions.updateUserPublicProfilePicture(profilePicture));
  };

  useEffect(() => {
    dispatch(globalActions.getCoverImageList());
  }, [globalActions, dispatch]);

  const handleFormSubmit = formData => {
    dispatch(actions.updateUserPublicInfo({ userId, ...formData }));
  };

  return (
    <GridContainer>
      <Grid item xs={12} md={8}>
        <Card elevation={2} sx={{ position: 'relative' }}>
          <PublicProfileForm
            data={data}
            handleFormSubmit={handleFormSubmit}
            isLoading={userDataFetchStatus === 'loading'}
            type={'USER'}
          />
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack
          spacing={4}
          direction={{ xs: 'column', sm: 'row', md: 'column' }}
        >
          <ProfilePictureUpload
            isProfilePictureLoading={userDataFetchStatus === 'loading'}
            currentProfilePicture={data?.profilePicture}
            changeProfilePicture={profilePicture =>
              changeUserProfilePicture(profilePicture)
            }
          />
          {data?.coverImage && (
            <ProfileCoverImageSelect
              isCoverImageLoading={userDataFetchStatus === 'loading'}
              coverImages={coverImages}
              changeCoverImage={imageUrl => changeUserCoverImage(imageUrl)}
              userCoverImage={data.coverImage}
            />
          )}
        </Stack>
      </Grid>
    </GridContainer>
  );
};

export default PublicProfile;
