const RouteConstants = {
  auth: {
    index: '/auth',
    login: '/auth/login',
    registration: '/auth/registration',
    resetPassword: '/auth/reset-password',
    newPassword: '/auth/new-password',
  },
  dashboard: {
    index: '/dashboard',
  },
  boosts: {
    index: '/online-tools',
    all: '/online-tools/all',
    boostInner: '/online-tools/online-tool/:boostId',
    residential: '/residential-tools',
    residentialInner: '/residential-tools/:boostId',
    commercial: '/commercial-tools',
    commercialInner: '/commercial-tools/:boostId',
    education: {
      continuing: {
        index: '/continuing-education',
        inner: '/continuing-education/:boostId',
      },
      agentMentorship: {
        index: '/agent-mentorship',
        inner: '/agent-mentorship/:boostId',
      },
      coaching: {
        index: '/coaching',
        inner: '/coaching/:boostId',
      },
    },
  },
  myResources: {
    index: '/my-resources',
    inner: '/my-resources/:boostId',
  },
  education: {
    index: '/education',
    inner: '/education/:boostId',
  },
  deals: {
    index: '/vendors',
    all: '/vendors/all',
    dealsInner: '/vendors/vendor/:dealId',
  },
  admin: {
    index: '/admin',
    boosts: '/admin/online-tools',
    addBoost: '/admin/online-tools/add',
    editBoost: '/admin/online-tools/edit/:boostId',
    deals: '/admin/vendors',
    addDeal: '/admin/vendors/add',
    editDeal: '/admin/vendors/edit/:dealId',
    users: '/admin/users',
    editUser: '/admin/users/edit/:userId',
    addUser: '/admin/users/add',
    teams: '/admin/teams',
    addTeam: '/admin/team/add',
    editTeam: '/admin/team/edit/:teamId',
  },
  wiki: {
    index: '/wiki',
  },
  onboarding: {
    index: '/onboarding',
  },
  getPaid: {
    index: '/get-paid',
  },
  agentByDesign: {
    index: '/agent-by-design',
  },
  profile: {
    index: '/profile',
    public: '/profile/public',
    documents: '/profile/documents',
    team: '/profile/team',
  },
};

export default RouteConstants;
