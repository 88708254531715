import React, { useEffect, useState, useMemo } from 'react';
import {
  Form,
  Input,
  Skeleton,
  Upload,
  Checkbox,
  Select,
  InputNumber,
} from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import {
  DealForm,
  IDealData,
  IDealEditParams,
  QuillEditor,
} from '../../slice/types';
import { FormCard, Header } from '../../styles';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getFileNameFromUrl } from 'utils/helpers';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Typography from 'app/components/Typography';
import ScrollToTopOnMount from 'app/components/ScrollToTopOnMount';
import RouteConstants from 'app/routes';
import { Button, Link } from 'app/components/Clicks';
import { svgIcons } from 'utils/svgIcons';
import {
  selectDeal,
  selectDealsCategories,
  selectIsCategoriesLoading,
  selectIsLoading,
  selectIsLocationsLoading,
  selectIsSaving,
  selectLocations,
} from '../../slice/selectors';
import { useAdminSlice } from '../../slice';
import { isImageSizeValid } from 'utils/helpers';

const FORM_ID = 'deal-form';
const { Option } = Select;

const DealAddEdit = () => {
  const [form] = Form.useForm();
  const { dealId } = useParams<IDealEditParams>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const deal = useSelector(selectDeal);
  const categories = useSelector(selectDealsCategories);
  const locations = useSelector(selectLocations);
  const isLoading = useSelector(selectIsLoading);
  const isCategoriesLoading = useSelector(selectIsCategoriesLoading);
  const isLocationsLoading = useSelector(selectIsLocationsLoading);
  const isSaving = useSelector(selectIsSaving);
  const dispatch = useDispatch();
  const { actions } = useAdminSlice();
  const [logoList, setLogoList] = useState<Array<UploadFile<any>>>([]);
  const [description, setDescription] = useState('');
  const [partnerDescription, setPartnerDescription] = useState('');
  const history = useHistory();
  const [isCountryWide, setIsCountryWide] = useState<boolean>(false);

  useEffect(() => {
    if (dealId) {
      setIsEdit(true);
      dispatch(actions.getDeal(Number(dealId)));
    } else {
      setIsEdit(false);
    }
  }, [dealId, dispatch, actions, history]);

  useEffect(() => {
    dispatch(actions.getDealsCategories());
    dispatch(actions.getLocations());
  }, [actions, dispatch]);

  useEffect(() => {
    if (isEdit && deal) {
      let logoObj: UploadFile<any> | null = null;

      if (deal.logoUrl) {
        logoObj = {
          uid: '0',
          name: getFileNameFromUrl(deal.logoUrl),
          status: 'done',
          url: deal.logoUrl,
        };

        setLogoList([logoObj]);
        form.setFieldsValue({
          [DealForm.LogoObj]: [logoObj],
        });
      }

      setDescription(deal.description || '');
      setPartnerDescription(deal.partnerDescription || '');

      form.setFieldsValue({
        [DealForm.CompanyName]: deal.companyName,
        [DealForm.Title]: deal.title,
        [DealForm.ShortDescription]: deal.shortDescription,
        [DealForm.ClientValue]: deal.clientValue,
        [DealForm.AffiliateUrl]: deal.affiliateUrl,
        [DealForm.PhoneNumber]: deal.phoneNumber,
        [DealForm.Email]: deal.email,
        [DealForm.Facebook]: deal.facebook,
        [DealForm.Categories]: deal.categories?.map(cat => cat.id),
        [DealForm.Geo]: deal.geo,
        [DealForm.CountryWide]: deal.countryWide,
        [DealForm.Active]: deal.active,
        [DealForm.Top]: deal.top,
        [DealForm.Rank]: deal.rank,
      });

      setIsCountryWide(Boolean(deal.countryWide));
    }
  }, [isEdit, deal, form]);

  const catOptions = useMemo(() => {
    const options: React.ReactNode[] = [];
    categories?.forEach(cat => {
      options.push(
        <Option value={cat.id} key={cat.slug} disabled>
          {cat.name}
        </Option>,
      );
      if (cat.children)
        cat.children.forEach(child => {
          options.push(
            <Option
              value={child.id}
              key={child.slug}
              className="realthy-child-opt"
            >
              {child.name}
            </Option>,
          );
        });
    });
    return options;
  }, [categories]);

  const locationOptions = useMemo(() => {
    const options: React.ReactNode[] = [];
    locations?.forEach(loc => {
      options.push(
        <Option value={loc.code} key={loc.code}>
          {loc.name}
        </Option>,
      );
      if (loc.cities)
        loc.cities.forEach(child => {
          options.push(
            <Option
              value={child.code}
              key={child.code}
              className="realthy-child-opt"
            >
              {child.name}
            </Option>,
          );
        });
    });
    return options;
  }, [locations]);

  const onFormSubmit = ({
    companyName,
    title,
    shortDescription,
    clientValue,
    affiliateUrl,
    phoneNumber,
    logoObj,
    categories,
    geo,
    countryWide,
    email,
    facebook,
    active,
    top,
    rank,
  }) => {
    const data: IDealData = {
      companyName: companyName,
      title: title,
      shortDescription: shortDescription,
      clientValue: clientValue,
      description: description === QuillEditor.EmptyHtml ? '' : description,
      partnerDescription:
        partnerDescription === QuillEditor.EmptyHtml ? '' : partnerDescription,
      affiliateUrl: affiliateUrl,
      phoneNumber: phoneNumber,
      email: email,
      facebook: facebook,
      logoUrl: isEdit ? deal?.logoUrl || '' : '',
      categories: categories,
      geo: geo,
      countryWide: countryWide,
      active: active,
      top: top,
      rank: Math.round(rank),
    };

    if (
      isEdit &&
      dealId &&
      data.companyName &&
      data.title &&
      data.shortDescription &&
      data.categories.length > 0
    ) {
      dispatch(
        actions.updateDeal({
          dealId: Number(dealId),
          data,
          logoFile: logoObj ? logoObj[0].originFileObj : null,
        }),
      );
      return;
    }

    if (
      data.companyName &&
      data.title &&
      data.shortDescription &&
      data.categories
    ) {
      dispatch(
        actions.createDeal({
          data,
          logoFile: logoObj ? logoObj[0].originFileObj : null,
        }),
      );
    }
  };

  const getLogoUploadValue = (e: any) => {
    if (Array.isArray(e)) {
      setLogoList(e);
      return e;
    }
    setLogoList(e.fileList);
    return e && e.fileList;
  };

  const onCountryWideChange = event => {
    if (event.target.checked) {
      form.resetFields([DealForm.Geo]);
    }
    setIsCountryWide(Boolean(event.target.checked));
  };

  return (
    <div>
      <ScrollToTopOnMount />
      <Header>
        <Typography type="h" level={3}>
          {isEdit ? 'Edit vendor' : 'Add new vendor'}
        </Typography>
        <Link
          to={RouteConstants.admin.deals}
          common={{ variation: 'secondary', small: 'true' }}
        >
          Cancel
        </Link>
      </Header>
      {isLoading ? (
        <Skeleton active style={{ marginTop: '3.5rem' }} />
      ) : (
        <FormCard>
          <Form
            name={FORM_ID}
            form={form}
            id={FORM_ID}
            onFinish={onFormSubmit}
            layout="vertical"
          >
            <Typography
              type="h"
              level={4}
              common={{ margin: '3.5rem 0 1.5rem 0' }}
            >
              Is this vendor active?
            </Typography>
            <Form.Item name={DealForm.Active} valuePropName="checked">
              <Checkbox>This vendor is visible for users.</Checkbox>
            </Form.Item>
            <Typography
              type="h"
              level={4}
              common={{ margin: '3.5rem 0 1.5rem 0' }}
            >
              Partner
            </Typography>
            <Form.Item
              name={DealForm.CompanyName}
              rules={[
                {
                  required: true,
                  message: 'Please input a company name of a partner',
                },
              ]}
              label="Name"
            >
              <Input placeholder="Company name" size="large" />
            </Form.Item>

            <Form.Item
              name={DealForm.LogoObj}
              label="Logo"
              extra="Max size 20KB"
              valuePropName="file"
              getValueFromEvent={getLogoUploadValue}
              rules={[
                {
                  validator: (rule, value) => {
                    if (
                      Array.isArray(value) &&
                      !isImageSizeValid(value[0]?.size, 20)
                    ) {
                      return Promise.reject('File cannot be larger than 20KB');
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Upload
                name="partner_logo"
                listType="picture"
                fileList={logoList}
                accept=".jpg,.jpeg,.png,.svg"
                maxCount={1}
                beforeUpload={() => false}
              >
                <Button
                  type="button"
                  common={{ variation: 'secondary', iconside: 'left' }}
                >
                  {svgIcons.upload()} Upload Logo
                </Button>
              </Upload>
            </Form.Item>

            <Typography
              type="h"
              level={4}
              common={{ margin: '3.5rem 0 1.5rem 0' }}
            >
              Contacts
            </Typography>
            <Form.Item name={DealForm.AffiliateUrl} label="Affiliate link">
              <Input placeholder="https://..." size="large" disabled={isEdit} />
            </Form.Item>
            <Form.Item
              name={DealForm.PhoneNumber}
              label="Phone number"
              rules={[
                {
                  validator: (rule, value) => {
                    if (
                      value &&
                      !/^[+]?(1-|1\s|1|\d{3}-|\d{3}\s|)?((\(\d{3}\))|\d{3})(-|\s)?(\d{3})(-|\s)?(\d{4})$/.test(
                        value,
                      )
                    ) {
                      return Promise.reject(
                        'Please enter a valid phone number',
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Input placeholder="(XXX)-XXX-XXXX" size="large" />
            </Form.Item>
            <Form.Item
              name={DealForm.Email}
              rules={[
                {
                  type: 'email',
                  message: 'Please enter a valid email',
                },
              ]}
              label="Email"
            >
              <Input placeholder="Vendor email" size="large" />
            </Form.Item>
            <Form.Item name={DealForm.Facebook} label="Facebook link">
              <Input placeholder="Facebook link" size="large" />
            </Form.Item>

            <Typography
              type="h"
              level={4}
              common={{ margin: '3.5rem 0 1.5rem 0' }}
            >
              Vendor
            </Typography>
            <Form.Item
              name={DealForm.Categories}
              label="Category"
              rules={[{ required: true, message: 'Please select a category' }]}
            >
              <Select
                placeholder="Select a category"
                loading={isCategoriesLoading}
                mode="multiple"
                allowClear
              >
                {catOptions}
              </Select>
            </Form.Item>
            <Form.Item name={DealForm.CountryWide} valuePropName="checked">
              <Checkbox onChange={onCountryWideChange}>
                Vendor is available <strong>country wide</strong>
              </Checkbox>
            </Form.Item>
            <Form.Item
              name={DealForm.Geo}
              label="Location"
              rules={[
                { required: !isCountryWide, message: 'Please select location' },
              ]}
            >
              <Select
                placeholder="Select location"
                loading={isLocationsLoading}
                mode="multiple"
                allowClear
                disabled={isCountryWide}
              >
                {locationOptions}
              </Select>
            </Form.Item>
            <Form.Item
              name={DealForm.Title}
              rules={[
                {
                  required: true,
                  message: 'Please input a deal title',
                },
              ]}
              label="Title"
            >
              <Input placeholder="Boost title" size="large" />
            </Form.Item>

            <Form.Item
              name={DealForm.ClientValue}
              label="Value for the agent's client"
            >
              <Input.TextArea showCount maxLength={250} />
            </Form.Item>

            <Form.Item
              name={DealForm.ShortDescription}
              label="Short description"
              rules={[
                {
                  required: true,
                  message: 'Please input a short description',
                },
              ]}
            >
              <Input.TextArea showCount maxLength={350} />
            </Form.Item>

            <Typography
              type="div"
              bodyVariation="bodySmall"
              common={{
                isUppercase: true,
                fontWeight: 400,
                margin: '0 0 8px 0',
              }}
            >
              Description
            </Typography>
            <ReactQuill
              value={description}
              onChange={setDescription}
              style={{ marginBottom: '24px' }}
            />

            <Typography
              type="div"
              bodyVariation="bodySmall"
              common={{
                isUppercase: true,
                fontWeight: 400,
                margin: '0 0 8px 0',
              }}
            >
              Partner description
            </Typography>
            <ReactQuill
              value={partnerDescription}
              onChange={setPartnerDescription}
              style={{ marginBottom: '24px' }}
            />
            <Form.Item
              name={DealForm.Top}
              valuePropName="checked"
              label="Top vendor"
            >
              <Checkbox>This is a TOP vendor.</Checkbox>
            </Form.Item>
            <Form.Item
              name={DealForm.Rank}
              label="Position in All Vendors list"
              extra="The LOWER is the number, the HIGHER position in the list this vendor will take. 0 is the highest."
              rules={[
                {
                  validator: (rule, value) => {
                    if (value && !/^[1-9]\d*$/.test(value)) {
                      return Promise.reject('Please enter a positive integer');
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <InputNumber min={0} placeholder="0" />
            </Form.Item>

            <Form.Item>
              <Button
                type="submit"
                loading={isSaving ? 'true' : 'false'}
                common={{ margin: '0 2rem 0 0' }}
              >
                {isEdit ? 'Save changes' : 'Add vendor'}
              </Button>
              <Link
                to={RouteConstants.admin.deals}
                common={{ variation: 'secondary' }}
              >
                Cancel
              </Link>
            </Form.Item>
          </Form>
        </FormCard>
      )}
    </div>
  );
};

export default DealAddEdit;
