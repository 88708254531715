import { useEffect } from 'react';
import { API_URL } from '../../utils/constants';

function useBeforeUnload(uuid, eventName) {
  const handleBeforeUnload = event => {
    const url = API_URL + '/signup/abandon/' + uuid + '/' + eventName;

    fetch(url, {
      method: 'GET',
      keepalive: true,
    }).catch(error => {
      console.error('Failed to send tracking data:', error);
    });
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [uuid, eventName, handleBeforeUnload]);
}

export default useBeforeUnload;
