import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDeals, selectIsLoading } from '../../slice/selectors';
import RouteConstants from 'app/routes';
import VendorsList from 'app/components/VendorsList';
import { useDealsSlice } from '../../slice';
import { useQuery } from 'app/hooks/useQuery';
import { useScrollAfterChange } from 'app/hooks/useScrollAfterChange';
import PageHeader from '../../../../components/PageHeader';
import { Box, Button, Container, Typography } from '@mui/material';
import FilterDrawer, { QueryParams } from '../../../../components/FilterDrawer';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { selectUserInfo } from '../../../UserSettings/slice/selectors';
import { useUserProfileSlice } from '../../../UserSettings/slice';

const SCROLL_POINT_ID = 'scroll-point';

const AllDeals = () => {
  const dispatch = useDispatch();
  const { actions } = useDealsSlice();
  const deals = useSelector(selectDeals);
  const isLoading = useSelector(selectIsLoading);
  const query = useQuery();
  const [filterDrawerOpen, setFilterDrawer] = useState(false);
  const [isVendorDrawerVisible, setIsVendorDrawerVisible] =
    useState<boolean>(false);
  const userData = useSelector(selectUserInfo);
  const { actions: userActions } = useUserProfileSlice();

  useEffect(() => {
    dispatch(userActions.getUserInfo());
  }, [userActions, dispatch]);

  const handleFilterToggle = () => {
    setFilterDrawer(!filterDrawerOpen);
  };

  const handleVendorDrawerToggle = () => {
    setIsVendorDrawerVisible(!isVendorDrawerVisible);
  };

  useEffect(() => {
    dispatch(
      actions.getDeals({
        page: query.get(QueryParams.Page),
        category:
          query.get(QueryParams.SubCategory) || query.get(QueryParams.Category),
        city:
          query.get(QueryParams.SubLocation) || query.get(QueryParams.Location),
      }),
    );
  }, [dispatch, actions, query]);

  useScrollAfterChange(deals, SCROLL_POINT_ID);

  return (
    <Container maxWidth="xl">
      <PageHeader
        withDivider
        title="Local Vendors"
        subtitle={
          <Box>
            <Typography variant={'body1'}>
              Help your clients with the most relevant service providers
              tailored to serve any home ownership need.
            </Typography>
            <Typography variant={'body1'}>
              Our team follows a rigorous vetting process that every vendor must
              complete to get accepted to PinnacleHQ. We strive to make great
              local connections between vendors, homeowners, and real estate
              agents that can last decades and create long-term value. We
              regularly review the list of vendors and update it for the
              convenience of our community.
            </Typography>
          </Box>
        }
        rightColumn={
          <Button
            variant={'outlined'}
            size={'large'}
            startIcon={<AddOutlinedIcon />}
            onClick={() => setIsVendorDrawerVisible(true)}
          >
            Suggest your vendor
          </Button>
        }
      />
      <FilterDrawer
        rootUrl={RouteConstants.deals.all}
        drawerOpen={filterDrawerOpen}
        handleDrawerClose={handleFilterToggle}
        categories={deals.categories}
        locations={deals.geo}
      />
      <VendorsList
        isLoading={isLoading}
        deals={deals.vendors}
        onFilterClick={handleFilterToggle}
        toggleVendorDrawer={handleVendorDrawerToggle}
        isDrawerVisible={isVendorDrawerVisible}
        userData={userData}
      />
    </Container>
  );
};

export default AllDeals;
