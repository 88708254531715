import {
  QueryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import api from '../api';
import { IUser } from '../auth/types';
import { AxiosError } from 'axios';
import { IUserWithAddressObject } from '../../containers/Admin/slice/types';

export function useDeleteUser() {
  return useMutation((userId: string) => api.delete(`admin/users/${userId}`));
}

interface GetUserAdminOptions<T> {
  options?: UseQueryOptions<IUserWithAddressObject, AxiosError, T, any>;
}

export function useGeUserAdmin<T = IUserWithAddressObject>(
  userId: string,
  props?: GetUserAdminOptions<T>,
) {
  const { data } = useQuery(
    ['getUserAdmin'],
    async () => {
      const result = await api.get(`admin/users/${userId}`);
      return result.data;
    },
    props?.options,
  );

  return data;
}

interface ToggleUserActivationOptions<T> {
  options?: UseMutationOptions<
    IUserWithAddressObject,
    AxiosError,
    { userId: string; active: boolean },
    any
  >;
}

export function useToggleUserActivation<T = IUserWithAddressObject>(
  props?: ToggleUserActivationOptions<T>,
) {
  return useMutation(
    ['toggleUserActivation'],
    async ({ userId, active }: { userId: string; active: boolean }) => {
      const result = await api.post(`admin/users/toggleActive/${userId}`, {
        active: active,
      });
      return result.data;
    },
    props?.options,
  );
}
