import React, { useState } from 'react';
import { CircularProgress, Stack } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripePaymentForm from '../stripePaymentForm';
import { useGetSignUpData } from '../../../queries/registration/useRegistrationQueries';
import useSignUpDataError from '../../../hooks/useSignUpDataError';
import { STRIPE_PUBLISHABLE_KEY } from '../../../../utils/constants';
import useBeforeUnload from '../../../hooks/useBeforeUnload';
import { IPricingPlan } from '../../../containers/Registration';

interface Props {
  uuid: string;
  planCode: string;
  price: string | null;
  changeStep: (direction: 'forward' | 'backward') => void;
}

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const Checkout: React.FC<Props> = ({ changeStep, uuid, planCode, price }) => {
  const {
    data: signUpData,
    isLoading,
    error: getSignUpDataError,
  } = useGetSignUpData(uuid);

  useSignUpDataError(getSignUpDataError, uuid, isLoading);

  useBeforeUnload(uuid, 'signup_abandon');

  const [planData] = useState<IPricingPlan | null>(null);

  return (
    <Elements
      stripe={stripePromise}
      options={{
        loader: 'always',
        mode: 'subscription',
        currency: 'usd',
        paymentMethodCreation: 'manual',
        amount: parseInt(planData?.price ?? '0'),
      }}
    >
      <StripePaymentForm
        signUpData={signUpData}
        uuid={uuid}
        planCode={planCode}
        changeStep={changeStep}
        price={price}
      />
    </Elements>
  );
};

export default Checkout;
