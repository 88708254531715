import styled, { css } from 'styled-components';
import { media } from 'styles/media';

export const MainContent = styled.section`
  min-height: 94vh;
  padding: 13.5rem 0 5rem 0;

  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    font-size: 21px;
  }
`;

export const Logo = styled.img`
  display: block;
  max-height: 5.4rem;
  max-width: 18rem;
  object-fit: contain;
  mix-blend-mode: multiply;
`;

export const Text = styled.div`
  ol,
  ul {
    list-style-position: inside;
  }

  h5 {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.262;
    text-transform: uppercase;
    margin-bottom: 24px;
  }
`;

export const ImageHolder = styled.div`
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
`;

export const HeroImage = styled.img`
  display: block;
  width: 100%;
  height: auto;
  max-height: 400px;
  object-fit: cover;
`;

export const SpaceHolder = styled.div`
  min-height: calc(51.91px + 3.2rem);

  ${media.phone`
    min-height: calc(82.91px + 3.2rem);
  `}
`;

interface ICtaBannerProps {
  isActive?: boolean;
}

export const CtaBanner = styled.div<ICtaBannerProps>`
  width: 100%;
  min-height: 5.7rem;
  padding: 1.6rem 0;
  background-color: ${props => (props.isActive ? '#fff' : 'transparent')};
  box-shadow: ${props =>
    props.isActive ? '0 2px 10px rgba(0, 0, 0, 0.2)' : 'none'};

  ${props =>
    props.isActive
      ? css`
          position: fixed;
          top: 0;
          left: 0;
          z-index: 100;
        `
      : ''};
`;

export const CtaWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  ${media.phone`
    flex-direction: column-reverse;
    gap: 8px;

    > * {
      width: 100%;
    }
  `}
`;

export const Cta = styled.a`
  display: block;
  flex: 0 0 50%;
  padding: 16px 24px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.12;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
  min-width: 29rem;
  margin-right: 2.4rem;
  background-color: #056ba4;
  border-color: #056ba4;

  &:active,
  &:hover {
    color: #ffffff;
    border-color: #004074;
    background-color: #004074;
  }

  ${media.phone`
    margin-right: 0;
  `}
`;

export const CtaIcon = styled.img`
  display: inline-block;
  width: 16px;
  height: 16px;
  object-fit: contain;
  margin: -3px 0 0 8px;
`;

export const NumberCard = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem;
  gap: 2.4rem;
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 2rem;
`;

export const BigNumber = styled.div`
  font-weight: 600;
  font-size: 44px;
  line-height: 1.262;
  color: #000000;
  white-space: nowrap;
`;

export const Terms = styled.div`
  margin-top: 2.5rem;

  .ant-collapse > .ant-collapse-item > .ant-collapse-header > div {
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
