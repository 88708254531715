import React, { useState } from 'react';
import { Typography } from '@mui/material';
import {
  PaymentElement,
  AddressElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { LoadingButton } from '@mui/lab';
import { useSnackBar } from '../CustomSnackbar';
import { useRegistration } from '../../queries/registration/useRegistrationQueries';
import { useQueryClient } from '@tanstack/react-query';
import { ISignUpResponse } from '../../queries/registration/types';
import { useQuery } from '../../hooks/useQuery';

interface Props {
  uuid: string;
  planCode: string;
  price: string | null;
  changeStep: (direction: 'forward' | 'backward') => void;
  signUpData: ISignUpResponse | undefined;
}

const StripePaymentForm: React.FC<Props> = ({
  changeStep,
  uuid,
  planCode,
  price,
  signUpData,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const snackbar = useSnackBar();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const query = useQuery();

  const { mutate: signUp } = useRegistration({
    options: {
      onMutate: variables => {
        setIsLoading(true);
      },
      onSuccess: data => {
        queryClient.setQueryData(['signUp', data.uuid], data);

        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'purchase',
          ecommerce: {
            transaction_id: data.id,
            value: price?.replace('$', ''),
            currency: 'USD',
            items: [
              {
                item_id: planCode,
                item_name: planCode,
                index: 0,
                price: price?.replace('$', ''),
                quantity: 1,
              },
            ],
          },
        });

        changeStep('forward');
        setIsLoading(false);
      },
      onError: error => {
        snackbar.showSnackBar(
          error.response?.data.context.stripe_error ||
            'Payment was not successful',
          'error',
        );
        setIsLoading(false);
      },
    },
  });

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    elements.submit();

    stripe
      .createPaymentMethod({
        elements,
      })
      .then(function (result) {
        if (result.error) {
          snackbar.showSnackBar(
            result.error.message || 'Payment was not successful',
            'error',
          );
        } else {
          signUp({
            stepId: 3,
            planCode,
            uuid,
            paymentMethodId: result.paymentMethod.id,
            team: query.get('team'),
          });
        }
      });

    setIsLoading(false);
  };

  return (
    <>
      <Typography variant={'h6'} mb={4}>
        Card Information
      </Typography>
      <form onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />
        <Typography variant={'h6'} my={4}>
          Billing Address
        </Typography>
        <AddressElement
          options={{
            mode: 'billing',
            display: {
              name: 'split',
            },
            allowedCountries: ['US'],
            defaultValues: {
              firstName: signUpData?.name,
              lastName: signUpData?.lastName,
              address: {
                country: 'US',
                state: signUpData?.licenseState ?? '',
              },
            },
          }}
        />
        <LoadingButton
          onClick={() => changeStep('backward')}
          sx={{ mt: 4 }}
          loading={isLoading}
        >
          Back
        </LoadingButton>
        <LoadingButton
          variant={'contained'}
          type="submit"
          loading={isLoading}
          sx={{ mt: 4 }}
        >
          Continue
        </LoadingButton>
      </form>
    </>
  );
};

export default StripePaymentForm;
