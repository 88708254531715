import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const RatingWrapper = styled.div`
  margin: 0 1.5rem 0 7px;
  display: flex;
  align-items: flex-end;
`;
