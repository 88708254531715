import styled, { keyframes } from 'styled-components';
import { media } from 'styles/media';
import { Link } from 'react-router-dom';

// common object's keys are in lowercase and has strings instead of boolean in order to remove warnings on router Link
interface ICommonProps {
  variation?: 'primary' | 'secondary' | 'text';
  color?: 'green' | 'yellow' | string;
  small?: 'true' | 'false';
  fullwidth?: 'true' | 'false';
  fullwidthmobile?: 'true' | 'false';
  margin?: string;
  iconside?: 'left' | 'right';
}

interface IButtonProps extends ICommonProps {
  loading?: 'true' | 'false';
}

const getCommonStyles = props => {
  return `
    position: relative;
    display: inline-block;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.12;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    width: ${props.fullwidth === 'true' ? '100%' : 'auto'};
    max-width: ${props.fullwidth === 'true' ? '100%' : '300px'};
    margin: ${props.margin ? props.margin : '0'};
  `;
};

const getPrimaryBtnStyles = props => {
  return `
    border-radius: 4px;
    border: 1px solid ${props.theme.blue.primary};
    background-color: ${props.theme.blue.primary};
    color: ${props.theme.white};
    padding: ${props.small === 'true' ? '9px' : '15px'};
    transition: border-color 0.25s, background-color 0.25s;

    &:active,
    &:hover {
      color: ${props.theme.white};
      border-color: ${props.theme.blue.dark};
      background-color: ${props.theme.blue.dark};
    }

    &:focus {
      outline: none;
      border-color:  ${props.theme.blue.light};
      background-color: ${props.theme.blue.light};
    }

    &:disabled,
    &[disabled] {
      cursor: not-allowed;
      color: rgba(0, 0, 0, 0.26);
      border-color: ${props.theme.grey.disabled};
      background-color: ${props.theme.grey.disabled};

      svg path {
        fill: #BFBFBF;
      }
    }
  `;
};

const getSecondaryBtnStyles = props => {
  return `
    background: none;
    border-radius: 4px;
    border: 1px solid ${props.theme.blue.primary};
    color: ${props.theme.blue.primary};
    padding: ${props.small === 'true' ? '9px' : '15px'};
    transition: border-color 0.25s, color 0.25s;

    svg path {
      fill: ${props.theme.blue.primary};
      transition: fill 0.25s, fill 0.25s;
    }

    &:active,
    &:hover {
      border-color: ${props.theme.blue.primary};
      color: ${props.theme.blue.primary};

      svg path {
        fill: ${props.theme.blue.primary};
      }
    }

    &:focus {
      outline: none;
      border-color: ${props.color === 'yellow' ? '#D69F00' : '#378682'};
      color: ${props.color === 'yellow' ? '#D69F00' : '#378682'};

      svg path {
        fill: ${props.color === 'yellow' ? '#D69F00' : '#378682'};
      }
    }

    &:disabled,
    &[disabled] {
      cursor: not-allowed;
      color: ${props.loading === 'true' ? 'transparent' : '#BFBFBF'};
      border-color: #BFBFBF;

      svg path {
        fill: ${props.theme.white};
      }
    }
  `;
};

const getTextBtnStyles = props => {
  return `
    background: none;
    border: none;
    color: ${props.theme.blue.primary};
    padding: 0;
    transition: color 0.25s;

    svg path {
      fill: ${props.theme.blue.primary};
      transition: fill 0.25s, fill 0.25s;
    }

    &:active,
    &:hover {
      color: ${props.theme.blue.dark};

      svg path {
        fill: ${props.theme.blue.dark};
      }
    }

    &:focus {
      outline: none;
      color: ${props.theme.blue.light};

      svg path {
        fill: ${props.theme.blue.light};
      }
    }

    &:disabled,
    &[disabled] {
      cursor: not-allowed;
      color: ${props.loading === 'true' ? 'transparent' : '#BFBFBF'};

      svg path {
        fill: #BFBFBF;
      }
    }
  `;
};

const getSvgStyles = props => {
  return `> svg { 
    width: 16px;
    height: 16px;
    opacity: ${props.loading === 'true' ? '0' : '1'};
    visibility: ${props.loading === 'true' ? 'invisible' : 'visible'};
    margin: ${
      props.iconside === 'right'
        ? '-2px 0 0 10px'
        : props.iconside === 'left'
        ? '-2px 10px 0 0'
        : '-2px 0 0 0'
    };
  }`;
};

const getTypeStyles = props => {
  switch (props.variation) {
    case 'secondary':
      return getSecondaryBtnStyles(props);
    case 'text':
      return getTextBtnStyles(props);
    case 'primary':
    default:
      return getPrimaryBtnStyles(props);
  }
};

const getPhoneStyles = props => {
  return `
    width: ${
      props.fullwidth === 'true' || props.fullwidthmobile === 'true'
        ? '100%'
        : 'auto'
    };
    max-width: ${
      props.fullwidth === 'true' || props.fullwidthmobile === 'true'
        ? '100%'
        : '300px'
    };
  `;
};

export const ButtonEl = styled.button<IButtonProps>`
  cursor: pointer;
  ${props => getCommonStyles(props)}
  ${props => getTypeStyles(props)}
  ${props => getSvgStyles(props)}

  ${media.phone`
    ${props => getPhoneStyles(props)}
  `}
`;

export const Anchor = styled.a<ICommonProps>`
  ${props => getCommonStyles(props)}
  ${props => getTypeStyles(props)}
  ${props => getSvgStyles(props)}

  ${media.phone`
    ${props => getPhoneStyles(props)}
  `}
`;

export const RouterLink = styled(Link)<ICommonProps>`
  ${props => getCommonStyles(props)}
  ${props => getTypeStyles(props)}
  ${props => getSvgStyles(props)}

  ${media.phone`
    ${props => getPhoneStyles(props)}
  `}
`;

interface ISpinnerWrapperProps {
  loading?: 'true' | 'false';
}

export const SpinnerWrapper = styled.span<ISpinnerWrapperProps>`
  opacity: ${props => (props.loading === 'true' ? '1' : '0')};
  visibility: ${props => (props.loading === 'true' ? 'visible' : 'invisible')};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(89, 89, 89, 0.3);
  border-radius: 50%;
  border-top-color: #595959;
  animation: ${spin} 1s ease-in-out infinite;
`;
