import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  Stack,
  Typography,
  InputAdornment,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import RouteConstants from '../../../../routes';
import { useResetPassword } from '../../../../queries/auth/useAuthQueries';
import { useRedirectTo } from '../../../../hooks/useStore';
import { InputField } from '../../../../components/FormInputs';

const NewPassword = () => {
  const location = useLocation();
  const setRedirectTo = useRedirectTo();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const query = new URLSearchParams(location.search);
  const createNewPasswordCode = query.get('code');

  const form = useForm<{ password: string }>({
    defaultValues: {
      password: '',
    },
  });

  const { handleSubmit, control } = form;

  const { mutate: setNewPassword, isLoading } = useResetPassword({
    options: {
      onSuccess: () => {
        setRedirectTo(RouteConstants.myResources.index);
      },
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleNewPassSubmit = ({ password }) => {
    if (createNewPasswordCode) {
      setNewPassword({ code: createNewPasswordCode, password });
    }
  };

  return (
    <Container maxWidth="sm" sx={{ height: '100%', py: 3 }}>
      <Stack height={'100%'} justifyContent={'center'}>
        <Box pb={{ xs: 3, sm: 5, md: 8 }}>
          <Typography variant={'h5'}>
            Create your password to log in to PinnacleHQ
          </Typography>
        </Box>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(handleNewPassSubmit)}>
            <Stack spacing={2} alignItems={'flex-end'} width={'100%'}>
              <InputField
                control={control}
                name="password"
                label="Password"
                rules={{
                  minLength: {
                    value: 6,
                    message: 'Password must be at least 6 characters long',
                  },
                }}
                textFieldProps={{
                  type: showPassword ? 'text' : 'password',
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword()}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </Stack>
            <LoadingButton
              variant={'contained'}
              type="submit"
              sx={{ mt: 3 }}
              fullWidth
              disabled={!createNewPasswordCode}
              loading={isLoading}
            >
              Change password
            </LoadingButton>
          </form>
        </FormProvider>
      </Stack>
    </Container>
  );
};

export default NewPassword;
