import React from 'react';
import { Typography, Stack } from '@mui/material';
import noResult from '../../../assets/images/noResults.svg';

interface IPageHeader {
  title: string;
  subtitle?: string[];
}

const NoResults = ({ title, subtitle }: IPageHeader) => {
  return (
    <Stack sx={{ py: 6, px: 1 }} alignItems="center">
      <Stack spacing={1} justifyContent="center" alignItems="center">
        <img src={noResult} alt={'no-results-found-icon'} />
        <Typography
          sx={{ textTransform: 'capitalize', pt: 2 }}
          variant="h4"
          align={'center'}
        >
          {title}
        </Typography>
        {subtitle?.map((text, index) => (
          <Typography variant="body1" key={index} align={'center'}>
            {text}
          </Typography>
        ))}
      </Stack>
    </Stack>
  );
};

export default NoResults;
