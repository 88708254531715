import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../types';
import { initialState } from '../../Admin/slice';

const selectSlice = (state: RootState) => state?.userProfile || initialState;

export const selectUserDataFetchStatus = createSelector(
  [selectSlice],
  state => state.userDataFetchStatus,
);

export const selectUserInfo = createSelector(
  [selectSlice],
  state => state.userData?.user,
);

export const selectMyTeam = createSelector(
  [selectSlice],
  state => state.myTeam,
);

export const selectUserInnerPageData = createSelector(
  [selectSlice],
  state => state.userData?.user?.innerPage,
);

export const selectUserAddressInfo = createSelector(
  [selectSlice],
  state => state.userData?.address,
);

export const selectStripeUrlIsLoading = createSelector(
  [selectSlice],
  state => state.stripeUrlFetchStatus,
);

export const selectStripeUrl = createSelector(
  [selectSlice],
  state => state.stripeAccountUrl,
);
