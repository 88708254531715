import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  InputAdornment,
  Stack,
  Typography,
  Box,
  Link,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { InputField } from '../../FormInputs';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { LoadingButton } from '@mui/lab';
import {
  useLogin,
  useResetPassword,
} from '../../../queries/auth/useAuthQueries';
import RouteConstants from '../../../routes';
import { useHistory } from 'react-router-dom';
import { useGetSignUpData } from '../../../queries/registration/useRegistrationQueries';
import { useSetUserData } from '../../../hooks/useStore';
import useSignUpDataError from '../../../hooks/useSignUpDataError';
import useBeforeUnload from '../../../hooks/useBeforeUnload';

interface Props {
  uuid: string;
  planCode: string;
}

const Login: React.FC<Props> = ({ uuid }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const history = useHistory();
  const {
    data: signUpData,
    isLoading,
    error: getSignUpDataError,
  } = useGetSignUpData(uuid);
  const updateUser = useSetUserData();
  const [passwordExist, setPasswordExist] = useState<boolean>(false);

  useEffect(() => {
    if (getSignUpDataError?.response?.data?.code === 1) {
      setPasswordExist(
        !!getSignUpDataError?.response?.data?.context?.passwordSet,
      );
    }
  }, [getSignUpDataError, isLoading, signUpData, uuid]);

  useSignUpDataError(
    getSignUpDataError,
    uuid,
    isLoading,
    !!signUpData?.stripeSubscription?.clientSecret,
    !!signUpData?.passwordReminder?.unique_id,
  );

  useBeforeUnload(uuid, 'Signup_HQpassword_abandon');

  const form = useForm<{ email: string; password: string }>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { setError, handleSubmit, control, reset } = form;

  useEffect(() => {
    if (signUpData) {
      reset({
        email: signUpData?.email,
      });
    } else {
      reset({
        email: getSignUpDataError?.response?.data?.context?.email || '',
      });
    }
  }, [getSignUpDataError, reset, signUpData]);

  const { mutate: setNewPassword, isLoading: isLoadingSetPassword } =
    useResetPassword({
      options: {
        onSuccess: () => {
          history.push(RouteConstants.onboarding.index);
        },
      },
    });

  const { mutate: Login, isLoading: isLoadingLogin } = useLogin({
    options: {
      onSuccess: data => {
        window.dataLayer.push({
          event: 'Login',
          userID: data[0].uuid,
        });
        updateUser(data[0]);
        history.push(RouteConstants.onboarding.index);
      },
      onError: error => {
        if (error && error.response?.status === 401) {
          setError('password', {
            type: 'manual',
            message: 'Invalid email address or password',
          });
        }
      },
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleFormSubmit = ({ email, password }) => {
    if (passwordExist) {
      Login({
        email,
        password,
      });
    } else {
      setNewPassword({
        code: signUpData?.passwordReminder?.unique_id!,
        password,
      });
    }
  };

  return (
    <Stack maxWidth={520} mx={'auto'}>
      <Typography variant={'h5'} mb={4} textAlign={'center'}>
        {passwordExist
          ? 'Login in to PinnacleHQ'
          : 'Create your password and continue onboarding process'}
      </Typography>
      {isLoading ? (
        <Box mx={'auto'} my={6}>
          <CircularProgress size={50} />
        </Box>
      ) : (
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Stack spacing={2} alignItems={'flex-end'} width={'100%'}>
              <InputField
                label={'Email'}
                name={'email'}
                control={control}
                textFieldProps={{
                  disabled: !passwordExist,
                }}
              />
              <InputField
                label={'Password'}
                control={control}
                name="password"
                rules={
                  passwordExist && {
                    minLength: {
                      value: 6,
                      message: 'Password must be at least 6 characters long',
                    },
                  }
                }
                textFieldProps={{
                  type: showPassword ? 'text' : 'password',
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword()}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </Stack>
            <LoadingButton
              variant={'contained'}
              type="submit"
              sx={{ mt: 5, mb: 2 }}
              fullWidth
              loading={isLoadingSetPassword || isLoadingLogin}
            >
              {passwordExist ? 'Login' : 'Create password'}
            </LoadingButton>
            {!passwordExist && (
              <Typography fontSize={12}>
                By clicking the "Sign Up" button, you confirm that you have
                read, understood and agree with the{' '}
                <Link underline="none" href={'#'}>
                  Terms and Conditions for User and Purchaser
                </Link>
                .
              </Typography>
            )}
          </form>
        </FormProvider>
      )}
    </Stack>
  );
};

export default Login;
