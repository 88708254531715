import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Select,
  MenuItem,
  Chip,
  Box,
  Grid,
  Typography,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { Autocomplete } from '@mui/material';
import { Button } from 'app/components/Clicks';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const PriceEditForm = ({ defaultValues, onSubmit }) => {
  const { control, getValues } = useForm({
    defaultValues,
  });
  const [isLoading, setIsLoading] = useState(false);

  const quillModules = {
    toolbar: ['bold', 'italic', 'underline', { list: 'bullet' }],
    clipboard: {
      matchVisual: false,
    },
  };

  return (
    <form>
      <Box sx={{ mt: 1, mb: 2 }}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField {...field} label="Name" fullWidth />
          )}
        />
      </Box>

      <Box my={2} height={170}>
        <Typography variant="body1">Description</Typography>
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <>
              <ReactQuill
                {...field}
                theme="snow"
                modules={quillModules}
                style={{ height: 100, color: 'black' }}
              />
            </>
          )}
        />
      </Box>

      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={3}>
          <Controller
            name="amount"
            control={control}
            render={({ field }) => (
              <TextField {...field} label="Amount" fullWidth />
            )}
          />
        </Grid>

        <Grid item xs={2}>
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <Select {...field} fullWidth>
                <MenuItem value="">Hidden</MenuItem>
                <MenuItem value="amount">$</MenuItem>
                <MenuItem value="percentage">%</MenuItem>
              </Select>
            )}
          />
        </Grid>

        <Grid item xs={7}>
          <Controller
            name="additionalText"
            control={control}
            render={({ field }) => (
              <TextField {...field} label="Additional Text" fullWidth />
            )}
          />
        </Grid>
      </Grid>

      <Box sx={{ mb: 2 }}>
        <Controller
          name="additionalPricing"
          control={control}
          render={({ field }) => (
            <Autocomplete
              multiple
              options={[]} // options could be predefined or fetched from an API
              value={field.value}
              onChange={(event, newValue) => {
                field.onChange(newValue);
              }}
              freeSolo // allows free input, not just selection from the options array
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Additional Pricing items"
                  placeholder="Prices"
                />
              )}
            />
          )}
        />
      </Box>

      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={6}>
          <Controller
            name="promoCode"
            control={control}
            render={({ field }) => (
              <TextField {...field} label="Promo code" fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="acEventName"
            control={control}
            render={({ field }) => (
              <TextField {...field} label="AC event name" fullWidth />
            )}
          />
        </Grid>
      </Grid>

      <Box sx={{ mb: 2 }}>
        <Controller
          name="ctaUrl"
          control={control}
          render={({ field }) => (
            <TextField {...field} label="CTA URL" fullWidth />
          )}
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <Controller
          name="ctaText"
          control={control}
          render={({ field }) => (
            <TextField {...field} label="CTA Text" fullWidth />
          )}
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <Controller
          name={'featured'}
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Switch checked={field.value} {...field} />}
              label="Show this price in tools list."
            />
          )}
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <Button
          type="button"
          onClick={() => {
            onSubmit(getValues());
            setIsLoading(true);
          }}
          loading={isLoading ? 'true' : 'false'}
        >
          Save
        </Button>
      </Box>
    </form>
  );
};

export default PriceEditForm;
