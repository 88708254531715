import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';
import { globalActions } from '../../Global/slice';
import { IUpdateUserData, IUpdateUserPublicData, IUserData } from './types';
import userProfileApi from '../userProfileApi';
import { userProfileActions as actions } from '.';
import { IMyTeam, IUserInnerPageImageUrl } from '../../Admin/slice/types';
import { IUser } from '../../../queries/auth/types';

function* getUserInfo() {
  try {
    const user: IUserData = yield userProfileApi.getUserInfo();
    yield put(actions.setUserInfoWithAddress(user));
  } catch (err) {
    yield put(globalActions.handleError(err));
    yield put(
      actions.setFetchStatusByKey({
        key: 'userDataFetchStatus',
        status: 'fail',
      }),
    );
  }
}
function* getMyTeam() {
  try {
    const user: IMyTeam = yield userProfileApi.getMyTeam();
    yield put(actions.setMyTeam(user));
  } catch (err) {
    yield put(globalActions.handleError(err));
    yield put(
      actions.setFetchStatusByKey({
        key: 'myTeamDataFetchStatus',
        status: 'fail',
      }),
    );
  }
}

function* setUserInfo(action: PayloadAction<IUpdateUserData>) {
  try {
    const user: IUserData = yield userProfileApi.updateUserData(action.payload);
    yield put(actions.setUserInfoWithAddress(user));
    yield put(
      globalActions.showSnackbar({
        message: 'Your account details have been saved.',
        type: 'success',
        anchor: {
          vertical: 'top',
          horizontal: 'center',
        },
      }),
    );
  } catch (err) {
    yield put(globalActions.handleError(err));
    yield put(
      actions.setFetchStatusByKey({
        key: 'userDataFetchStatus',
        status: 'fail',
      }),
    );
  }
}

function* setUserPublicInfo(action: PayloadAction<IUpdateUserPublicData>) {
  try {
    const user: IUser = yield userProfileApi.updateUserPublicData(
      action.payload,
    );
    yield put(actions.setUserInfo(user));
    yield put(
      globalActions.showSnackbar({
        message: 'Your website information has been saved.',
        type: 'success',
        anchor: {
          vertical: 'top',
          horizontal: 'center',
        },
      }),
    );
  } catch (err) {
    yield put(globalActions.handleError(err));
    yield put(
      actions.setFetchStatusByKey({
        key: 'userDataFetchStatus',
        status: 'fail',
      }),
    );
  }
}

function* uploadImage(file) {
  const formData = new FormData();
  formData.append('images', file, '[PROXY]');
  const images: Array<{ imageUrl: string }> = yield userProfileApi.uploadImage(
    formData,
  );
  return images[0].imageUrl;
}

function* updateUserPublicProfilePicture(action: PayloadAction<File | null>) {
  const data: IUserInnerPageImageUrl = {
    type: 'profile',
    image: null,
  };
  const profilePicture = action.payload;
  try {
    if (profilePicture) {
      data.image = yield uploadImage(profilePicture);
    }
    yield put(actions.updateUserPublicProfileImageUrl(data));
  } catch (err) {
    yield put(globalActions.handleError(err));
  }
}

function* updateUserPublicProfileImageUrl(
  action: PayloadAction<IUserInnerPageImageUrl>,
) {
  try {
    const data = action.payload;
    const user: IUser = yield userProfileApi.updateUserPublicProfileImageUrl(
      data,
    );
    yield put(actions.setUserInfo(user));
    yield put(
      globalActions.showSnackbar({
        message: `Your ${data.type} image has been saved.`,
        type: 'success',
        anchor: {
          vertical: 'top',
          horizontal: 'center',
        },
      }),
    );
  } catch (err) {
    yield put(globalActions.handleError(err));
  }
}

function* getStripeAccountUrl() {
  try {
    const stripeUrl: string = yield userProfileApi.getStripeAccountUrl();
    yield put(actions.setStripeAccountUrl(stripeUrl));
  } catch (err) {
    yield put(globalActions.handleError(err));
    yield put(
      actions.setFetchStatusByKey({
        key: 'stripeUrlFetchStatus',
        status: 'fail',
      }),
    );
  }
}

export function* userProfileSaga() {
  yield takeLatest(actions.getUserInfo.type, getUserInfo);
  yield takeLatest(actions.getMyTeam.type, getMyTeam);
  yield takeLatest(actions.updateUserInfo.type, setUserInfo);
  yield takeLatest(actions.updateUserPublicInfo.type, setUserPublicInfo);
  yield takeLatest(actions.getStripeAccountUrl.type, getStripeAccountUrl);
  yield takeLatest(
    actions.updateUserPublicProfilePicture.type,
    updateUserPublicProfilePicture,
  );
  yield takeLatest(
    actions.updateUserPublicProfileImageUrl.type,
    updateUserPublicProfileImageUrl,
  );
}
