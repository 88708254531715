import { useMutation } from '@tanstack/react-query';
import api from './api';

export const useUploadImage = () => {
  return useMutation(
    async (file: string | Blob) => {
      const formData = new FormData();
      formData.append('file', file);

      const result = await api.post('/admin/images/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      return result.data[0].imageUrl;
    },
    {
      onError: error => {
        console.log(error);
      },
    },
  );
};
