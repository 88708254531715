import React from 'react';
import { useMemo } from 'react';
import { PaletteColorOptions, ThemeOptions } from '@mui/material';
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles';
import '@mui/material/styles/createPalette';
import typography from './typography';
import palette from './pallete';
import componentsOverride from './overrides';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from './globalStyles';

declare module '@mui/material/styles' {
  interface ThemeOptions {
    [key: string]: any;
  }
}

declare module '@mui/material/styles' {
  interface CustomPalette {
    stripe: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    stripe: true;
    black: true;
  }
}

const ThemeProvider = ({ children }) => {
  const themeOptions = useMemo(
    () => ({
      palette,
      typography,
    }),
    [],
  );

  const theme = createTheme(themeOptions as ThemeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <GlobalStyles />
      <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeProvider;
