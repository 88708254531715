import styled from 'styled-components';

export const MainContent = styled.section`
  min-height: 94vh;
  padding: 13.5rem 0 5rem 0;

  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    font-size: 21px;
  }
`;

export const BigNumber = styled.div`
  font-weight: 600;
  font-size: 44px;
  line-height: 1.262;
  color: #000000;
  white-space: nowrap;
`;

export const Logo = styled.img`
  display: block;
  max-height: 3rem;
  max-width: 13rem;
  object-fit: contain;
  mix-blend-mode: multiply;
`;
