import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { dealsSaga } from './saga';
import {
  IDealsState,
  IDeal,
  IDealRatePayload,
  IDeals,
  IGetDealsData,
} from './types';

export const initialState: IDealsState = {
  isLoading: false,
  deals: {
    vendors: null,
    categories: null,
    filters: {
      pagination: {
        total_pages: 1,
        current_page: 1,
        page_size: 10,
        total: 0,
      },
      category: '',
    },
    geo: null,
  },
  deal: null,
  isEmailSent: false,
};

const slice = createSlice({
  name: 'deals',
  initialState,
  reducers: {
    getDeals(state, action: PayloadAction<IGetDealsData>) {
      state.isLoading = true;
    },
    setDeals(state, action: PayloadAction<IDeals>) {
      state.deals = action.payload;
    },
    getTopDeals(state) {
      state.isLoading = true;
    },
    getDeal(state, action: PayloadAction<number>) {
      state.isLoading = true;
    },
    setDeal(state, action: PayloadAction<IDeal>) {
      state.deal = action.payload;
    },
    setRating(state, action: PayloadAction<IDealRatePayload>) {},
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
  },
});

export const { actions: dealsActions } = slice;

export const useDealsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: dealsSaga });
  return { actions: slice.actions };
};
