import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import api from '../api';
import { AxiosError } from 'axios/index';
import { ITeam } from '../admin/types';
import { IUserObject } from '../../containers/Admin/slice/types';

interface MyTeamInfo {
  teamInfo: ITeam;
  inviteUrl: string;
  members: Array<IUserObject>;
}

interface GetMyTeam<T> {
  options?: UseQueryOptions<MyTeamInfo, AxiosError, T, any>;
}

export function useGetMyTeam<T = MyTeamInfo>(props?: GetMyTeam<T>) {
  return useQuery(
    ['myTeam'],
    async () => {
      const result = await api.get(`team/my-team`);
      return result.data;
    },
    props?.options,
  );
}

export function useInviteTeamMember(props?) {
  return useMutation(async (data: object) => {
    const result = await api.post(`/team/invite`, data);
    return result.data;
  }, props?.options);
}

export function useUpdateTeamInfo(props?) {
  return useMutation(async (data: object) => {
    const result = await api.post(`/team/update`, data);
    return result.data;
  }, props?.options);
}
