export const formatMessage = (message: string, changeables: Array<string>) => {
  let newMessage = message;
  changeables.forEach(item => {
    newMessage = newMessage.replace(/\{{(.*?)\}}/, item);
  });
  return newMessage;
};

export const getFileNameFromUrl = (url: string) => {
  return url?.split('/').pop() || '';
};

export const formatAmount = (amount: number) => {
  return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const hex2rgba = (hex: string, opacity: number) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
        result[3],
        16,
      )}, ${opacity})`
    : 'rgba(0,0,0,0)';
};

export const pxToRem = value => {
  return `${value / 16}rem`;
};

export const isImageSizeValid = (size: number, maxSizeKB: number) => {
  return size === undefined || size <= maxSizeKB * 1000;
};

export const shortenText = (text: string, numberOfChars: number) => {
  if (text.length > numberOfChars) return `${text.slice(0, numberOfChars)}...`;
  return text;
};

export const inputLinkValidation = (str: string) => {
  // if string is empty do not validate
  if (!str) {
    return true;
  } else {
    const urlPattern =
      /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    return urlPattern.test(str);
  }
};
