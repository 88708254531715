import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, Stack, Typography } from '@mui/material';
import { FormRadioGroup, InputDropdown, InputField } from '../../FormInputs';
import { LoadingButton } from '@mui/lab';
import {
  useGetAssociations,
  useGetSignUpData,
  useRegistration,
} from '../../../queries/registration/useRegistrationQueries';
import { LicenseStates } from '../../../containers/UserSettings/States';
import { DropdownOptions } from '../../FormInputs/InputDropdown';
import { useQueryClient } from '@tanstack/react-query';
import { AgentType } from '../../../queries/auth/types';
import useSignUpDataError from '../../../hooks/useSignUpDataError';
import useBeforeUnload from '../../../hooks/useBeforeUnload';
import { useQuery } from '../../../hooks/useQuery';
import { useSnackBar } from '../../CustomSnackbar';

interface FormProps {
  licenseState: string;
  licenseNumber: string;
  localAssociation: string;
  agentType: AgentType | null;
  dbaName?: string;
  currentlyIam?: string;
  teamSize?: string | null;
}

interface Props {
  planCode: string;
  changeStep: (direction: 'forward' | 'backward') => void;
  uuid: string;
}

const currentIamOptions = [
  { value: 'Independent Agent', name: 'Independent Agent' },
  { value: 'Team Lead', name: 'Team Lead' },
  { value: 'Other', name: 'Other' },
];

const LicenseInformation: React.FC<Props> = ({
  planCode,
  changeStep,
  uuid,
}) => {
  const form = useForm<FormProps>({
    defaultValues: {
      licenseState: '',
      licenseNumber: '',
      localAssociation: '',
      dbaName: '',
      currentlyIam: '',
      teamSize: null,
      agentType: null,
    },
  });
  const { setError, control, handleSubmit, reset, watch, setValue } = form;
  const isLicenseStateSelected = !!watch('licenseState');
  const queryClient = useQueryClient();
  const query = useQuery();
  const snackbar = useSnackBar();

  const { mutate: signUp, isLoading: isLoadingRegistration } = useRegistration({
    options: {
      onSuccess: data => {
        queryClient.setQueryData(['signUp', data.uuid], data);
        changeStep('forward');
      },
      onError: error => {
        if (error && error.response?.data.code === 7) {
          setError('dbaName', {
            type: 'manual',
          });
          snackbar.showSnackBar(
            <Typography fontSize={'inherit'} color={'inherit'}>
              {error.response.data.message}
            </Typography>,
            'error',
          );
        }
      },
    },
  });

  const { data: signUpData, error: getSignUpDataError } = useGetSignUpData(
    uuid!,
  );

  const { data: localAssociations, isLoading: isLoadingAssociations } =
    useGetAssociations(watch('licenseState'), {
      options: {
        enabled: isLicenseStateSelected,
        // reformat date to name/value pair
        select: data =>
          data.map(option => ({
            value: option.name,
            name: option.name,
          })),
      },
    });

  useEffect(() => {
    if (signUpData) {
      reset({
        licenseState: signUpData.licenseState || '',
        licenseNumber: signUpData.licenseNumber || '',
        localAssociation: signUpData.localAssociation || '',
        currentlyIam: signUpData.currentlyIam,
        dbaName: signUpData.dbaName,
        teamSize: signUpData.teamSize,
        agentType: signUpData.agentType,
      });
    }
  }, [signUpData, reset]);

  useSignUpDataError(getSignUpDataError, uuid);
  useBeforeUnload(uuid, 'signup_abandon');

  return (
    <>
      <Typography variant={'h6'} mb={4}>
        Your License Information:
      </Typography>
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(data =>
            signUp({
              stepId: 2,
              uuid,
              planCode,
              ...data,
              team: query.get('team'),
            }),
          )}
        >
          <Stack gap={2}>
            <InputDropdown
              control={control}
              name={'licenseState'}
              label={'License State *'}
              options={LicenseStates}
              rules={{
                required: 'Please select license state',
              }}
              onInputChange={() => setValue('localAssociation', '')}
            />
            <InputField
              control={control}
              name={'licenseNumber'}
              label={'Real Estate License # *'}
              rules={{
                required: 'Please input real estate license number',
              }}
            />
            {isLicenseStateSelected && !isLoadingAssociations && (
              <InputDropdown
                control={control}
                name={'localAssociation'}
                label={'Local Realtor Association / MLS *'}
                options={localAssociations as DropdownOptions}
                rules={{
                  required: 'Please input real estate license number',
                }}
              />
            )}
            {(planCode === 'wl_pro' ||
              planCode === 'wl_commercial' ||
              planCode === 'pra_commercial') && (
              <InputField
                control={control}
                name={'dbaName'}
                label={'Name of your brand/team *'}
                rules={{
                  required: 'Please input team name',
                }}
              />
            )}
            {(planCode === 'wl_pro' || planCode === 'wl_lcc') && (
              <InputDropdown
                control={control}
                name={'currentlyIam'}
                label={'Currently I am *'}
                options={currentIamOptions}
                rules={{
                  required: 'Please select',
                }}
              />
            )}
            {planCode === 'wl_pro' || planCode === 'wl_lcc' ? (
              <FormRadioGroup
                control={control}
                name={'teamSize'}
                label={'Size Of The Team You Work In'}
                buttonLabels={[
                  '1 agent',
                  '2 - 4 agents',
                  '5 - 10 agents',
                  '11+ agents',
                ]}
                row={true}
                rules={{
                  required: 'Please select team size',
                }}
              />
            ) : (
              <FormRadioGroup
                control={control}
                name={'agentType'}
                label={'Agent type *'}
                buttonLabels={[
                  'New License - First brokerage ever joining',
                  'Transfer License - Currently licensed at a different brokerage',
                  'Inactive License - Not licensed with a brokerage but have been in the past',
                ]}
                row={false}
                rules={{
                  required: 'Please select agent type',
                }}
              />
            )}
          </Stack>
          <LoadingButton
            onClick={() => changeStep('backward')}
            sx={{ mt: 4 }}
            loading={isLoadingRegistration}
          >
            Back
          </LoadingButton>
          <LoadingButton
            variant={'contained'}
            type="submit"
            loading={isLoadingRegistration}
            sx={{ mt: 4 }}
          >
            Continue
          </LoadingButton>
        </form>
      </FormProvider>
    </>
  );
};

export default LicenseInformation;
