import React from 'react';
import { Controller, Control } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

interface Props {
  control: Control<any>;
  name: string;
  label: string;
  rules?: any;
  buttonLabels: string[];
  row?: boolean;
}

const FormRadioGroup: React.FC<Props> = ({
  control,
  name,
  label,
  rules,
  buttonLabels,
  row,
}) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => {
          return (
            <Box>
              <FormControl error={!!error}>
                <FormLabel id={label}>{label}</FormLabel>
                <RadioGroup {...field} row={row} aria-labelledby={label}>
                  {buttonLabels.map(label => (
                    <FormControlLabel
                      key={label}
                      value={label}
                      control={<Radio />}
                      label={label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              {error?.message && (
                <FormHelperText error>{error.message}</FormHelperText>
              )}
            </Box>
          );
        }}
      />
    </>
  );
};

export default FormRadioGroup;
