import React from 'react';
import {
  Popover,
  Typography,
  Button,
  Stack,
  OutlinedInput,
} from '@mui/material';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import { useTheme } from '@mui/material/styles';

interface IProps {
  copyText: string;
  ctaText?: string;
  isWithIcon?: boolean;
}

const CopyText = ({ copyText, ctaText, isWithIcon }: IProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const themes = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    navigator.clipboard.writeText(copyText);
    setTimeout(() => {
      setAnchorEl(null);
    }, 1000);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Stack direction={'row'} spacing={1} width={'100%'}>
        <OutlinedInput
          sx={{
            pl: 1,
            borderRadius: 0,
            '.MuiInputBase-input': {
              padding: '11px 11px',
              cursor: 'text',
              color: themes.palette.common.black,
            },
          }}
          value={copyText}
          startAdornment={
            isWithIcon ? (
              <LinkOutlinedIcon
                fontSize={'small'}
                sx={{ transform: 'rotate(-45deg)' }}
              />
            ) : undefined
          }
          disabled
          fullWidth
        />
        <Button
          variant="outlined"
          onClick={handleClick}
          size="large"
          sx={{ minWidth: 'fit-content' }}
        >
          {ctaText || 'Copy code'}
        </Button>
      </Stack>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 2 }}>Copied!</Typography>
      </Popover>
    </>
  );
};

export default CopyText;
