import { createGlobalStyle } from 'styled-components';
import '@fontsource/inter';

export const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  html,
  body,
  #root{
    height: 100%;
    width: 100%;
    position: relative;
  }

  body {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.62;
    color: ${props => props.theme.grey.secondary};
    background-color: ${props => props.theme.grey.background};
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
  }

  ol,
  ul {
    list-style-position: inside;
  }

  ::selection {
    background-color: ${props => props.theme.green.primary};
  }

  .ant-btn {
    text-transform: uppercase;
    line-height: 1;
  }

  .ant-select-item.realthy-child-opt {
    padding-left: 3rem;
  }
`;
